/* Copyright © 2024 Ganchrow Scientific, SA all rights reserved */
'use strict';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

type DialogData = { title: string, description: string };

@Component({
  selector: 'confirm-dialog-material',
  templateUrl: 'confirmDialog/confirmDialog.component.html',
  styles: [require('./confirmDialog.component.scss')], // tslint:disable-line
})
export class ConfirmDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {}

  protected confirm() {
    this.dialogRef.close(true);
  }

  protected cancel() {
    this.dialogRef.close(false);
  }
}
