/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseRequestorService } from '../baseRequestor.service';

@Injectable()
export class RequestOutrightIdService extends BaseRequestorService<any, any> {

  constructor(protected http: HttpClient) {
    super('requestOutrightId', http);
  }
}
