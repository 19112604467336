/* Copyright © 2021-2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit, Input } from '@angular/core';

import { EventChangesService } from './eventChanges.service';

import { EventInterface } from  'gs-templates/lib/event';

const TIMESTAMP_KEY = '__now__';

@Component({
  selector: 'event-changes',
  templateUrl: 'events/eventChanges.component.html',
  styles: [
    require('./eventChanges.component.scss') // tslint:disable-line
  ],
  providers: [
    EventChangesService
  ]
})
export class EventChangesComponent implements OnInit {
  @Input()
  protected rotation: number;
  protected eventChanges: (EventInterface & { teamString?: string, formattedStartDate?: string, formattedTimestamp?: string })[];

  private comparedKeys = ['start_date', 'sport_id', 'league_id', 'country_id', 'teamsString', 'status', 'pitchersString'];

  constructor(private eventChangesService: EventChangesService) { /**/ }

  public ngOnInit() {
    this.eventChangesService.requestRotation(this.rotation).subscribe(eventChanges => {
      this.eventChanges = Object.values(eventChanges).map(ev => {
        return {
          ...ev,
          formattedStartDate: this.formatDate(ev.start_date),
          formattedTimestamp: this.formatDate(ev.timestamp),
          teamsString: ev.teams.map(t => t.name).join(' vs '),
          pitchersString: (ev.pitchers || []).map(p => p.name).join(' vs ')
        };
      }).sort((d1, d2) => d1[TIMESTAMP_KEY] - d2[TIMESTAMP_KEY]).filter((d, i, a) => {
        return i === 0 || this.comparedKeys.some(k => {
          return d[k] !== a[i - 1][k];
        });
      });
    });
  }

  private formatDate(d): string {
    return [(new Date(d)).toLocaleDateString(), (new Date(d)).toLocaleTimeString()].join(' ');
  }
}
