/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';

interface Stampable {
  feed_source: string;
  timestamp?: number;
}

@Injectable()
export class DateService {

  public static ADMIN_FEED_SOURCE = 'GSAdmin';

  public date(): Date {
    return new Date();
  }

  public timestamp(): number {
    return this.date().getTime();
  }

  public stampModel<T extends Stampable>(model: T): T {
    if (model) {
      model.feed_source = DateService.ADMIN_FEED_SOURCE;
      model.timestamp = this.timestamp();
    }
    return model;
  }
}
