/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component } from '@angular/core';

import { PasswordHash, UsernamePassword } from '../../server/shared/models/passwordHash';

import { WebsocketSpelunkerService } from './websocketSpelunker.service';

@Component({
  selector: 'websocket-spelunker',
  templateUrl: './websocketSpelunker/websocketSpelunker.component.html',
  styles: [
    require('./websocketSpelunker.component.scss')  // tslint:disable-line
  ]
})
export class WebsocketSpelunkerComponent {
  protected logSources: string[];

  protected activeSource: string;
  protected commandText: string;
  protected result: string;
  protected submitted: boolean;

  constructor(private websocketSpelunker: WebsocketSpelunkerService) { /**/ }

  public ngOnInit(): void {
    this.getLogSources();
    this.submitted = false;
  }

  protected getLogSources() {
    this.websocketSpelunker.getLogSources().subscribe(logSources => {
      this.logSources = logSources;
    });
  }

  protected executeCommand() {
    if (this.activeSource && this.commandText) {
      this.submitted = true;
      this.websocketSpelunker.executeCommand(this.activeSource, this.commandText)
        .subscribe(result => {
          this.result = result;
          this.submitted = false;
         }, err => {
           this.result = err;
           this.submitted = false;
         });
    }
  }
}
