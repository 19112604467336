/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings } from '../multiselect/multiselect.types';
import { ScoringRulesService } from './scoringRules.service';
import { ScoringRulesMapperService } from './scoringRulesMapper.service';

export type ScoringRulesType = 'league' | 'sport' | 'rotation';

@Component({
  selector: 'scoring-rules-drop-down',
  templateUrl: 'scoringRules/scoringRulesDropdown.component.html',
  styles: [
    require('./scoringRulesDropdown.component.scss'), // tslint:disable-line
  ],
})
export class ScoringRulesDropdownComponent implements OnInit {
  protected isFetching = false;
  protected rules: IMultiSelectOption[] = [];
  protected initialRuleMapped;
  protected selectedRulesKey;
  protected msg: string;

  protected selectSettings: IMultiSelectSettings = {
    enableSearch: true,
    buttonClasses: 'btn btn-default btn-block',
    checkedStyle: 'glyphicon',
    showUncheckAll: true,
    selectionLimit: 1,
    autoUnselect: true,
    closeOnSelect: true,
  };

  @Input() protected type: ScoringRulesType;
  @Input() protected id: number;

  constructor(
    private scoringRulesService: ScoringRulesService,
    private scoringRulesMapperService: ScoringRulesMapperService
  ) {
    /**/
  }

  public ngOnInit(): void {
    this.scoringRulesService.list().subscribe(
      (rules) => {
        this.isFetching = false;
        this.rules = Object.keys(rules || {}).map((ruleId) => ({ id: ruleId, name: rules[ruleId].name }));
      },
      (err) => {
        this.isFetching = false;
      }
    );

    this.scoringRulesMapperService.fetch(`${this.type}:${this.id}`).subscribe((rule) => {
      this.initialRuleMapped = rule;
      if (this.initialRuleMapped) {
        this.selectedRulesKey = this.initialRuleMapped;
      }
    });
  }

  protected save() {
    if (this.selectedRulesKey && this.selectedRulesKey[0]) {
      this.isFetching = true;
      this.scoringRulesMapperService.save(this.key, { ruleId: this.selectedRulesKey[0] }, { key: this.key }).subscribe(() => {
        this.isFetching = false;
        this.initialRuleMapped = this.selectedRulesKey[0];
        this.msg = `${this.type} rules updated successfuly`;
        this.clearMsg();
      });
    } else {
      this.msg = 'Please select at least one rule to save';
      this.clearMsg();
    }
  }

  get key() {
    return `${this.type}:${this.id}`;
  }

  protected delete() {
    this.isFetching = true;
    this.scoringRulesMapperService.delete(this.key, { key: this.key }).subscribe(() => {
      this.isFetching = false;
      this.selectedRulesKey = null;
      this.msg = `${this.type} updated successfuly`;
      this.clearMsg();
    }, () => {
      this.isFetching = false;
      this.msg = 'An error ocurred while deleting this rule';
      this.clearMsg();
    });
  }

  protected revert() {
    this.selectedRulesKey = this.initialRuleMapped;
  }

  protected clearMsg() {
    setTimeout(() => {
      this.msg = '';
    }, 3000);
  }
}
