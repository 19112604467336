/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component } from '@angular/core';
import { DialogModel } from '../../dialog.model';

@Component({
  selector: 'alert-dialog',
  templateUrl: 'dialog/kinds/alert-dialog/alert-dialog.component.html',
})
export class AlertDialogComponent extends DialogModel<AlertDialogComponent>  {
  constructor() {
    super();
    this.confirmLabel = 'Accept';
  }

  public close() {
    this.doClose(true);
  }
}
