/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EventInterface } from 'gs-templates/lib/event';

const PATH = '/api/eventChanges';

@Injectable()
export class EventChangesService {
  constructor(protected http: HttpClient) { /**/ }

  public requestRotation(rotation: number): Observable<EventInterface[]> {
    return this.http.get<EventInterface[]>(PATH, { params: { rotation: `${rotation}` } });
  }
}
