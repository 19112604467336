/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { SimpleChanges, Component, EventEmitter, Input, Output } from '@angular/core';

import { SystemStatusesService, SystemStatus } from '../statuses/systemStatus.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'status-selector',
  templateUrl: 'events/statusSelector.component.html',
  styles: [
    require('./statusSelector.component.scss')  // tslint:disable-line
  ]
})
export class StatusSelectorComponent {
  protected statuses: SystemStatus[] = [];

  @Input() private rotation: string;
  @Output() private statusChanged = new EventEmitter<{rotation?: string, newStatus: number}>();

  // I would prefer to keep the statuses as numbers, but I can't figure out how to
  // bind the results of ngModel as a number. Angular keeps setting it as string.
  // Solution is to convert all statuses to strings inside the component and
  // convert back to number when saving.
  @Input() private status: string;
  @Input() private hideSave = false;
  @Input() private withLabel = true;
  @Input() private resetOn: Subject<any> = null;
  private origStatus: string;

  constructor(private systemStatusesService: SystemStatusesService) {
    /**/
  }

  public async ngOnInit() {
    if (this.resetOn) {
      this.resetOn.subscribe(() => this.status = '');
    }
    this.origStatus = this.status = String(this.status);
    try {
      this.statuses = await this.systemStatusesService.listStatuses();
    } catch (err) {
      this.statuses = this.generateErrorStatuses(err);
    }
  }

  protected doStatusChange() {
    if (this.hideSave) {
      this.origStatus = String(this.status);
      this.statusChanged.emit({ newStatus: Number(this.status) });
    }
  }

  protected doSaveStatus() {
    if (!this.hideSave) {
      this.origStatus = String(this.status);
      this.statusChanged.emit({ rotation: this.rotation, newStatus: Number(this.status) });
    }
  }

  protected getStatusLabel(status: SystemStatus) {
    return `${status.status} (${status.id})`;
  }

  private generateErrorStatuses(err) {
    return [{
      id: -1,
      status: `Error loading statuses: ${err.message || err}`
    }];
  }
}
