/* Copyright © 2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'valueFilter' })
export class ValuePipe implements PipeTransform {
  public transform(input: { key: string; value: any }[], correlation: number[]): { key: string; value: any }[] {
    return input.filter(item => {
      return !correlation.includes(item.value.value);
    });
  }
}
