/* Copyright © 2017-2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoginService } from '../login/login.service';

@Component({
  template: `
    <h2>You are not authorized to access {{ page }}</h2>
    <button (click)="goToLogin()" class="btn btn-primary">log in</button>
  `,
  styles: []
})
export class NotAuthorizedComponent implements OnInit {
  private page: string;

  constructor(private state: ActivatedRoute, private router: Router, private loginService: LoginService) {
    this.page = 'this page';
  }

  public ngOnInit() {
    this.loginService.loggedInStatus.subscribe(login => {
      if (login.isLoggedIn) {
        this.state.queryParams.subscribe((params: Params) => {
          this.page = this.extractPageName(params.url) || this.page;
        });
      }
    });
  }

  public goToLogin() {
    this.loginService.logout();
  }

  private extractPageName(url) {
    if (!url) {
      return;
    }

    let path = new URL(url).pathname;

    if (!path) {
      return;
    }
    let splits = path.split('/');
    return splits[splits.length - 1];
  }
}
