/* Copyright © 2023 Ganchrow Scientific, SA all rights reserved */

'use strict';

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { isObject } from 'gs-utils/lib/utilities';
import { ActiveEventsService } from './activeEvents.service';
import { BlockedEventsService } from './blockedEvents.service';
import { BlockedBooksService } from './blockedBooks.service';
import { FilterListPipe } from '../filters/filterListPipe.filter';
import { RequestIdService } from '../events/requestId.service';
import { BehaviorSubject, Subscription, catchError, combineLatest, of, switchMap, tap } from 'rxjs';
import { SnackBarService } from '../services/snackbar.service';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import agGridLicense from '../agGridLicense';
import { LicenseManager } from 'ag-grid-enterprise';
import { ICellRendererAngularComp } from 'ag-grid-angular';

export interface ActiveEventInterface {
  rotation: number;
  flipped: boolean;
  sourceId: number;
}

@Component({
  selector: 'active-events',
  templateUrl: 'activeEvents/activeEvents.component.html',
  styles: [
    require('./activeEvents.component.scss'), // tslint:disable-line
  ],
  providers: [FilterListPipe, BlockedEventsService, BlockedBooksService],
})
export class ActiveEventsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() protected source: string;
  protected source$: BehaviorSubject<string>;
  protected subscriptions = new Subscription();
  protected showBlockedEvent = false;
  protected showBlockedBook = false;
  protected blockedEvents: number[] = [];
  protected blockedBooks: string[] = [];
  private activeEvents: ActiveEventInterface[];
  protected mapping = false;
  protected columnDefs: ColDef[] = [
    ...[
      ['sourceId', 'Source ID'],
      ['rotation', 'Rotation'],
      ['flipped', 'Flipped'],
    ].map(([field, headerName]) => ({
      field,
      headerName,
    })),
    {
      field: 'actions',
      headerName: 'Actions',
      cellRendererFramework: ActiveEventsActionsCellRenderer,
    },
  ];
  protected context = { controller: this };
  protected defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  };

  constructor(
    private activeEventsService: ActiveEventsService,
    private requestIdService: RequestIdService,
    private blockedEventsService: BlockedEventsService,
    private blockedBooksService: BlockedBooksService,
    private snackbarService: SnackBarService
  ) {}

  public ngOnInit(): void {
    LicenseManager.setLicenseKey(agGridLicense);
    this.source$ = new BehaviorSubject(this.source);
    const subs = this.source$
      .pipe(
        switchMap((source) =>
          combineLatest([
            this.activeEventsService.list(source),
            this.blockedEventsService.list(source),
            this.blockedBooksService.list(source),
          ]).pipe(
            tap(([activeEvents, blockedEvents, blockedBooks]) => {
              this.activeEvents = activeEvents;
              this.blockedEvents = blockedEvents.map(Number);
              this.blockedBooks = blockedBooks.map((b) => b.toUpperCase());
            }),
            catchError((err) => {
              this.activeEvents = [];
              this.blockedEvents = [];
              this.blockedBooks = [];
              this.snackbarService.addErrorMessage(err.message || (isObject(err) ? JSON.stringify(err) : err));
              return of();
            })
          )
        )
      )
      .subscribe();
    this.subscriptions.add(subs);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.source && !changes.source.firstChange) {
      this.source$.next(changes.source.currentValue);
    }
  }

  protected getRowNodeId(data: ActiveEventInterface): number {
    return data.rotation;
  }

  protected unblockBook(book: string): void {
    const subs = this.blockedBooksService.save(`${this.source}/${book}`, { action: 'unblock', book }).subscribe({
      next: (res) => {
        if (res) {
          this.blockedBooks = this.blockedBooks.filter((b) => b.includes(book.toUpperCase()));
          this.snackbarService.addSuccessMessage(`Book ${book} unblocked successfully`);
        } else {
          this.snackbarService.addErrorMessage('Nothing to do');
        }
      },
      error: (err) => this.snackbarService.addErrorMessage(err.message || (isObject(err) ? JSON.stringify(err) : err)),
    });
    this.subscriptions.add(subs);
  }

  protected unblockEvent(rotation: number): void {
    const subs = this.blockedEventsService.delete(`${this.source}/${rotation}`).subscribe({
      next: (res) => {
        if (res) {
          this.blockedEvents = this.blockedEvents.filter((e) => +e !== rotation);
          this.snackbarService.addSuccessMessage(`Event ${rotation} unblocked successfully`);
        } else {
          this.snackbarService.addErrorMessage('Nothing to do');
        }
      },
      error: (err) => this.snackbarService.addErrorMessage(err.message || (isObject(err) ? JSON.stringify(err) : err)),
    });
    this.subscriptions.add(subs);
  }

  protected deleteSource(sourceId: number): void {
    const subs = this.activeEventsService.delete(`${this.source}/${sourceId}`).subscribe({
      next: (res) => {
        if (res) {
          this.activeEvents = this.activeEvents.filter((e) => +e.sourceId !== +sourceId);
          this.snackbarService.addSuccessMessage(`Source ${sourceId} deleted successfully`);
        } else {
          this.snackbarService.addErrorMessage('Nothing to do');
        }
      },
      error: (err) => this.snackbarService.addErrorMessage(err.message || (isObject(err) ? JSON.stringify(err) : err)),
    });
    this.subscriptions.add(subs);
  }

  protected blockBook(book: string): void {
    if (this.blockedBooks.includes(book.toUpperCase())) {
      this.snackbarService.addErrorMessage('Nothing to do');
    } else {
      const subs = this.blockedBooksService.save(`${this.source}/${book}`, { action: 'block', book }).subscribe({
        next: (res) => {
          if (res.book.toUpperCase() === book.toUpperCase() && !this.blockedBooks.includes(book.toUpperCase())) {
            this.snackbarService.addSuccessMessage(`Book ${book} blocked successfully`);
            this.blockedBooks.push(book.toUpperCase());
          } else {
            this.snackbarService.addErrorMessage('Nothing to do');
          }
        },
        error: (err) => this.snackbarService.addErrorMessage(err.message || (isObject(err) ? JSON.stringify(err) : err)),
      });
      this.subscriptions.add(subs);
    }
  }

  protected blockEvent(rotation: number): void {
    if (this.blockedEvents.includes(+rotation)) {
      this.snackbarService.addErrorMessage('Nothing to do');
    } else {
      const subs = this.blockedEventsService.create(this.source, { rotation }).subscribe({
        next: (rot) => {
          if (+rot === +rotation && !this.blockedEvents.includes(+rot)) {
            this.snackbarService.addSuccessMessage(`Event ${rot} blocked successfully`);
            this.blockedEvents.push(+rot);
          } else {
            this.snackbarService.addErrorMessage('Nothing to do');
          }
        },
        error: (err) => this.snackbarService.addErrorMessage(err.message || (isObject(err) ? JSON.stringify(err) : err)),
      });
      this.subscriptions.add(subs);
    }
  }

  protected toggleFlip(sourceId: number, rotation: number, activeFlippedStatus: boolean): void {
    const subs = this.activeEventsService
      .update(this.source, {
        event_id: sourceId,
        rotation,
        flipOnly: true,
        flipped: !activeFlippedStatus,
      })
      .subscribe({
        next: (res) => {
          this.snackbarService.addSuccessMessage(`${res.rawInstance.flipped ? 'Flip' : 'Unlip'}ped successfully`);
          this.activeEvents = this.activeEvents.map((a) => ({
            ...a,
            ...(+a.sourceId === +sourceId && { flipped: !!res.rawInstance.flipped }),
          }));
        },
        error: (err) => this.snackbarService.addErrorMessage(err.message || (isObject(err) ? JSON.stringify(err) : err)),
      });
    this.subscriptions.add(subs);
  }

  protected mapId(sourceId: number, rotation: number, flipped: boolean): void {
    if (sourceId && rotation) {
      this.mapping = true;
      this.activeEventsService.update(this.source, { event_id: sourceId, rotation, flipped }).subscribe({
        next: (res) => {
          this.mapping = false;
          const result = res.result && res.result[0] && res.result[0].result;
          if (result === 0 || result === 1) {
            this.snackbarService.addSuccessMessage(result === 1 ? 'Mapping Created' : 'Mapping Updated');
          } else {
            this.snackbarService.addErrorMessage('Failed');
          }
        },
        error: (err) => this.snackbarService.addErrorMessage(err.message || (isObject(err) ? JSON.stringify(err) : err)),
      });
    } else {
      this.snackbarService.addErrorMessage('Mapping failed');
    }
  }

  protected request(source: string, id: number) {
    const subs = this.requestIdService.fetch(`${source}/${id}`).subscribe({
      next: () => this.snackbarService.addSuccessMessage(`${source} id ${id} requested!`),
      error: (err) => this.snackbarService.addErrorMessage(err.message || (isObject(err) ? JSON.stringify(err) : err)),
    });
    this.subscriptions.add(subs);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

@Component({
  template: `<a
      mat-raised-button
      [routerLink]="['/events', { pattern: data.rotation, eventSearchType: 'by-rotation' }]"
      >Visit rotations
    </a>
    <button
      color="primary"
      (click)="remove()"
      mat-raised-button>
      Remove
    </button>
    <button
      color="accent"
      (click)="flip()"
      mat-raised-button>
      Flip
    </button>`,
})
export class ActiveEventsActionsCellRenderer implements ICellRendererAngularComp {
  private params: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  protected get data(): ActiveEventInterface {
    return this.params.data;
  }

  public refresh(): boolean {
    return false;
  }

  protected remove(): void {
    this.params.context.controller.deleteSource(this.data.sourceId);
  }

  protected flip(): void {
    this.params.context.controller.toggleFlip(this.data.sourceId, this.data.rotation, this.data.flipped);
  }
}
