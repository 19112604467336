/* Copyright © 2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PeriodsService } from '../periods/periods.service';
import { AdminWagertypeInterface, CorrelationInterface } from '../../server/shared/models/gsModel';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SnackBarService } from '../services/snackbar.service';

type Correlation = CorrelationInterface & { allowSameMarket: boolean; isOverlapping: boolean };

type Period = {
  id: number;
  name: string;
};

type SideOption = {
  value: number;
  display: string;
};

type Data = {
  correlations?: Correlation[];
  periodsByRotation: Record<number, Period[]>;
  wagertypes: AdminWagertypeInterface[];
  sideOptions: SideOption[];
  rotations: number[];
};

type CorrelationFormGroup = FormGroup<{
  side: FormControl<number>;
  correlatedMinFigure: FormControl<number>;
  correlatedMaxFigure: FormControl<number>;
  minFigure: FormControl<number>;
  maxFigure: FormControl<number>;
  correlatedSide: FormControl<number>;
  allowSameMarket: FormControl<boolean>;
  isOverlapping: FormControl<boolean>;
  value: FormControl<number>;
  comment: FormControl<string>;
  vigIncrease: FormControl<number>;
  classifier: FormControl<string>;
}>;

type CorrelationGroup = {
  figureRange: string;
  side: number;
  correlatedMinFigure: number;
  correlatedMaxFigure: number;
  minFigure: number;
  maxFigure: number;
  correlatedSide: number;
  allowSameMarket: boolean;
  isOverlapping: boolean;
  value: number;
  comment: string;
  vigIncrease: number;
  classifier: string;
};

type CorrelationsForm = FormArray<CorrelationFormGroup>;

@Component({
  selector: 'correlations-form',
  templateUrl: 'correlations/correlationsForm.component.html',
  providers: [PeriodsService],
  styles: [
    require('./correlationsForm.component.scss'), // tslint:disable-line
  ],
})
export class CorrelationsFormComponent {
  protected period: number[] = [];
  protected wagertype: number[] = [];
  protected correlatedWagertype: number[] = [];
  protected correlatedPeriod: number[] = [];
  protected correlationForm: FormGroup;
  protected rotation: FormControl<number>;
  protected periods: Period[] = [];

  constructor(
    private dialogRef: MatDialogRef<CorrelationsFormComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: Data,
    private fb: FormBuilder,
    private snackbarService: SnackBarService
  ) {
    this.period = isFinite(data.correlations[0]?.period) ? [+data.correlations[0]?.period] : [];
    this.wagertype = isFinite(data.correlations[0]?.wagertype) ? [+data.correlations[0]?.wagertype] : [];
    this.correlatedWagertype = isFinite(data.correlations[0]?.correlatedWagertype) ? [+data.correlations[0]?.correlatedWagertype] : [];
    this.correlatedPeriod = isFinite(data.correlations[0]?.correlatedPeriod) ? [+data.correlations[0]?.correlatedPeriod] : [];
    this.rotation = new FormControl();
    this.rotation.valueChanges.subscribe((r) => {
      this.periods = this.data.periodsByRotation[r];
    });
    this.rotation.setValue(this.data.correlations[0]?.rotation ?? +this.data.rotations[0]);
    if (this.data.correlations.length) {
      this.rotation.disable();
    }

    const inputs = data.correlations.length ? data.correlations.map((corr) => this.createInputs(corr, false)) : [this.createInputs()];
    this.correlationForm = new FormGroup({
      correlations: this.fb.array([...inputs]),
    });
  }

  protected addInput(item = {}): void {
    const correlations = this.correlationForm.get('correlations') as CorrelationsForm;
    correlations.push(this.createInputs(item));
  }

  protected minValueValidator(min: number) {
    return (control: AbstractControl) => {
      return control.value >= min ? null : { minValue: { requiredValue: min, actualValue: control.value } };
    };
  }

  protected getFormErrorMessage(control: AbstractControl): string {
    const errors = [];

    if (control?.get('side').hasError('minValue')) {
      errors.push('Side must be greater than or equal to 1.');
    }
    if (control?.get('correlatedSide').hasError('minValue')) {
      errors.push('Correlated Side must be greater than or equal to 1.');
    }
    return errors.join('\n');
  }

  protected createInputs(
    {
      side = 1,
      minFigure = null,
      maxFigure = null,
      correlatedMinFigure = null,
      correlatedMaxFigure = null,
      correlatedSide = 1,
      allowSameMarket = false,
      isOverlapping = false,
      value = 0,
      comment = '',
      vigIncrease = 0,
      classifier = null,
    } = {},
    isNewField = true
  ): CorrelationFormGroup {
    return this.fb.group({
      classifier: [classifier],
      side: [side, isNewField ? [this.minValueValidator(1)] : []],
      minFigure: [minFigure],
      maxFigure: [maxFigure],
      correlatedMinFigure: [correlatedMinFigure],
      correlatedMaxFigure: [correlatedMaxFigure],
      correlatedSide: [correlatedSide, isNewField ? [this.minValueValidator(1)] : []],
      allowSameMarket: [allowSameMarket],
      isOverlapping: [isOverlapping],
      value: [value],
      comment: [comment],
      vigIncrease: [vigIncrease],
    });
  }

  protected removeInputs(index: number): void {
    const correlations = this.correlationForm.get('correlations') as FormArray;
    correlations.removeAt(index);
  }

  protected doSave(): void {
    if (!this.period.length || !this.wagertype.length || !this.correlatedPeriod.length || !this.correlatedWagertype.length) {
      return this.snackbarService.addErrorMessage('Period or wagertype missing');
    }
    const toCreate = [];
    const toUpdateOrDelete = [];
    const newCorrelations: CorrelationGroup[] = this.correlationForm.value.correlations;
    const initialDataMap = new Map(this.data.correlations.map((item) => [item.classifier, item]));
    newCorrelations.forEach((newItem) => {
      const corr: Partial<CorrelationInterface> = {
        ...newItem,
        period: +this.period,
        wagertype: +this.wagertype,
        correlatedPeriod: +this.correlatedPeriod,
        rotation: this.rotation.value,
        correlatedWagertype: +this.correlatedWagertype,
        timestamp: Date.now(),
      };
      if (corr.classifier) {
        const existingItem = initialDataMap.get(corr.classifier);
        toUpdateOrDelete.push({ ...existingItem, ...corr });
        initialDataMap.delete(corr.classifier);
      } else {
        toCreate.push(corr);
      }
    });
    initialDataMap.forEach((initialItem) => {
      toUpdateOrDelete.push({ ...initialItem, value: -1, timestamp: Date.now() });
    });
    this.dialogRef.close({ toCreate, toUpdateOrDelete });
  }
}
