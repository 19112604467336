/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Validator, ValidationResponse } from './validator';
import { LineInterface } from 'gs-templates/lib/line';

export class LineValidator extends Validator<LineInterface> {
  public validate(rotation: string, instance: LineInterface): ValidationResponse {
    let errors = [];
    errors.push(...this.checkInteger(rotation, 'rotation'));

    if (!instance) {
      errors.push('Must supply line info.');
    }

    if (Number(rotation) !== Number(instance.rotation)) {
       errors.push(`Key ${rotation} and rotation ${instance.rotation} must match`);
    }

    if (!instance.feed_source) {
      errors.push('Must supply a feed_source.');
    }

    if (!instance.book_name) {
      errors.push('Must supply a book name');
    }

    errors.push(...this.checkInteger(instance.rotation, 'rotation'));
    errors.push(...this.checkInteger(instance.timestamp, 'timestamp'));
    errors.push(...this.checkInteger(instance.period, 'period', -1));
    errors.push(...this.checkInteger(instance.wagertype, 'wagertype', -1));

    if (errors.length) {
      return {
        success: false,
        reasons: errors
      };
    }
    return {
      success: true
    };
  }

  private checkInteger(field, name, gt = 0) {
    if (!Number.isInteger(Number(field))) {
      return [`${name} must be an integer`];
    }
    if (Number(field) <= gt) {
      return [`${name} must be greater than ${gt}`];
    }
    return [];
  }
}
