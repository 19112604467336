/* Copyright © 2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { isString } from 'gs-utils/lib/utilities';

import { BaseRequestorService } from '../baseRequestor.service';
import { ValidationResponse } from '../../server/shared/validation/validator';
import { CreateTeamInterface } from '../../server/shared/models/createTeam';

@Injectable()
export class CreateTeamService extends BaseRequestorService<CreateTeamInterface, any> {

  constructor(protected http: HttpClient) {
    super('createTeam', http);
  }

  public validate(id: string, model: CreateTeamInterface): ValidationResponse {
    return { success: model.league_id > 0 && isString(model.name) };
  }
}
