/* Copyright © 2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ValidationResponse } from '../../server/shared/validation/validator';
import { User } from '../../server/shared/models/user';
import { FilterListPipe } from '../filters/filterListPipe.filter';
import { UsersService } from './users.service';
import { Observable, map, take, tap } from 'rxjs';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridAngular, ICellRendererAngularComp } from 'ag-grid-angular';
import { SnackBarService } from '../services/snackbar.service';
import { MatDrawer } from '@angular/material/sidenav';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import MTpermissions from './mtPermissions';
import { UserValidator } from '../../server/shared/validation/userValidator';
import { PasswordHash } from '../../server/shared/models/passwordHash';
import { ConfirmDialogComponent } from '../confirmDialog/confirmDialog.component';
import { IMultiSelectSettings } from '../multiselect/multiselect.types';
import { LicenseManager } from 'ag-grid-enterprise';
import agGridLicense from '../agGridLicense';

export interface AllowedPage {
  name: string;
  checked: boolean;
  parent: string;
  children?: AllowedPage[];
}

@Component({
  selector: 'users',
  templateUrl: 'users/users.component.html',
  styles: [
    require('./users.component.scss'), // tslint:disable-line
  ],
  providers: [FilterListPipe],
})
export class UsersComponent implements OnInit {
  protected users$: Observable<User[]>;
  protected userSelected: User = null;
  protected userForm: FormGroup = null;
  protected panelOpenState = false;
  protected isSaving = false;
  private validator = new UserValidator();
  protected usernames: { name: string; id: string }[] = [];
  @ViewChild(AgGridAngular) protected agGrid: AgGridAngular;
  @ViewChild('drawer') protected drawer: MatDrawer;
  protected selectSettings: IMultiSelectSettings = {
    enableSearch: true,
  };
  @HostListener('document:click', ['$event'])
  protected clickout(event) {
    const userContainer = document.querySelector('.users');
    if (
      this.drawer &&
      userContainer.contains(event.target) &&
      !event.target.matches('.user-action-icon, .fa-edit, .mdc-button__label') &&
      !this.drawer._content.nativeElement.contains(event.target) &&
      !this.isSaving
    ) {
      this.drawer.close();
      this.userSelected = null;
    }
  }
  protected columnDefs: ColDef[] = [
    ...[
      ['username', 'Username'],
      ['inherits', 'Inherits'],
    ].map(([field, headerName]) => ({
      field,
      headerName,
    })),
    {
      field: 'permissions',
      headerName: 'Permissions',
      cellRenderer: ({ data }) => {
        const pre = document.createElement('pre');
        pre.classList.add('raw-data');
        pre.textContent = data.permissions;
        return pre;
      },
      flex: 3,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      cellRendererFramework: UsersActionsCellRenderer,
    },
  ];
  protected context = { controller: this };
  protected defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    autoHeight: true,
  };

  constructor(
    private fb: FormBuilder,
    private usersService: UsersService,
    private snackbarService: SnackBarService,
    private dialog: MatDialog
  ) {
    this.userForm = this.fb.group({
      username: new FormControl<string>({ value: '', disabled: true }, [Validators.required]),
      inherits: new FormControl<string | string[]>([]),
      passwordHash: new FormControl<string>('', [Validators.required]),
      passwordSalt: new FormControl<string>('', [Validators.required]),
      permissions: new FormControl<string>(''),
    });
    LicenseManager.setLicenseKey(agGridLicense);
  }

  public ngOnInit(): void {
    this.refreshUserList();
    this.snackbarService.duration = 5 * 1000;
  }

  protected getRowNodeId(data: User): string {
    return data.username;
  }

  protected deleteUser(user: User): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: `Deleting user ${user.username}`, description: `Are you sure you want to delete ${user.username}?` },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((confirm) => {
        if (confirm) {
          this.usersService.delete(user.username).subscribe((success) => {
            if (success) {
              this.agGrid.api.applyTransaction({ remove: [user] });
              this.snackbarService.addSuccessMessage(`${user.username} deleted`);
            }
          });
        }
      });
  }

  protected editUser(user: User): void {
    this.drawer.open();
    this.userSelected = user;
    this.userForm.controls.username.disable();
    Object.entries(user).forEach(([key, value]) => {
      const v = key !== 'inherits' || Array.isArray(value) ? value : [value].filter(Boolean);
      this.userForm.controls[key].setValue(v);
    });
  }

  protected newUser(): void {
    this.userForm.reset();
    this.userSelected = null;
    this.drawer.open();
    this.userForm.controls.username.enable();
  }

  protected inheritsChange(usernames: string[]): void {
    this.userForm.controls.inherits.setValue(usernames);
  }

  protected editMTPermissions(): void {
    const dialogRef = this.dialog.open(MTPermissionsDialog, {
      data: this.userForm.controls.permissions.value,
      minWidth: '400px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.userForm.controls.permissions.setValue(JSON.stringify(result, null, 2));
      }
    });
  }

  protected save(): void {
    try {
      const { username, permissions, ...rest } = Object.fromEntries(
        Object.entries(this.userForm.controls).map(([key, control]) => [key, control.value])
      );
      this.isSaving = true;
      const inherits = rest.inherits?.length > 1 ? rest.inherits : rest.inherits?.[0] || '';
      const data = { ...rest, permissions: JSON.parse(permissions) ?? {}, inherits } as User;
      let validation = this.validate(username, data);
      if (validation.success) {
        this.usersService
          .save(username, data)
          .pipe(take(1))
          .subscribe(() => {
            this.agGrid.api.applyTransaction({ [this.userSelected ? 'update' : 'add']: [{ username, permissions, ...rest }] });
            this.snackbarService.addSuccessMessage(`${username} saved`);
            this.isSaving = false;
            this.refreshUserList();
          });
      } else {
        throw new Error(validation.reasons.join(',\n'));
      }
    } catch (error) {
      this.isSaving = false;
      this.snackbarService.addErrorMessage(error);
    }
  }

  protected validate(username: string, data: User): ValidationResponse {
    return this.validator.validate(username, data);
  }

  protected changePassword(password: PasswordHash): void {
    this.userForm.controls.passwordHash.setValue(password.passwordHash);
    this.userForm.controls.passwordSalt.setValue(password.passwordSalt);
  }

  private refreshUserList(): void {
    this.users$ = this.usersService.list().pipe(
      tap((users) => (this.usernames = Object.keys(users).map((name) => ({ id: name, name })))),
      map((user) =>
        Object.entries(user)
          .map(([username, value]) => ({ ...value, username, permissions: JSON.stringify(value.permissions, null, 2) }))
          .sort((u1, u2) => u1.username.localeCompare(u2.username))
      )
    );
  }
}

@Component({
  template: `
    <span
      (click)="edit()"
      class="user-action-icon">
      Edit <i class="fas fa-edit"></i>
    </span>
    <span
      (click)="remove()"
      class="user-action-icon">
      Delete <i class="fas fa-trash"></i>
    </span>
  `,
})
export class UsersActionsCellRenderer implements ICellRendererAngularComp {
  private params: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  protected get data(): User {
    return this.params.data;
  }

  public refresh(): boolean {
    return false;
  }

  protected remove(): void {
    this.params.context.controller.deleteUser(this.data);
  }

  protected edit(): void {
    this.params.context.controller.editUser(this.data);
  }
}

@Component({
  template: `
    <h1 mat-dialog-title>Manager Tools Permissions</h1>
    <mat-form-field class="users__drawer__form__input">
      <mat-label>Agent: </mat-label>
      <input
        [formControl]="agent"
        matInput />
    </mat-form-field>
    <div mat-dialog-content>
      <mt-permissions
        [pages]="allowedPages"
        (changeInput)="onInputChange($event)">
      </mt-permissions>
    </div>
    <div mat-dialog-actions>
      <button
        (click)="save()"
        mat-button>
        Save Permissions
      </button>
    </div>
  `,
})
export class MTPermissionsDialog {
  protected allowedPages = [];
  protected originalPermissions: Record<string, any> = {};
  protected permissions: Record<string, any> = null;
  protected agent = new FormControl('');

  constructor(public dialogRef: MatDialogRef<MTPermissionsDialog>, @Inject(MAT_DIALOG_DATA) public data: string) {
    this.originalPermissions = JSON.parse(data) ?? {};
    this.allowedPages = this.transformAllowedPages({ ...MTpermissions, ...this.originalPermissions?.managerTools?.allowedPages }, null);
    this.agent.setValue(this.originalPermissions?.managerTools?.agent);
  }

  protected transformAllowedPages(userPermissions: Record<string, any>, parent: string): AllowedPage[] {
    return Object.entries(userPermissions).map(([name, value]) => {
      const children =
        typeof value === 'object' || typeof MTpermissions[name] === 'object'
          ? this.transformAllowedPages({ ...MTpermissions[name], ...value }, name)
          : [];
      const hasChildrenChecked = children.some((c) => c.checked);
      const checked = typeof value === 'object' && !hasChildrenChecked ? null : hasChildrenChecked ? true : (value as boolean);
      return {
        name,
        parent,
        checked,
        children,
      };
    });
  }

  protected onInputChange(permisions: Record<string, any>): void {
    this.permissions = permisions;
  }

  protected save(): void {
    if (this.permissions || this.agent.value) {
      this.originalPermissions.managerTools = {
        ...this.originalPermissions?.managerTools,
        ...(this.agent.value && { agent: this.agent.value }),
        ...(this.permissions && { allowedPages: this.permissions }),
      };
    }
    this.dialogRef.close(this.originalPermissions);
  }
}
