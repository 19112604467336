/* Copyright © 2017-2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit } from '@angular/core';

import { DisplayStylesService } from './displayStyles.service';

const MESSAGE_TIMEOUT = 10000;

@Component({
  selector: 'display-styles',
  templateUrl: 'wagertypes/displayStyles.component.html',
  styles: [
    require('./displayStyles.component.scss')  // tslint:disable-line
  ],
  providers: [DisplayStylesService]
})
export class DisplayStylesComponent implements OnInit {
  private message: Record<string, string> = {};
  private isError: Record<string, boolean> = {};
  protected creating = false;
  protected displayStyleTypes: { id?: number; name: string }[] = [];

  constructor(
    private displayStyleTypesService: DisplayStylesService
  ) { /**/ }

  public ngOnInit(): void {
    this.displayStyleTypesService.list().subscribe(displayStyleTypes => {
      this.displayStyleTypes = Object.values(displayStyleTypes).sort((a, b) => a.name.localeCompare(b.name));
      this.sort();
    });
  }

  protected doSave(evt, id: number) {
    let name = evt.target.parentElement.parentElement.querySelector('input').value;
    let stat = this.displayStyleTypes.find(st => {
      return st.id === id;
    });
    if (stat.name === name) {
      alert('Nothing to do');
    } else {
      this.displayStyleTypesService.save(id, { id, name }).subscribe(newStat => {
        this.displayStyleTypes = this.displayStyleTypes.map(st => {
          if (st.id === newStat.id) {
            st.name = newStat.name;
          }
          return st;
        });
        this.sort();
        this.addMessage(id, 'Saved successfully', false);
      }, err => {
        this.addMessage(id, JSON.stringify(err), true);
      });
    }
  }

  protected doCreate(name: string, id: number) {
    if (name && id) {
      this.displayStyleTypesService.save(id, { name, id }).subscribe(newStat => {
        this.creating = !this.creating;
        alert(JSON.stringify(newStat));
        if (!this.displayStyleTypes.find(st => st.id === newStat.id)) {
          this.displayStyleTypes = this.displayStyleTypes.concat(newStat);
        }
      }, err => {
        alert(err.error || err.message || JSON.stringify(err));
        this.creating = !this.creating;
      });
    } else {
      this.creating = !this.creating;
      alert('Invalid Name or id');
    }
  }

  private sort() {
    this.displayStyleTypes.sort((s1, s2) => s1.id - s2.id);
  }

  private addMessage(id, message: string, isError: boolean) {
    this.message[id] = message;
    this.isError[id] = isError;

    setTimeout(() => {
      delete this.message[id];
      delete this.isError[id];
    }, MESSAGE_TIMEOUT);
  }
}
