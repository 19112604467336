/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import {Validator, ValidationResponse} from './validator';
import {Group} from '../models/group';

export class GroupValidator extends Validator<Group> {
  public validate(id: string, instance: Group): ValidationResponse {
    let errors = [];
    if (!id || !instance) {
      errors.push('Must supply a group name.');
    }

    if (id !== instance) {
      errors.push('Group name and id must be equivalent.');
    }

    if (typeof id !== 'string' || typeof instance !== 'string') {
      errors.push('Group name and id must be equivalent.');
    }

    if (errors.length) {
      return {
        success: false,
        reasons: errors
      };
    }
    return {
      success: true
    };
  }
}
