/* Copyright © 2017-2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import {Validator, ValidationResponse} from './validator';
import {CountryInterface} from 'gs-templates/lib/country';

export class CountryValidator extends Validator<CountryInterface> {
  public validate(id: CountryInterface['id'], instance: CountryInterface): ValidationResponse {
    let errors = [];
    if (!id) {
      errors.push('Must supply a country id.');
    }

    if (!instance) {
      errors.push('Must supply country info.');
    }

    if (!instance.id) {
      errors.push('Must supply a country id.');
    }

    if (!instance.name) {
      errors.push('Must supply a country name.');
    }

    if (!instance.code) {
      errors.push('Must supply a country code.');
    }

    if (errors.length) {
      return {
        success: false,
        reasons: errors
      };
    }
    return {
      success: true
    };
  }
}
