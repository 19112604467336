/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */

import { ComponentRef } from '@angular/core';

export class DialogModel<M = any> {
  protected componentRef: ComponentRef<M>;
  protected _dialog: any; //tslint:disable-line
  protected useTextArea: boolean;
  public content;
  public title;
  public cancelLabel;
  public confirmLabel;
  protected doResolve;
  protected doReject;
  private resultPromise;


  constructor() {
    this.resultPromise = new Promise((resolve, reject) => {
      this.doReject = reject;
      this.doResolve = resolve;
    });
  }

  public set config(dialog) {
    this._dialog = dialog;
    if (dialog.cancelLabel) {
      this.cancelLabel = dialog.cancelLabel;
    }
    if (dialog.confirmLabel) {
      this.confirmLabel = dialog.confirmLabel;
    }
    this.useTextArea = !!dialog.useTextArea;
    this.content = dialog.content;
    this.title = dialog.modalTitle;
    this.componentRef = dialog.ref;
  }

  public get result() {
    return this.resultPromise;
  }

  public close() {
    this.doClose(false);
  }

  protected doClose(payload) {
    if (payload) {
      this.doResolve(payload);
    } else {
      this.doReject();
    }
    this._dialog.close();
  }
}
