/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseRequestorService } from '../baseRequestor.service';
import { StandardPoints } from '../../server/shared/models/standardPoints';
import { StandardPointsValidator } from '../../server/shared/validation/standardPointsValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';


@Injectable()
export class StandardPointsService extends BaseRequestorService<StandardPoints, any> {
  public source: string;
  private standardPointsValidator = new StandardPointsValidator();  // TODO: should be injected

  constructor(protected http: HttpClient) {
    super('standardPoints', http);
  }

  public validate(id: string, model: StandardPoints): ValidationResponse {
    return this.standardPointsValidator.validate(id, model);
  }
}
