/* Copyright © 2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component } from '@angular/core';

@Component({
  selector: 'body',
  template: '<div></div>',
  styles: []
})
export class BodyComponent {
  public x = 'y';
}
