/* Copyright © 2017-2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit } from '@angular/core';

import { StatisticTypesService } from './statisticTypes.service';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { take } from 'rxjs';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { SnackBarService } from '../services/snackbar.service';

@Component({
  selector: 'statistics',
  templateUrl: 'wagertypes/statistics.component.html',
  styles: [
    require('./statistics.component.scss'), // tslint:disable-line
  ],
  providers: [StatisticTypesService],
})
export class StatisticsComponent implements OnInit {
  protected creating = false;
  protected statisticTypes: { id?: number; name: string }[] = [];

  constructor(private statisticTypesService: StatisticTypesService, private snackbarService: SnackBarService) {}

  protected context = { controller: this };
  protected columnDefs: ColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      resizable: false,
      sortable: false,
      filter: false,
      cellRendererFramework: StatisticActionsCellRenderer,
    },
  ];
  protected defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  };

  protected getRowNodeId(data: { id?: number; name: string }): number {
    return data.id;
  }

  public ngOnInit(): void {
    this.statisticTypesService
      .list()
      .pipe(take(1))
      .subscribe((statisticTypes) => {
        this.statisticTypes = Object.values(statisticTypes);
        this.sort();
      });
  }

  protected doSave({ name, id }: { id?: number; name: string }): void {
    const stat = this.statisticTypes.find((st) => st.id === id);
    if (stat.name === name) {
      this.snackbarService.addErrorMessage('Nothing to do');
    } else {
      this.statisticTypesService.save(id, { id, name }).subscribe(
        (newStat) => {
          this.snackbarService.addSuccessMessage(JSON.stringify(newStat));
          this.statisticTypes = this.statisticTypes.map((st) => {
            if (st.id === newStat.id) {
              st.name = newStat.name;
            }
            return st;
          });
          this.sort();
        },
        (err) => {
          this.snackbarService.addErrorMessage(JSON.stringify(err));
        }
      );
    }
  }

  protected doCreate(name: string): void {
    if (name) {
      this.statisticTypesService.create({ name }).subscribe(
        (newStat) => {
          this.creating = !this.creating;
          this.snackbarService.addSuccessMessage(JSON.stringify(newStat));
          if (!this.statisticTypes.find((st) => st.id === newStat.id)) {
            this.statisticTypes = this.statisticTypes.concat(newStat);
          }
        },
        (err) => {
          this.snackbarService.addErrorMessage(err.error || err.message || JSON.stringify(err));
          this.creating = !this.creating;
        }
      );
    } else {
      this.creating = !this.creating;
      this.snackbarService.addErrorMessage('Invalid Name');
    }
  }

  private sort(): void {
    this.statisticTypes.sort((s1, s2) => s1.id - s2.id);
  }
}

@Component({
  template: ` <button
    color="primary"
    (click)="save()"
    mat-raised-button>
    Save
  </button>`,
})
export class StatisticActionsCellRenderer implements ICellRendererAngularComp {
  private params: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  protected get data(): { id?: number; name: string } {
    return this.params.data;
  }

  public refresh(): boolean {
    return true;
  }

  protected save(): void {
    this.params.context.controller.doSave(this.data);
  }
}
