/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { ScoringRulesService } from './scoringRules.service';
import { ScoringRuleInterface } from '../../server/shared/models/scoringRule';

class RulePeriod {
  public periodForm: FormGroup;
  public id: number;
  public raceTo: FormControl;
  public winBy: FormControl;
  public winByTie: FormControl;
  public name: string;
  public owner: FormGroup;

  constructor(periodId: number, form: FormGroup, values?: { raceTo?: number, winBy?: number, winByTie?: number }) {
    this.periodForm = new FormGroup({});
    this.id = periodId;
    this.raceTo = new FormControl(values && values.raceTo, [Validators.required]);
    this.winBy = new FormControl(values && values.winBy, [Validators.required]);
    this.winByTie = new FormControl(values && values.winByTie);
    this.owner = form;

    this.name = `period-${this.id}`;

    this.periodForm.addControl('raceTo', this.raceTo);
    this.periodForm.addControl('winBy', this.winBy);
    this.periodForm.addControl('winByTie', this.winByTie);

    this.owner.addControl(this.name, this.periodForm);
  }

  public remove() {
    this.owner.removeControl(this.name);
  }
}

@Component({
  selector: 'scoring-rule-dialog',
  templateUrl: 'scoringRules/scoringRuleDialog.component.html',
  styles: [
    require('./scoringRuleDialog.component.scss')  // tslint:disable-line
  ]
})
export class ScoringRuleDialogComponent implements OnInit {
  protected ruleForm = new FormGroup({});
  protected periodCountControl = new FormControl();
  protected ruleNameControl = new FormControl('', [Validators.required]);
  protected bestOfControl = new FormControl();
  protected sameRulesForPeriodsControl = new FormControl(true);
  protected periodsGroup = new FormGroup({}, this.allOrNoneWinByTieValidator());
  protected periods: RulePeriod[] = [];
  protected saving = false;
  protected success = false;
  protected msg = '';

  @Input() private rule: ScoringRuleInterface & { key: string };
  @Output() private close = new EventEmitter();

  constructor(private scoringRulesService: ScoringRulesService) { }

  public ngOnInit() {
    this.initializeControls();
    this.registerControls();

    this.generatePeriods(
      this.rule && this.rule.race_to,
      this.rule && this.rule.win_by,
      this.rule && this.rule.win_by_tie
    );

    this.periodCountControl.valueChanges.subscribe(() => this.updatePeriods());
    this.sameRulesForPeriodsControl.valueChanges.subscribe(() => this.updatePeriods());
    this.periodsGroup.statusChanges.subscribe((status) => {
      if (status === 'INVALID') {
        this.msg = this.periodsGroup.errors ? this.periodsGroup.errors.allOrNoneWinByTie : '';
      } else {
        this.msg = '';
      }
    });
  }

  protected save() {
    this.msg = '';
    this.saving = true;
    let ruleToSave = this.generateObjectToSave();
    let key = this.rule && this.rule.key || undefined;
    this.scoringRulesService.save(key || 'new', ruleToSave, key ? { key } : {}).subscribe(
      result => {
        this.saving = false;
        this.success = true;
        this.msg = !!this.rule ? 'Rule updated' : 'Rule created successfully.';
      },
      (response) => {
        this.saving = false;
        this.msg = response.error || 'An error has ocurred. Try again.';
      });
  }

  protected closeDialog() {
    this.close.emit(this.success);
  }

  protected generatePeriods(raceTo?: number | number[], winBy?: number | number[], winByTie?: number | number[]) {
    if (this.sameRulesForPeriodsControl.value) {
      if (!this.periods.length) {
        this.periods = [new RulePeriod(1, this.periodsGroup, {
          raceTo: this.extractValue(raceTo, 0),
          winBy: this.extractValue(winBy, 0),
          winByTie: this.extractValue(winByTie, 0)
        })];
      }
    } else {
      if (this.periodCountControl.value > this.periods.length) {
        for (let i = this.periods.length; i < this.periodCountControl.value; i++) {
          this.periods.push(
            new RulePeriod(
              i + 1,
              this.periodsGroup,
              {
                raceTo: this.extractValue(raceTo, i),
                winBy: this.extractValue(winBy, i),
                winByTie: this.extractValue(winByTie, i)
              }
            )
          );
        }
      }
    }

    this.periods.splice(this.periodCount);
  }

  protected applyToAll(period, prop) {
    this.periods.forEach(p => {
      p[prop].setValue(period[prop].value);
    });
  }

  private updatePeriods() {
    this.periods.forEach(period => period.id > this.periodCount && period.remove());
    this.generatePeriods();
    this.ruleForm.updateValueAndValidity();
  }

  private generateObjectToSave(): ScoringRuleInterface {
    let objToSave: ScoringRuleInterface = {
      name: this.ruleNameControl.value,
      race_to: [],
      win_by: [],
      win_by_tie: []
    };
    if (this.sameRulesForPeriodsControl.value) {
      objToSave.race_to = this.periods[0].raceTo.value || undefined;
      objToSave.win_by = this.periods[0].winBy.value || undefined;
      objToSave.win_by_tie = this.periods[0].winByTie.value || undefined;
    } else {
      this.periods.reduce((ruleInfo, periodRule) => {
        (ruleInfo.race_to as number[]).push(periodRule.raceTo.value || '');
        (ruleInfo.win_by as number[]).push(periodRule.winBy.value || '');
        (ruleInfo.win_by_tie as number[]).push(periodRule.winByTie.value || '');
        return ruleInfo;
      }, objToSave);
      this.simplifyObject(objToSave);
    }
    objToSave.best_of = this.bestOfControl.value || undefined;
    return objToSave;
  }

  private simplifyObject(obj) {
    this.trySingleValOnProp(obj, 'race_to');
    this.trySingleValOnProp(obj, 'win_by');
    this.trySingleValOnProp(obj, 'win_by_tie');

    this.clearPropIfEmpty(obj, 'race_to');
    this.clearPropIfEmpty(obj, 'win_by');
    this.clearPropIfEmpty(obj, 'win_by_tie');
  }

  private trySingleValOnProp(obj, prop) {
    if (obj[prop].every((val, i, arr) => val === arr[0])) {
      obj[prop] = obj[prop][0];
    }
  }

  private clearPropIfEmpty(obj, prop) {
    if ((Array.isArray(obj[prop]) && obj[prop].filter(item => !item).length === obj[prop].length) || !obj[prop]) {
      delete obj[prop];
    }
  }

  private initializeControls() {
    let sameRulesForPeriods = this.rule ? !Array.isArray(this.rule.race_to || this.rule.win_by || this.rule.win_by_tie) : true;
    let periodsCount =
      this.rule && (Array.isArray(this.rule.race_to) && this.rule.race_to.length ||
        Array.isArray(this.rule.win_by) && this.rule.win_by.length ||
        Array.isArray(this.rule.win_by_tie) && this.rule.win_by_tie.length) || 1;
    this.periodCountControl = new FormControl(periodsCount);
    this.ruleNameControl = new FormControl(this.rule && this.rule.name || '', [Validators.required]);
    this.bestOfControl = new FormControl(this.rule && this.rule.best_of || null);
    this.sameRulesForPeriodsControl = new FormControl(sameRulesForPeriods);
  }

  private registerControls() {
    this.ruleForm.addControl('periods', this.periodCountControl);
    this.ruleForm.addControl('name', this.ruleNameControl);
    this.ruleForm.addControl('same-rules', this.sameRulesForPeriodsControl);
    this.ruleForm.addControl('periodGroup', this.periodsGroup);
    this.ruleForm.addControl('best-of', this.bestOfControl);
  }

  private extractValue(arr: number[] | number, index: number): number | undefined {
    if (!arr) { return; }
    return Array.isArray(arr) ? arr[index] : arr;
  }

  private get periodCount() {
    return (this.sameRulesForPeriodsControl.value && 1) || this.periodCountControl.value;
  }

  private allOrNoneWinByTieValidator(): ValidatorFn {
    return (periodGroupControl: AbstractControl): { [key: string]: any } | null => {
      let isAnyWinByTieSet = Object.keys(periodGroupControl.value).some(key => !!periodGroupControl.value[key].winByTie);
      let areAllFillOrEmpty = Object.keys(periodGroupControl.value)
        .every(key => isAnyWinByTieSet === !!periodGroupControl.value[key].winByTie);
      return !areAllFillOrEmpty ? { allOrNoneWinByTie: 'If one tiebreaker win is set, all should be.' } : null;
    };
  }
}
