/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { BaseRequestorService } from '../baseRequestor.service';

interface Store {
  id: string;
  name: string;
}

@Injectable()
export class StoresService extends BaseRequestorService<Store, any> {

  constructor(protected http: HttpClient) {
    super('stores', http, 10000);
  }
}
