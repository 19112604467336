/* Copyright © 2017-2018 Ganchrow Scientific, SA all rights reserved */
'use strict';

import {WagertypeInterface} from 'gs-templates/lib/wagertype';

import {Validator, ValidationResponse} from './validator';

export class WagertypeValidator extends Validator<WagertypeInterface> {
  public validate(id: any, instance: WagertypeInterface): ValidationResponse {
    let errors = [];
    if (!id) {
      errors.push('Must supply a wagertype id.');
    }

    if (!instance) {
      errors.push('Must supply wagertype info.');
    }

    if (!instance.id) {
      errors.push('Must supply a wagertype id.');
    }

    if (!instance.name) {
      errors.push('Must supply a wagertype name.');
    }

    if (errors.length) {
      return {
        success: false,
        reasons: errors
      };
    }
    return {
      success: true
    };
  }
}
