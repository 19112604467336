/* Copyright © 2023 Ganchrow Scientific, SA all rights reserved */

'use strict';

import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventSourcesService } from '../events/eventSources.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { Observable, combineLatest, map } from 'rxjs';

const SELF = '/mediator';
const ROTATION_CHANGE = 'rotationChange';

@Component({
  selector: 'mediator',
  templateUrl: 'mediator/mediator.component.html',
  styles: [
    require('./mediator.component.scss'), // tslint:disable-line
  ],
})
export class MediatorComponent {
  protected eventSources$: Observable<[string, string][]>;
  protected active = '';

  constructor(private eventSourcesService: EventSourcesService, private state: ActivatedRoute, private router: Router) {}

  protected ngOnInit(): void {
    this.eventSources$ = combineLatest([this.eventSourcesService.list(), this.state.params]).pipe(
      map(([list, params]) => {
        const { type } = params;
        this.active = type === ROTATION_CHANGE ? type : list.find((o) => o[0] === type) ? type : null;
        return list;
      })
    );
  }

  protected navigate({ value }: MatButtonToggleChange): void {
    this.router.navigate([SELF, { type: value }]);
  }

  protected capitalize(str: string): string {
    return str
      .split('-')
      .map((s) => s.toLowerCase().replace(/^[a-z]/, (s[0] || '').toUpperCase()))
      .join('-');
  }
}
