/* Copyright © 2017-2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseRequestorService } from '../baseRequestor.service';
import { Sports, AdminSportInterface } from '../../server/shared/models/gsModel';
import { SportValidator } from '../../server/shared/validation/sportValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';


export const EMPTY_LEAGUE = {
  label: '', name: ''
};

@Injectable()
export class SportsService extends BaseRequestorService<AdminSportInterface, Sports> {
  private sportValidator = new SportValidator();  // TODO: should be injected

  constructor(protected http: HttpClient) {
    super('sports', http, 30000);
  }

  public validate(id: string, model: AdminSportInterface): ValidationResponse {
    return this.sportValidator.validate(id, model);
  }
}
