/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const BLOCKED_EVENTS_PATH = '/api/blockSourceEvent';

@Injectable()
export class BlockedEventsService {

  constructor(protected http: HttpClient) {
    /**/
  }

  public delete(key: string) {
    return this.http.delete(`${BLOCKED_EVENTS_PATH}/${key}`, { withCredentials: true });
  }

  public create(key: string, body: Object) {
    return this.http.post(`${BLOCKED_EVENTS_PATH}/${key}`, body, { withCredentials: true });
  }

  public list(key: string): Observable<any> {
    return this.http.get(`${BLOCKED_EVENTS_PATH}/${key}`);
  }
}
