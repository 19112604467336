/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { FilterListPipe } from '../filters/filterListPipe.filter';
import { Group, Groups } from '../../server/shared/models/group';
import { GroupValidator } from '../../server/shared/validation/groupValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';

import { GroupsService } from './groups.service';


@Component({
  selector: 'groups',
  templateUrl: 'groups/groups.component.html',
  styles: [
    require('./groups.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe]
})

export class GroupsComponent implements OnInit {
  private groupForm: FormGroup;
  private groups: Groups;
  private validator: GroupValidator = new GroupValidator();
  private formErrors: Record<string, string> = {};

  constructor(private fb: FormBuilder, private groupsService: GroupsService) { /**/ }

  public ngOnInit(): void {
    this.groupsService.list().subscribe(groups => this.buildForm(groups));
    this.buildForm({});
  }

  protected saveGroup(newGroup: Group, oldGroup: Group) {
    if (newGroup !== oldGroup && this.validate(newGroup).success) {
      this.deleteGroup(oldGroup);
      this.createGroup(newGroup);
    }
  }

  protected deleteGroup(oldGroup: Group) {
    this.groupsService.delete(oldGroup).subscribe(success => {
      if (success) {
        delete this.groups[oldGroup];
        this.buildForm(this.groups);
      }
    });
  }

  protected createGroup(newGroup: Group) {
    if (this.validate(newGroup).success) {
      this.groupsService.save(newGroup, newGroup).subscribe(result => {
        this.groups[newGroup] = newGroup;
        this.buildForm(this.groups);
      });
    }
  }

  protected get groupNames() {
    return Object.keys(this.groups);
  }

  protected validate(groupName: string): ValidationResponse {
    return this.validator.validate(groupName, groupName);
  }

  protected buildForm(groups: Groups): void {
    this.groups = groups;
    let fields: any = { };
    Object.keys(this.groups).forEach(group => {
      fields[group] = [group, [Validators.required] ];
    });
    fields.__newGroup = ['', [Validators.required] ];
    fields['groups-filter'] = ['', []];
    this.groupForm = this.fb.group(fields);

    this.groupForm.valueChanges
      .subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // (re)set validation messages now
  }

  protected cancelGroup(groupName) {
    this.groups[groupName] = groupName;
    let control = this.groupForm.get(groupName);
    control.setValue(groupName);
  }

  protected onValueChanged(data?) {
    if (!this.groupForm) {
      return;
    }

    const form = this.groupForm;
    Object.keys(this.groups).forEach(group => {
      // clear previous error message (if any)
      this.formErrors[group] = '';
      const control = form.get(group);
      if (control && control.dirty) {
        const result = this.validate(control.value);
        if (!result.success) {
          this.formErrors[group] = result.reasons.join(',\n');
        }
      }
    });
  }
}
