/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EventSourcesService } from './eventSources.service';
import { RequestIdService } from './requestId.service';
import { RequestOutrightIdService } from './requestOutrightId.service';

@Component({
  selector: 'request--event',
  templateUrl: 'events/requestEvent.component.html',
  styles: [
    require('./requestEvent.component.scss')  // tslint:disable-line
  ]
})
export class RequestEventComponent {
  public isProcessing = false;
  public eventSources: [string, string][] = [];
  private canConfirmSubject = new BehaviorSubject(true);
  private requestSources: Record<string, number> = {};

  constructor(
    private eventSourcesService: EventSourcesService,
    protected requestIdService: RequestIdService,
    protected requestOutrightIdService: RequestOutrightIdService,
  ) { }

  public ngOnInit() {
    this.eventSourcesService.list().subscribe(e => this.eventSources = e);
  }

  public get canConfirm() {
    return this.canConfirmSubject.asObservable();
  }

  protected requestSourcesRecord(source: string, $event) {
    this.requestSources[source] = $event.target.value;
  }

  protected requestId(source: string, $event) {
    if (this.requestSources[source]) {
      let isOutright = this.findIsOutrightChecked($event, source, 'event');
      this[`request${ isOutright ? 'Outright' : ''}IdService`].fetch(`${source}/${this.requestSources[source]}`).subscribe(() => {
        alert(`${source} id ${this.requestSources[source]} requested!`);
      });
    }
  }

  private findIsOutrightChecked($event: any, source: string, kind: 'event' | 'league'): boolean {
    if (source === 'lsports') {
      let supplemental = kind === 'league' ? 'league--' : '';
      return $event.target.parentElement.parentElement.querySelector(`#eventSources--${supplemental}${source}--outright`).checked;
    }
  }
}
