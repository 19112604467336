/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ScoringRules } from '../../server/shared/models/gsModel';
import { BaseRequestorService } from '../baseRequestor.service';

import { ScoringRuleInterface } from '../../server/shared/models/scoringRule';
@Injectable()
export class ScoringRulesService extends BaseRequestorService<ScoringRuleInterface, ScoringRules> {
  constructor(protected http: HttpClient) {
    super('scoringRules', http);
  }
}
