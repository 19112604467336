/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component } from '@angular/core';
import { DialogModel } from '../../dialog.model';

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'dialog/kinds/confirm-dialog/confirm-dialog.component.html',
})
export class ConfirmDialogComponent extends DialogModel<ConfirmDialogComponent> {
  constructor() {
    super();
    this.cancelLabel = 'Cancel';
    this.confirmLabel = 'Confirm';
  }
}
