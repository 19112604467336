/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { isObject } from 'gs-utils/lib/utilities';

@Pipe({
  name: 'json',
  pure: false
})
@Injectable()
export class JsonStringifyPipe implements PipeTransform {
  public transform(item: any, pretty: boolean, removeCurlies?: boolean): string {
    let indent = pretty && !removeCurlies ? 2 : undefined;
    let str: string = isObject(item) ? JSON.stringify(item, null, indent) : item;

    if (removeCurlies) {
      str = str.substring(1, str.length - 1).trim();
      if (pretty) {
        str = str.replace(/,\"/g, ',\n"');
      }
    }
    return str;
  }
}
