/* Copyright © 2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { OnInit, Inject, Component, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription, combineLatest, map, throwError } from 'rxjs';
import { AggregatorTemplatesService } from './aggregatorTemplates.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from '../services/snackbar.service';
import { isObject } from 'gs-utils/lib/utilities';
import { OffertypeInterface } from '../../server/shared/models/offertype';

type AggregatorTemplatesForm = FormArray<
  FormGroup<{
    templateId: FormControl<string>;
    templateType: FormControl<string>;
  }>
>;

@Component({
  selector: 'aggregator-templates',
  templateUrl: 'offertypes/aggregatorTemplates.component.html',
  styles: [
    require('./aggregatorTemplates.component.scss'), // tslint:disable-line
  ],
})
export class AggregatorTemplates implements OnInit, OnDestroy {
  protected aggregatorTemplateData$: Observable<{ id: string; name: string }[]>;
  protected aggregatorTemplateForm: FormGroup;
  protected eventId = new FormControl(null);
  protected unmapped: { id: string | number; name: string }[] = [];
  protected fetchingResults = false;
  protected subscriptions = new Subscription();
  protected eventSelected = null;
  protected availableSourcesToFetch = {
    'betsapi-bwin': true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { source: string; offertypes: OffertypeInterface[] },
    private aggregatorTemplatesService: AggregatorTemplatesService,
    private snackbarService: SnackBarService,
    private fb: FormBuilder
  ) {
    this.aggregatorTemplateForm = new FormGroup({
      aggregatorTemplates: this.fb.array([this.createInputs()]),
    });
  }

  public ngOnInit(): void {
    this.snackbarService.duration = 5 * 1000;
    this.aggregatorTemplateData$ = this.aggregatorTemplatesService
      .getList(this.data.source)
      .pipe(map((res) => res.templateTypes.map((t) => ({ id: t, name: t }))));
  }

  protected requestEvent(): void {
    this.fetchingResults = true;
    const sub = this.aggregatorTemplatesService.getEvent(this.data.source, this.eventId.value).subscribe({
      next: (result) => {
        this.unmapped = result.filter(
          (r) =>
            !this.data.offertypes.find((o) => {
              const id = String(o.id).split(':')[0];
              return id.includes(r.name);
            })
        );
        this.snackbarService.addSuccessMessage('Event requested!');
        this.eventSelected = this.eventId.value;
        this.eventId.disable();
        this.fetchingResults = false;
      },
      error: (err) => {
        this.snackbarService.addErrorMessage(err.message || (isObject(err) ? JSON.stringify(err) : err));
        this.fetchingResults = false;
        this.clearEvent();
      },
    });
    this.subscriptions.add(sub);
  }

  protected createInputs({ templateId = null, templateType = null } = {}): FormGroup<{
    templateId: FormControl<string>;
    templateType: FormControl<string>;
  }> {
    return this.fb.group({
      templateId: [templateId, Validators.required],
      templateType: [templateType, Validators.required],
    });
  }

  protected addInputs({ templateId = null, templateType = null } = {}): void {
    const aggregatorTemplates = this.aggregatorTemplateForm.get('aggregatorTemplates') as AggregatorTemplatesForm;
    if (aggregatorTemplates.at(0).value.templateId) {
      aggregatorTemplates.push(this.createInputs({ templateId, templateType }));
    } else {
      aggregatorTemplates.at(0).patchValue({ templateId });
    }
  }

  protected addUnmapped(id: string | number): void {
    this.addInputs({ templateId: id });
  }

  protected addAllUnmapped(): void {
    [...new Set(this.unmapped.map((u) => u.id))].forEach((item) => {
      this.addUnmapped(item);
    });
  }

  protected removeInputs(index: number): void {
    const aggregatorTemplates = this.aggregatorTemplateForm.get('aggregatorTemplates') as FormArray;
    aggregatorTemplates.removeAt(index);
  }

  protected openEvent(): void {
    this.aggregatorTemplatesService.goToEvent(this.data.source, this.eventId.value);
  }

  protected clearEvent(): void {
    this.eventSelected = null;
    this.eventId.reset();
    this.eventId.enable();
    this.unmapped = [];
  }

  protected doSave(): void {
    this.fetchingResults = true;
    const obs = (this.aggregatorTemplateForm.value.aggregatorTemplates as Array<{ templateId: string; templateType: string }>).map(
      ({ templateId, templateType }) => {
        if (templateId && templateType) {
          return this.aggregatorTemplatesService.saveTemplate(this.data.source, { templateId, templateType });
        }
        const error = new Error('TemplateId or TemplateType is empty');
        throwError(() => error);
      }
    );
    const subs = combineLatest(obs).subscribe({
      next: () => {
        this.snackbarService.addSuccessMessage('Offertype mapped successfully!');
        this.fetchingResults = false;
      },
      error: (err) => {
        this.snackbarService.addErrorMessage(err.message || (isObject(err) ? JSON.stringify(err) : err));
        this.fetchingResults = false;
      },
    });
    this.subscriptions.add(subs);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
