/* Copyright © 2023 Ganchrow Scientific, SA all rights reserved */

'use strict';

import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Observable, Subscription, map, startWith, tap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { IMultiSelectOption, IMultiSelectSettings } from '../multiselect/multiselect.types';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

function isIMultiSelectOption(value: string | IMultiSelectOption): value is IMultiSelectOption {
  return (value as IMultiSelectOption)?.name !== undefined;
}

@Component({
  selector: 'gs-autocomplete',
  templateUrl: 'autocomplete/autocomplete.component.html',
  styles: [require('./autocomplete.component.scss')], // tslint:disable-line
})
export class AutocompleteComponent implements OnDestroy {
  @Input() public options: IMultiSelectOption[] = [];
  @Input() public title = '';
  @Input() public selected: string = null;
  @Input() public control: FormControl<string> = new FormControl(null);
  @Input() public settings: IMultiSelectSettings = {};
  @Input() public errorMessage: string = null;
  @Output() public selectedChange = new EventEmitter();
  protected input = new FormControl<IMultiSelectOption | string>('');
  protected filteredOptions$: Observable<IMultiSelectOption[]>;
  protected subscriptions = new Subscription();

  public ngOnInit(): void {
    this.filteredOptions$ = this.input.valueChanges.pipe(map((value) => this._filter(value)));
    const sub = this.control.valueChanges.pipe(tap((value) => this.input.setValue(this.options.find((o) => o.id === value)))).subscribe();
    this.subscriptions.add(sub);
  }

  protected displayFn(option: IMultiSelectOption): string {
    return option && option.name ? option.name : '';
  }

  protected onChange(event: MatAutocompleteSelectedEvent): void {
    const { id } = event.option.value;
    this.control.setValue(id);
    this.selected = id;
    this.selectedChange.emit(id);
  }

  private _filter(value: string | IMultiSelectOption): IMultiSelectOption[] {
    const name = isIMultiSelectOption(value) ? value.name : value;
    return name ? this.options.filter((option) => option.name.toLowerCase().includes(name)) : this.options.slice();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
