/* Copyright © 2017-2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import {Validator, ValidationResponse} from './validator';
import {SportInterface} from 'gs-templates/lib/sport';

export class SportValidator extends Validator<SportInterface> {
  public validate(id: SportInterface['id'], instance: SportInterface): ValidationResponse {
    let errors = [];
    if (!id) {
      errors.push('Must supply a sport id.');
    }

    if (!instance) {
      errors.push('Must supply sport info.');
    }

    if (!instance.id) {
      errors.push('Must supply a sport id.');
    }

    if (!instance.name) {
      errors.push('Must supply a sport name.');
    }

    if (errors.length) {
      return {
        success: false,
        reasons: errors
      };
    }
    return {
      success: true
    };
  }
}
