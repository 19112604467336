/* Copyright © 2022 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const PATH = '/api/reverseGameTime';

@Injectable()
export class ReverseGameTimeService {
  constructor(protected http: HttpClient) { /**/ }

  public toggle(rotations: number[]): Observable<number[]> {
    return this.http.put<number[]>(PATH, { rotations: rotations });
  }
}
