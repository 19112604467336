/* Copyright © 2017-2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { LeagueInterface } from 'gs-templates/lib/league';
import { IMultiSelectOption, IMultiSelectSettings } from '../multiselect/multiselect.types';
import { AdminLeagueInterface } from '../../server/shared/models/gsModel';

import { FilterListPipe } from '../filters/filterListPipe.filter';

import { LeaguesService, EMPTY_LEAGUE } from './leagues.service';

@Component({
  selector: 'leagues-drop-down',
  templateUrl: 'leagues/leaguesDropDown.component.html',
  styles: [
    require('./leaguesDropDown.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe]
})

export class LeaguesDropDownComponent implements OnInit {
  protected leagueOptions: IMultiSelectOption[] = [];
  private leagues: Record<string, AdminLeagueInterface>;
  protected manualChange = false;
  protected selectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 2,
    containerClasses: 'leagues-drop-down--select--container',
    buttonClasses: 'form-control leagues-drop-down--select--selector',
    checkedStyle: 'glyphicon',
    showUncheckAll: true
  };
  private singleSelection = false;
  private leagueClasses = {};

  @Input() public simple = false;
  @Input() protected selectedLeagueIds: number[] = [];
  @Input() protected filterMappedLeaguesForTeams = false;
  @Input() protected filterMappedLeagues = false;
  @Input() protected enableSelection = true;
  @Input() protected selectionLimit;

  @Output() protected leaguesChanged = new EventEmitter<{league: AdminLeagueInterface, leagueIds: number[], labels: string[]}>();

  constructor(private leaguesService: LeaguesService) { /**/ }

  public ngOnInit(): void {
    if (this.selectionLimit) {
      this.singleSelection = this.selectionLimit === 1;
      this.selectSettings.selectionLimit = this.selectionLimit;
      this.selectSettings.closeOnSelect = this.singleSelection;
    }
    this.leaguesService.list(this.config).subscribe(leagues => {
      this.leagues = leagues;
      this.leagueOptions = Object.entries(leagues).map(([id, league]) => {
        this.processLeagueClass(league);
        return { id: Number(id), name: this.getLeagueLabel(league), sportId: league.sport_id };
      });
      this.sort();
    });
  }

  protected getLeagueLabel(league: LeagueInterface): string {
    return `${league.id}: ${league.name} (${league.sport_id})`;
  }

  protected handleLeaguesChanged() {
    if (this.manualChange) {
      let selectedLeagueIds = !this.singleSelection ? this.expandLeaguesWithClass(this.selectedLeagueIds) : this.selectedLeagueIds;
      this.leaguesChanged.emit({
        league: this.singleSelection ? this.leagues?.[this.selectedLeagueIds[0]] ?? null : null,
        leagueIds: !this.singleSelection ? selectedLeagueIds : null,
        labels: (selectedLeagueIds || [])
          .map(id => (this.leagueOptions.find(opt => Number(opt.id) === id) || EMPTY_LEAGUE).name)
          .filter(name => !!name)
      });
    }
  }

  private sort(): void {
    this.leagueOptions.sort((u1, u2) => Number(u1.id) - Number(u2.id));
  }

  private expandLeaguesWithClass(leagueIds: number[]): number[] {
    return Array.from(leagueIds.reduce((lgs, lgId) => {
      let cls = this.leagues[lgId].name.toLowerCase().trim() + ':' + this.leagues[lgId].sport_id;
      if (this.leagueClasses[cls]) {
        this.leagueClasses[cls].forEach(id => {
          lgs.add(id);
        });
      } else {
        lgs.add(lgId);
      }
      return lgs;
    }, new Set() as Set<number>));
  }

  private processLeagueClass(league: AdminLeagueInterface) {
    if (this.singleSelection || !league.class) {
      return;
    }
    let cls = league.class.toLowerCase().trim() + ':' + league.sport_id;
    this.leagueClasses[cls] = this.leagueClasses[cls] || new Set();
    this.leagueClasses[cls].add(league.id);
  }

  private get config(): { withFilter: boolean; withMappedFilter: boolean } {
    return {
      withFilter: this.filterMappedLeaguesForTeams, withMappedFilter: this.filterMappedLeagues
    };
  }
}
