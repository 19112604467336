/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { dup } from 'gs-utils/lib/utilities';

/**
 * Pipe that orders a list of strings
 */
@Pipe({
  name: 'stringOrderBy',
})
@Injectable()
export class StringOrderByPipe implements PipeTransform {
  public transform(args: string[]): string[] {
    return dup(args).sort();
  }
}

@Pipe({
  name: 'numberOrderBy',
})
@Injectable()
export class NumberOrderByPipe implements PipeTransform {
  public transform(args: (string|number)[]): (string|number)[] {
    return args.map(arg => Number(arg)).sort((a, b) => a - b);
  }
}
