/* Copyright © 2017-2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PasswordHash, UsernamePassword } from '../../server/shared/models/passwordHash';
import { PasswordGeneratorService } from './passwordGenerator.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'password-generator',
  templateUrl: 'passwordGenerator/passwordGenerator.component.html',
  styles: [
    require('./passwordGenerator.component.scss')  // tslint:disable-line
  ]
})
export class PasswordGeneratorComponent implements OnChanges {
  @Input() protected username = '';
  @Output() protected passwordChange = new EventEmitter<PasswordHash>();
  protected passwordControl = new FormControl('', { validators: [Validators.required] });
  protected formGroup = new FormGroup({ password: this.passwordControl });
  protected isLoading = false;

  constructor(private passwordGenerator: PasswordGeneratorService) { /**/ }

  public ngOnChanges(changes) {
    if (changes.username) {
      this.username = changes.username.currentValue;
    }
  }

  protected requestPassword() {
    this.isLoading = true;
    this.passwordGenerator.requestPassword(this.model).subscribe(result => {
      this.isLoading = false;
      this.passwordChange.emit(result);
    });
  }

  get model(): UsernamePassword {
    return {
      username: this.username,
      password: this.passwordControl.value
    };
  }
}
