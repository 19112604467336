/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PasswordHash, UsernamePassword } from '../../server/shared/models/passwordHash';

const PATH = '/api/passwords';

@Injectable()
export class PasswordGeneratorService {

  constructor(protected http: HttpClient) {
    /**/
  }

  public requestPassword(model: UsernamePassword): Observable<PasswordHash> {
    return this.http.put<PasswordHash>(PATH, model);
  }
}
