/* Copyright © 2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import {Validator, ValidationResponse} from './validator';
import {PlayerInterface} from '../models/player';

export class PlayerValidator extends Validator<PlayerInterface> {
  public validate(id: PlayerInterface['id'], instance: PlayerInterface): ValidationResponse {
    let errors = [];
    if (!id) {
      errors.push('Must supply a team id.');
    }

    if (Number(id) !== instance.id) {
      errors.push(`Player id is invalid. ${id} does not match ${instance.id}`);
    }

    if (!instance) {
      errors.push('Must supply player info.');
    }

    if (!instance.id) {
      errors.push('Must supply a player id.');
    }

    if (!instance.name) {
      errors.push('Must supply a player name.');
    }

    if (!instance.team_id || !Number.isInteger(Number(instance.team_id))) {
      errors.push('Must supply a team id.');
    }

    if (errors.length) {
      return {
        success: false,
        reasons: errors
      };
    }
    return {
      success: true
    };
  }
}
