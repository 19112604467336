/* Copyright © 2017-2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class NotAuthorizedInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
    /**/
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          return this.handleError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private handleError(error: HttpErrorResponse) {
    let queryParams: { error?: string; url?: string } = {};

    if (error.status === 401 || error.status === 403) {
      if (error.url.includes('/login')) {
        if (this.router.url.includes('login')) {
          queryParams.error = 'invalid-credentials';
        } else {
          queryParams.error = 'not-logged-in';
        }
        return throwError(queryParams);
      }

      queryParams.error = 'not-authorized';
      if (error.url) {
        queryParams.url = error.url;
      } else if (this.router.url) {
        queryParams.url = window.location.host + this.router.url;
      }
      this.router.navigate(['/notAuthorized'], { queryParams });
    }

    return throwError(error);
  }
}
