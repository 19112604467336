/* Copyright © 2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings } from './multiselect.types';

@Component({
  selector: 'multi-select',
  templateUrl: 'multiselect/multiselect.component.html',
  styles: [
    require('./multiselect.component.scss'), // tslint:disable-line
  ],
})
export class MultiSelectComponent {
  @ViewChild('checkboxes') protected checkboxes: ElementRef<HTMLDivElement>;
  protected isExpanded = false;
  protected isCheckAll = true;
  protected searchModel = '';
  protected defaultSettings: IMultiSelectSettings = {
    enableSearch: false,
    closeOnClickOutside: true,
    showCheckAll: false,
    autoUnselect: false,
    closeOnSelect: false,
    dynamicTitleMaxItems: 4,
    selectionLimit: 0,
  };
  protected id = Math.random();

  @Input() public settings: IMultiSelectSettings = {};
  @Input() public options: IMultiSelectOption[] = [];
  @Input() public list: any[] = [];

  @Output() public listChange = new EventEmitter();
  @Output() public click = new EventEmitter();
  @HostListener('document:click', ['$event'])
  protected clickout(event) {
    if (
      event?.target &&
      this.multiselectSettings.closeOnClickOutside &&
      !this.checkboxes.nativeElement.parentElement.contains(event.target)
    ) {
      this.closeCheckboxList();
    }
  }

  protected getName(id: any): string {
    return this.options.find((i) => id === i.id)?.name;
  }

  protected getIsChecked(id: any): boolean {
    return this.list?.some((i) => i === id);
  }

  get multiselectSettings(): IMultiSelectSettings {
    return { ...this.defaultSettings, ...this.settings };
  }

  protected showCheckboxes(): void {
    if (this.options?.length) {
      this.isExpanded = !this.isExpanded;
    }
    this.click.emit();
  }

  protected closeCheckboxList(): void {
    this.isExpanded = false;
  }

  protected trackByFn(_: number, item: IMultiSelectOption): string {
    return item.id;
  }

  protected clickCheckAll(): void {
    this.list = [];
    const { checked, classList } =
      this.getElementById<HTMLInputElement>('select-all');
    if (checked) {
      this.list = this.options.map(({ id }) => id);
      classList.add('checked');
    } else {
      classList.remove('checked');
    }
    this.listChange.emit(this.list);
  }

  protected unselectAll(): void {
    this.list = [];
    this.listChange.emit(this.list);
  }

  protected getElementById<E extends Element = Element>(id: string): E {
    return document.querySelector<E>(`#${id}`);
  }

  protected onCheckChange(event: { target: HTMLInputElement }): void {
    const { value: eValue, classList } = event.target;
    const { autoUnselect, selectionLimit, closeOnSelect } = this.multiselectSettings;
    const checked = classList.contains('checked');

    if (!this.list) {
      this.list = [];
    }

    if (!checked) {
      if (
        this.list.length > 0 &&
        this.list.length === selectionLimit &&
        autoUnselect
      ) {
        this.list.pop();
      }
      if (!selectionLimit || this.list.length < selectionLimit) {
        const value = this.options.find((o) => `${o.id}` === eValue ).id;
        this.list.push(value);
        if (closeOnSelect) {
          this.closeCheckboxList();
        }
      }
    } else {
      const index = this.list.findIndex((i) => `${i}` === eValue);
      this.list.splice(index, 1);
    }
    this.listChange.emit(this.list);
  }
}
