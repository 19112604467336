/* Copyright © 2019-2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { TeamsService } from './teams.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'teamsInit',
  templateUrl: 'teams/teamsInit.component.html',
  styles: [
    require('./teamsInit.component.scss')  // tslint:disable-line
  ]
})
export class TeamsInitComponent {
  protected searchLeagues: { id: number, label: string, teamIds?: number[] }[] = [];
  protected leagueIds: number[] = [];
  private teamFilterControl = new FormControl('');

  constructor(private router: Router, private state: ActivatedRoute, private teamsService: TeamsService) { /**/ }

  protected ngOnInit() {
    this.state.params.subscribe((params: Params) => {
      if (params.labels && params.leagueIds) {
        let labels = params.labels.split(',');
        let leagueIds = params.leagueIds.split(',').map(Number);
        let teamIds = params.teamIds ? params.teamIds.split(',').map(Number) : undefined;
        this.leagueIds = leagueIds;
        this.searchLeagues = leagueIds.map((id, idx) => ({ id, label: labels[idx], teamIds }));
        if (params.init) {
          this.search();
        }
      }
    });

    this.teamFilterControl.valueChanges.subscribe(filter => {
      this.teamsService.filterTeams(filter);
    });
  }

  protected leaguesChanged({ leagueIds, labels }) {
    this.router.navigate(['/teams', { leagueIds, labels }]);
  }

  protected clear() {
    this.leagueIds = [];
    this.searchLeagues = [];
    this.teamFilterControl.setValue('');
  }

  protected search() {
    this.teamsService.getTeamsForLeagues(this.searchLeagues, this.teamFilterControl.value);
  }
}
