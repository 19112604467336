/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component } from '@angular/core';
import { ScoringRulesService } from './scoringRules.service';
import { skipWhile } from 'rxjs/operators';

@Component({
  selector: 'scoring-rules',
  templateUrl: 'scoringRules/scoringRules.component.html',
  styles: [
    require('./scoringRules.component.scss')  // tslint:disable-line
  ],
})
export class ScoringRulesComponent {
  protected isDialogOpen = false;
  protected isFetching = false;
  protected rules = [];
  protected msg = '';
  protected ruleToEdit;

  constructor(private scoringRulesService: ScoringRulesService) {
    this.refreshRules();
  }

  protected refreshRules() {
    this.isFetching = true;
    let subscription = this.scoringRulesService.list()
      .pipe(
        skipWhile(rules => !rules)
      )
      .subscribe((rules) => {
        subscription.unsubscribe();
        this.isFetching = false;
        this.rules = Object.keys(rules || {}).map(ruleKey => ({ key: ruleKey, rule: rules[ruleKey] }));
      }, (err) => {
        if (subscription) {
          subscription.unsubscribe();
        }
        this.rules = [];
        this.isFetching = false;
      });
  }

  protected doCloseDialog(newRule: boolean) {
    if (newRule) {
      this.refreshRules();
    }
    this.ruleToEdit = null;
    this.isDialogOpen = !this.isDialogOpen;
  }

  protected deleteRule(key: string) {
    this.isFetching = true;
    this.scoringRulesService.delete(key).subscribe(() => {
      this.refreshRules();
      this.msg = 'Rule has been deleted';
      this.clearMsg();
    }, () => {
      this.isFetching = false;
      this.msg = 'There\'s been an error when deleting the rule. Try again.';
      this.clearMsg();
    });
  }

  protected editRule(rule) {
    this.isDialogOpen = true;
    this.ruleToEdit = { key: rule.key, ...rule.rule };
  }

  protected clearMsg() {
    setTimeout(() => {
      this.msg = '';
    }, 3000);
  }
}
