/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */

import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoginService } from '../login/login.service';

@Injectable()
export class AuthGuard implements CanActivateChild {
  constructor(private router: Router, private loginService: LoginService) {}

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.loginService.isLoggedIn.catch(err => {
      this.router.navigate(['login'], { queryParams: err });
      return false;
    });
  }
}
