/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { BaseRequestorService } from '../baseRequestor.service';

import { ValidationResponse } from '../../server/shared/validation/validator';
import { Lines, AdminLineInterface as LineInterface } from '../../server/shared/models/gsModel';
import { LineValidator } from '../../server/shared/validation/lineValidator';

@Injectable()
export class LinesService extends BaseRequestorService<LineInterface, Lines> {
  private lineValidator = new LineValidator();  // TODO: should be injected

  constructor(protected http: HttpClient) {
    super('lines', http, 10000);
  }

  public validate(id: string, model: LineInterface): ValidationResponse {
    return this.lineValidator.validate(id, model);
  }
}
