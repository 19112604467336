/* Copyright © 2018-2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { dup } from 'gs-utils/lib/utilities';

import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GamePartitionService } from './gamePartition.service';
import { PartnersService, PartnerInterface } from '../services/partners.service';

@Component({
  selector: 'game-partition',
  templateUrl: 'gamePartition/gamePartition.component.html',
  styles: [
    require('./gamePartition.component.scss') // tslint:disable-line
  ],
  providers: []
})
export class GamePartitionComponent implements OnInit {
  protected searching = false;

  private partners: PartnerInterface[] = [];
  private gamePartitionSearch = new FormControl('', [Validators.required]);
  private gamePartitionPartner = new FormControl({ disabled: true }, [Validators.required]);
  private searchForm = new FormGroup(Object.create({}));
  private partitions = [];
  private errorMsg: string;
  private hasError = false;
  private isSearching = false;

  constructor(
    private gamePartitionService: GamePartitionService,
    private partnerService: PartnersService,
    private router: Router
  ) {
    this.searchForm.addControl('partition', this.gamePartitionSearch);
    this.searchForm.addControl('partner', this.gamePartitionPartner);
  }

  public ngOnInit(): void {
    this.partnerService.list().subscribe(
      partners => {
        this.gamePartitionSearch.enable();
        this.partners = this.partnerService.parsePartners(partners);
      },
      e => {
        this.hasError = true;
        this.errorMsg = e.message;
      }
    );
  }

  protected delete(partition: any) {
    this.hasError = false;
    this.isSearching = true;
    this.gamePartitionService.delete(partition).subscribe(
      result => {
        this.isSearching = false;
        if (result) {
          this.partitions = this.gamePartitionService.extractPartitions(result);
        } else {
          this.partitions = [];
        }
      },
      e => {
        this.hasError = true;
        this.errorMsg = e.message;
      }
    );
  }

  protected search() {
    this.hasError = false;
    this.isSearching = true;
    this.gamePartitionService.getPartition(this.searchForm.getRawValue()).subscribe(
      result => {
        this.isSearching = false;
        if (result) {
          this.partitions = this.gamePartitionService.extractPartitions(result);
        } else {
          this.partitions = [];
        }
      },
      err => {
        this.hasError = true;
        this.partitions = [];
        this.isSearching = false;
        this.errorMsg = err.message;
      }
    );
  }

  protected clear() {
    this.gamePartitionSearch.setValue('');
    this.partitions = [];
  }
}
