/* Copyright © 2017-2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

import { CountryValidator } from '../../server/shared/validation/countryValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';
import { FilterListPipe } from '../filters/filterListPipe.filter';
import { AdminCountryInterface } from '../../server/shared/models/gsModel';

import { CountriesService } from './countries.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import { Clipboard } from '@angular/cdk/clipboard';
import { LicenseManager } from 'ag-grid-enterprise';
import agGridLicense from '../agGridLicense';

interface UICountry {
  id: number;
  name: string;
  code: string;
  platformOrder: number;
  mappings: string[];
  raw: string;
}

@Component({
  selector: 'countries',
  templateUrl: 'countries/countries.component.html',
  styles: [
    require('./countries.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe]
})

export class CountriesComponent implements OnInit {
  protected countries: UICountry[] = [];
  protected searching = false;

  private countryForm: FormGroup;
  private validator = new CountryValidator();
  private formMessages: Record<string, string> = {};
  private hasError: Record<string, boolean> = {};
  @ViewChild(AgGridAngular) protected agGrid: AgGridAngular;
  protected columnDefs: ColDef[] = [
    ['id', 'Country ID'],
    ['name', 'Name'],
    ['code', 'Code'],
    ['platformOrder', 'Platform order'],
    ['raw', 'Raw data']
  ].map(([field, headerName], i) => ({
    field,
    headerName,
    flex: 1,
    ...(!i && { cellRenderer: 'agGroupCellRenderer'}),
    ...(field !== 'id' && ({
      editable: true,
      cellEditor: 'agTextCellEditor'
    })),
    ...(field === 'raw' && ({
      cellRenderer: ({ data }) => {
        const pre = document.createElement('pre');
        pre.classList.add('raw-data');
        pre.textContent = data.raw;
        return pre;
      },
      flex: 3,
      editable: false,
    })),
  }) as ColDef)
  .concat({
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    resizable: false,
    sortable: false,
    filter: false,
    cellRenderer: ({ data }) => {
      const span = document.createElement('span');
      const [saveBtn, revertBtn] = [
        ['Save', 'btn-primary'],
        ['Revert', 'btn-default']
      ].map(([name, css]) => {
        const btn = document.createElement('button');
        btn.textContent = name;
        btn.classList.add('btn', css);
        return btn;
      });
      const { id, raw } = data;
      saveBtn.onclick = () => this.saveCountry( id, raw );
      revertBtn.onclick = () => this.revertChanges(id);
      span.appendChild(saveBtn);
      span.appendChild(revertBtn);
      return span;
    }
  });
  protected defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    autoHeight: true
  };
  protected detailCellRendererParams = {
    detailGridOptions : {
      columnDefs: [{
        field: 'mappings',
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: { maxLength: 2000 },
        minWidth: 550,
        flex: 1,
      }, {
        field: 'mappingsAction',
        headerName: 'Actions',
        cellRenderer: ({ data }) => {
          const span = document.createElement('span');
          const copyButton = document.createElement('button');
          copyButton.textContent = 'Copy';
          copyButton.classList.add('btn', 'btn-warning');
          const { id } = data;
          const mappingsControl = this.getFormControl(id, 'mappings');
          copyButton.onclick = () => this.clipBoard.copy(mappingsControl.value);
          span.appendChild(copyButton);
          return span;
        }
      }
    ],
      onCellValueChanged: this.onCellValueChanged.bind(this),
      enableCellTextSelection: true,
      ensureDomOrder: true,
      stopEditingWhenCellsLoseFocus: true
    },
    getDetailRowData: ({ successCallback, data }) => {
      successCallback([data]);
    },
  };
  protected getRowNodeId (data: UICountry): number {
    return data.id;
  }

  constructor(private fb: FormBuilder, private countriesService: CountriesService,
    private router: Router, private state: ActivatedRoute, private clipBoard: Clipboard) { /**/ }

  public ngOnInit(): void {
    this.buildForm([]);
    this.state.params.subscribe((params: Params) => {
      let field = this.countryForm.get('countries-search');
      if (field.value !== params.search) {
        field.setValue(params.search);
        this.searchForCountries(params.search);
      }
    });
    LicenseManager.setLicenseKey(agGridLicense);
  }

  protected createCountry(name: string) {
    this.searching = true;
    this.countries = [];
    this.countriesService.create({ name }).subscribe(country => {
      this.countries.push({
        id: Number(country.id),
        name: country.name,
        code: country.code,
        platformOrder: country.platformOrder,
        mappings: country.mappings || [],
        raw: JSON.stringify(country, null, 2)
      });
      this.buildForm(this.countries);
      this.searching = false;
    }, (e) => {
      this.hasError = { onCreation: true };
      this.searching = false;
      setTimeout(() => this.hasError = {}, 3000);
    });
  }

  public searchForCountries(search: string) {
    this.searching = true;
    this.countries = [];
    this.updateUrl(search);
    this.countriesService.list({ search, includeMappings: true }).subscribe(countries => {
      Object.keys(countries).forEach(countryId => {
        this.countries.push({
          id: Number(countryId),
          name: countries[countryId].name,
          code: countries[countryId].code,
          platformOrder: countries[countryId].platformOrder,
          mappings: countries[countryId].mappings || [],
          raw: JSON.stringify(countries[countryId], null, 2)
        });
      });
      this.countries = [...this.sort(this.countries)];
      this.buildForm(this.countries);
      this.searching = false;
    });
  }

  protected saveCountry(countryId: number, rawCountry: string) {
    let countryObject: AdminCountryInterface = JSON.parse(rawCountry);
    const { name, code, platformOrder, mappings } = this.extractValues(countryId);
    countryObject.name = name;
    countryObject.code = code || countryObject.code;
    countryObject.platformOrder = platformOrder;
    countryObject.originalMappings = countryObject.mappings;
    countryObject.mappings = mappings.split('\n');
    let validation = this.validate(countryId, countryObject);
    if (validation.success) {
      this.countriesService.save(countryId, countryObject).subscribe((newCountry: AdminCountryInterface) => {
        let oldCountry = this.countries.find(country => Number(country.id) === Number(newCountry.id));
        if (oldCountry) {
          oldCountry.name = newCountry.name;
          oldCountry.code = newCountry.code;
          oldCountry.platformOrder = newCountry.platformOrder;
          oldCountry.mappings = newCountry.mappings;
          oldCountry.raw = JSON.stringify(newCountry, null, 2);
        }
        this.buildForm(this.countries);
        this.addMessage(countryId, 'Country saved');
      });
    } else {
      this.addMessage(countryId, validation.reasons.join(',\n'), true);
    }
  }

  protected onCellValueChanged(params: CellValueChangedEvent): void {
    const key = params.column.getId();
    const { id } = params.data;
    const data = params.data[key];
    const control = this.getFormControl(id, key);
    if (key === 'mappings') {
      control.setValue(data.replace(/,/g, '\n'));
    } else if (key === 'platformOrder') {
      control.setValue(+data || undefined);
    } else {
      control.setValue(data);
    }
  }

  protected getFormControl( countryId: number, key: string ): AbstractControl {
    return this.countryForm.get(`${countryId}-${key}`);
  }

  protected extractValues(countryId: number) {
    return [ 'name', 'platformOrder', 'code', 'mappings' ]
      .reduce((prev, key) => (prev[key] = this.getFormControl( countryId, key ).value, prev), Object.create(null));
  }

  protected validate(countryId: number, country: AdminCountryInterface): ValidationResponse {
    return this.validator.validate(countryId, country);
  }

  protected buildForm(countries: UICountry[]): void {
    let originalSearch = this.countryForm ? this.countryForm.get('countries-search').value : '';
    this.countries = [...countries];
    let fields: any = { };
    this.countries.forEach(country => {
      fields[`${country.id}-name`] = [ country.name, [Validators.required] ];
      fields[`${country.id}-code`] = [ country.code, [] ];
      fields[`${country.id}-platformOrder`] = [ country.platformOrder, [] ];
      fields[`${country.id}-mappings`] = [ (country.mappings || []).join('\n'), [Validators.required] ];
    });

    fields['countries-search'] = [originalSearch, []];
    fields['countries-create'] = ['', []];
    this.countryForm = this.fb.group(fields);
  }

  protected revertChanges(countryId) {
    const { raw } = this.countries.find(u => (u.id === countryId));
    const country = {...JSON.parse(raw), raw };
    const nameControl = this.countryForm.get(`${countryId}-name`);
    const codeControl = this.countryForm.get(`${countryId}-code`);
    const orderControl = this.countryForm.get(`${countryId}-platformOrder`);
    const mappingsControl = this.countryForm.get(`${countryId}-mappings`);
    nameControl.setValue(country.name);
    codeControl.setValue(country.code);
    orderControl.setValue(country.platformOrder);
    mappingsControl.setValue((country.mappings || []).join('\n'));
    this.agGrid.api.applyTransaction({ update: [country] });
    this.addMessage(countryId, 'Changes undone');
  }

  private addMessage(countryId: number, message = '', isError = false) {
    this.formMessages[countryId] = message;
    this.hasError[countryId] = isError;
    setTimeout(() => {
      delete this.formMessages[countryId];
      this.hasError[countryId] = false;
    }, 2000);
  }

  private sort(countries: UICountry[]): UICountry[] {
    return countries.sort((l1, l2) => l1.id - l2.id);
  }

  private updateUrl(search: string) {
    this.router.navigate(['/countries', search ? { search } : {}]);
  }
}
