/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { HttpClient } from '@angular/common/http';
import { Params } from '@angular/router';
import { isObject } from 'gs-utils/lib/utilities';

import { GsModels } from '../server/shared/models/gsModel';
import { ValidationResponse } from '../server/shared/validation/validator';
import { Subscription, Observable, of, Subject } from 'rxjs';

export class BaseRequestorService<T, C extends GsModels<T>> {

  public source: string;
  private requestCache: Map<string, { timestamp: number, results: T | C }> = new Map();
  private requestSubject: Record<string, Subject<C | T>> = {};
  private requestSubscriptions: Record<string, Subscription> = {};

  constructor(protected key: string, protected http: HttpClient, private cache = 0) {
    /**/
  }

  public listWithSource(source: string): Observable<C> {
    let requestKey = `/api/${this.key}/${source}`;
    const cachedResponse = this.retrieveFromCache(requestKey);
    return cachedResponse ? of(cachedResponse) : this.doKeyRequest(requestKey);
  }

  public createWithSource(source: string, body: Object): Observable<T> {
    let requestKey = `/api/${this.key}/${source}`;
    return this.http.post<T>(requestKey, body, { withCredentials: true });
  }

  public list(query?: Params): Observable<C> {
    let key = this.getKey();
    let requestKey = `/api/${key}${this.generateQueryString(query)}`;
    const cachedResponse = this.retrieveFromCache(requestKey);
    return cachedResponse ? of(cachedResponse) : this.doKeyRequest(requestKey);
  }

  public fetch(id: string): Observable<T> {
    let key = this.getKey();
    let requestKey = `/api/${key}/${id}`;
    const cachedResponse = this.retrieveFromCache(requestKey);
    return cachedResponse ? of(cachedResponse) : this.doKeyRequest(requestKey);
  }

  public save(id: string | number, model: T, query?: { [key: string]: string }): Observable<T> {
    let key = this.getKey();
    let value = isObject(model) ? model : JSON.stringify(model);
    return this.http.put<T>(`/api/${key}/${id}${this.generateQueryString(query)}`, value, { withCredentials: true });
  }

  public batchSave(models: T[], query?: { [key: string]: string }): Observable<T[]> {
    let key = this.getKey();
    let value = models.filter(m => m).map(m => isObject(m) ? m : JSON.stringify(m));
    return this.http.put<T[]>(`/api/${key}/${this.generateQueryString(query)}`, value, { withCredentials: true });
  }

  public delete(id: string | number, query?: { [key: string]: string }): Observable<boolean> {
    let key = this.getKey();
    return this.http.delete<boolean>(`/api/${key}/${id}${this.generateQueryString(query)}`, { withCredentials: true });
  }

  public batchDelete(ids: string[] | number[], query?: { [key: string]: string }): Observable<boolean> {
    let key = this.getKey();
    return this.http.post<boolean>(`/api/${key}-delete${this.generateQueryString(query)}`, ids, { withCredentials: true });
  }

  public create(body?: Object): Observable<T> {
    return this.http.post<T>(`/api/${this.getKey()}`, body, { withCredentials: true });
  }

  public validate(id: string | number, model: any): ValidationResponse {
    return { success: true };
  }

  public doKeyRequest(requestKey): any {
    this.clearCacheKey(requestKey);
    this.requestSubject[requestKey] = new Subject();
    const subscription = this.http.get<C>(requestKey, { withCredentials: true }).subscribe(data => {
      this.setRequestCache(requestKey, data);
      this.requestSubject[requestKey].next(data);
      if (this.requestSubscriptions[requestKey]) {
        subscription.unsubscribe();
        delete this.requestSubscriptions[requestKey];
      }
    });
    return this.requestSubject[requestKey].asObservable();
  }

  private retrieveFromCache(requestKey) {
    const cachedData = this.requestCache.get(requestKey);
    const isValidData = cachedData && cachedData.timestamp > (Date.now() - this.cache);
    if (isValidData) {
      return cachedData.results;
    }
  }

  private generateQueryString(query: { [key: string]: string }) {
    if (!query) {
      return '';
    }
    return '?' + Object.keys(query).map((key) => `${key}=${query[key]}`).join('&');
  }

  private clearCacheKey(key: string) {
    this.requestCache.delete(key);
  }

  private setRequestCache(key: string, val: T | C): void {
    this.requestCache.set(key, { timestamp: Date.now(), results: val});
  }

  private getKey(): string {
    if (this.source) {
      return `${this.key}/${this.source}`;
    }
    return this.key;
  }
}
