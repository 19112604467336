/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component } from '@angular/core';

@Component({
  selector: 'wagertypes-header',
  templateUrl: 'wagertypes/header.component.html',
  styles: [
    require('./header.component.scss'), // tslint:disable-line
  ],
  providers: [],
})
export class WagertypesHeaderComponent {
  protected mode = '';
  protected modes = ['wagertypes', 'display-styles', 'statistics', 'market-categories', 'limit-groups'];

  protected capitalize(str: string): string {
    return str
      .split('-')
      .map((s) => s.toLowerCase().replace(/^[a-z]/, (s[0] || '').toUpperCase()))
      .join(' ');
  }
}
