/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import {
  HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';

export class JsonContentTypeInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    req.headers.append('Content-Type', 'application/json');
    return next.handle(req);
  }
}
