/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { ScoreInterface } from 'gs-templates/lib/score';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Scores } from '../../server/shared/models/gsModel';
import { BaseRequestorService } from '../baseRequestor.service';
import { ScoreValidator } from '../../server/shared/validation/scoreValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';

const SCORES_PATH = '/api/scores';

@Injectable()
export class ScoresService extends BaseRequestorService<ScoreInterface, Scores> {
  private scoreValidator = new ScoreValidator();  // TODO: should be injected

  constructor(protected http: HttpClient) {
    super('scores', http);
  }

  public validate(id: string, model: ScoreInterface): ValidationResponse {
    return this.scoreValidator.validate(id, model);
  }
}
