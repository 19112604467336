/* Copyright © 2017-2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Subject, of } from 'rxjs';
import { map, catchError, concatMap } from 'rxjs/operators';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, ValidatorFn, AbstractControl } from '@angular/forms';

import { dup, flattenArray, isObject } from 'gs-utils/lib/utilities';
import { defer as simpleDefer } from 'gs-utils/lib/simpleDefer';
import { WagertypeInterface } from 'gs-templates/lib/wagertype';

import { TeamDesignator } from '../../server/shared/models/teamDesignator';
import { AdminLeagueInterface, AdminCountryInterface, AdminEventInterface, Events } from '../../server/shared/models/gsModel';

import { DateService } from '../filters/date.service';

import { TeamDesignatorService } from '../teams/teamDesignator.service';

import { LeaguesService } from '../leagues/leagues.service';

import { WagertypesService } from '../wagertypes/wagertypes.service';

import { CountriesService } from '../countries/countries.service';

import { DialogService } from '../dialog/dialog.service';

import { ServerEnvironmentService } from '../serverEnvironment.service';

import { PlatformService } from '../platform.service';

import { CorrelationsService } from '../correlations/correlations.service';
import { LeagueCorrelationsService } from '../correlations/leagueCorrelations.service';

import { EventsService } from './events.service';
import { AddGanchrowRotationService } from './addGanchrowRotation.service';
import { ExpireFromPlatformService } from './expireFromPlatform.service';
import { ReverseGameTimeService } from './reverseGameTime.service';
import { EventSourcesService } from './eventSources.service';
import { MainLineSourcesService } from './mainLineSources.service';
import { LineSetsService } from './lineSet.service';
import { AddEventComponent } from './addEvent.component';
import { RequestEventComponent } from './requestEvent.component';
import { RequestLeagueComponent } from './requestLeague.component';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridReadyEvent, RowDataChangedEvent, RowHeightParams } from 'ag-grid-community';
import { formatDate } from '@angular/common';
import { SystemStatusesService } from '../statuses/systemStatus.service';
import { LicenseManager } from 'ag-grid-enterprise';
import agGridLicense from '../agGridLicense';
import { IMultiSelectSettings } from '../multiselect/multiselect.types';
import { MatDrawer } from '@angular/material/sidenav';
import { SnackBarService } from '../services/snackbar.service';

type EventSearchType = 'by-rotation' | 'by-league' | 'by-status' | 'by-sport';
type OperationOnRotationFn = (event: AdminEventInterface) => void;
type DisplayOption = 'pane' | 'panel';

interface Query {
  hasLines?: string;
  league_ids?: number[];
  pattern?: string;
  statuses?: number[];
  sport_ids?: number[];
}

@Component({
  selector: 'events',
  templateUrl: 'events/events.component.html',
  styles: [
    require('./events.component.scss')  // tslint:disable-line
  ],
  providers: [
    MainLineSourcesService, AddGanchrowRotationService, ExpireFromPlatformService,
    ServerEnvironmentService, ReverseGameTimeService,
    PlatformService, CorrelationsService, LineSetsService, WagertypesService,
    LeagueCorrelationsService
  ],
})
export class EventsComponent implements OnInit  {
  private activeWagertypes = {};
  private activeSettlements: Record<number, { period: string; wagertype: string; figure: string }[]> = {};
  private sourceOverrideChange = [];
  private parentRotationChange = [];
  private message: Record<string, string> = {};
  private isError: Record<string, boolean> = {};
  private rotationsPattern: string;
  private selectedLeagueIds: number[] = [];
  private selectedSportIds: number[] = [];
  private selectedStatuses: number[] = [];
  private events: Events;
  private visibleScores: Record<string, boolean> = {};
  private visibleSettlements: Record<string, boolean> = {};
  private lineSources: [string, string][] = [];
  private eventSources: [string, string][] = [];
  private logicalFeedSources: { source: string; alias: string }[] = [];
  private lineSourceIndexControl: FormControl = new FormControl();
  private lineSourceIndex: number;
  private selectedRotations: Set<number> = new Set();
  private isShowingSelected = false;
  private dateChange = [];
  private platformUrl = '';
  private gsDescriptionChange = [];
  private channelChange = [];
  private pitchersChange = [];
  private rotationsToDisplay = [];
  private newStatus = null;
  private feedSourceOneControl = new FormControl();
  private feedSourceTwoControl = new FormControl();
  private feedSourceEventControl = new FormControl();
  private eventDeclarationControl = new FormControl();
  private newLeague: number;
  private timeToAddSubtractControl = new FormControl(null, this.isValidTimeValidator());
  private freezeOrUnfreezeControl = new FormControl();
  private resetStateSignal: Subject<boolean> = new Subject();
  private baseballSportId: number;
  private wagertypes: Record<string, WagertypeInterface> = {};
  private viewOption: DisplayOption = 'pane';
  private rotationsForFeedSourceTwo = [];
  private rotationsForFeedSourceOne = [];
  private readyToFetch = simpleDefer();
  private feedSourceQuery: { source: string; alias: string };

  protected selectedActiveWagertypes: number[] = [];
  protected showWagertypesDialog = false;
  protected selectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 1,
    showCheckAll: true,
  };
  protected eventSearchTypes = [
    { label: 'Search by rotation', value: 'by-rotation', default: true },
    { label: 'Search by sport', value: 'by-sport', default: false },
    { label: 'Search by league', value: 'by-league', default: false },
    { label: 'Search by status', value: 'by-status', default: false },
  ];
  protected fetchingResults = false;
  protected eventSearchType: EventSearchType = 'by-rotation';
  protected manageLineMode = false;
  protected manageCorrelationMode = false;
  protected manageLeagueCorrelationMode = false;
  protected showEventChanges = false;
  protected activeRotation: number;
  protected platformView: number;
  protected lineSets: { id: string, name: string }[] = [];
  protected selectedLineSets: string[] = [];
  protected eventFeedSourcesWithAll: { source: string; alias: string }[] = [];

  protected agGridOne: AgGridAngular;
  protected agGridTwo: AgGridAngular;
  @ViewChild('drawer') protected drawer: MatDrawer;
  @HostListener('document:keydown.escape', ['$event'])
  protected onKeydownHandler() {
    this.closeMenu();
  }
  @HostListener('document:click', ['$event'])
  protected clickout(event) {
    const eventContainer = document.querySelector('.events');
    if (
      eventContainer.contains((event.target)) &&
      !event.target.classList.contains('fa-edit') &&
      !event.target.classList.contains('mat-mdc-button-touch-target') &&
      !this.drawer._content.nativeElement.contains(event.target)) {
        this.closeMenu();
      }
  }
  protected canAddToPlatform = false;
  protected columnDefs: ColDef[] = [
    {
      field: 'rotation',
      headerName: 'Rotation',
      cellRenderer: 'agGroupCellRenderer',
      filter: 'agSetColumnFilter',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      valueGetter: ({ data }) => data
    },
    {
      field: 'teams',
      headerName: 'Teams',
      valueGetter: ({ data }) => {
        const teams = this.events[data].teams
        .reduce((prev, team, i) => !i
          ? this.getTeamAndPitcher(team.name, data, i)
          : `${prev} / ${this.getTeamAndPitcher(team.name, data, i)}` , '');
        return this.events[data].tag ? `${teams}. ${this.events[data]?.tag}` : teams;
        }
    },
    {
      field: 'start',
      headerName: 'Start',
      valueGetter: ({ data }) => formatDate(this.events[data].start_date, 'medium', 'en-US')
    },
    {
      field: 'status',
      headerName: 'Status',
      valueGetter: ({ data }) => (this.events[data] as any).status_display
    },
    {
      field: 'leagueId',
      headerName: 'League ID',
      width: 150,
      valueGetter: ({ data }) => this.events[data].league_id
    },
    {
      field: 'sportId',
      width: 150,
      headerName: 'Sport ID',
      valueGetter: ({ data }) => this.events[data].sport_id
    },
    {
      field: 'countryId',
      width: 150,
      headerName: 'Country ID',
      valueGetter: ({ data }) => this.events[data].country_id
    },
    {
      field: 'ttl',
      headerName: 'TTL',
      width: 150,
      valueGetter: ({ data }) => this.events[data].ttl
    },
    {
      field: 'feedSource',
      headerName: 'Feed Source',
      valueGetter: ({ data }) => this.events[data].source_display
    },
    {
      field: 'actions',
      headerName: 'Actions',
      resizable: false,
      sortable: false,
      filter: false,
      cellRenderer: ({ data }) => {
        const span = document.createElement('span');
        span.classList.add('event-action-icon');
        span.innerHTML = '<i class="fas fa-edit"></i>';
        span.onclick = () => {
          this.activeRotation = data;
          this.drawer.open();
        };
        return span;
      }
    }
  ];
  protected context = { controller: this };
  protected defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  public eventsMessage;

  constructor(private eventsService: EventsService,
    private dateService: DateService, private router: Router, private state: ActivatedRoute,
    private mainLineSourcesService: MainLineSourcesService, private systemStatusService: SystemStatusesService,
    private teamDesignatorService: TeamDesignatorService, private leaguesService: LeaguesService,
    private countriesService: CountriesService, private dialogService: DialogService,
    private addGanchrowRotationService: AddGanchrowRotationService,
    private expireFromPlatformService: ExpireFromPlatformService,
    private reverseGameTimeService: ReverseGameTimeService,
    private serverEnvironmentService: ServerEnvironmentService,
    private eventSourcesService: EventSourcesService, private clipboard: Clipboard,
    private platformService: PlatformService, private correlationsService: CorrelationsService,
    private lineSetsService: LineSetsService, private wagertypesService: WagertypesService,
    private leagueCorrelationsService: LeagueCorrelationsService, private snackbarService: SnackBarService
  ) {
  }

  public ngOnInit() {
    this.ensureEnvironment();
    this.eventSourcesService.list().subscribe(e => {
      this.eventSources = e;
      this.eventFeedSourcesWithAll = [ { source: 'all', alias: 'all' }].concat(this.eventSources.map(i => ({ source: i[1], alias: i[0] })));
    });
    this.mainLineSourcesService.list().subscribe(l => this.lineSources = l);
    this.lineSetsService.list().subscribe(line => this.lineSets = line.map((l) => ({ id: l, name: l })));
    this.wagertypesService.list().subscribe((wagertypes: any) => {
      if (Array.isArray(wagertypes)) {
        wagertypes.forEach(wt => {
          this.wagertypes[wt.id] = wt;
        });
      } else {
        this.wagertypes = wagertypes;
      }
      this.readyToFetch.resolve();
    });
    this.state.params.subscribe((params: Params) => {
      let newPattern = (params as any).pattern || '';
      let newLeagueIds = extractFields(params);
      let newSportIds = extractFields(params, 'sport_ids');
      let newStatuses = extractFields(params, 'statuses');
      let newEventSearchType = (params as any).eventSearchType || 'by-rotation';
      let hasLines = extractFields(params, 'has_lines');

      if (this.shouldFetch(newEventSearchType, newPattern, newLeagueIds, newStatuses, newSportIds)) {
        this.fetch(newEventSearchType, newPattern, newLeagueIds, newStatuses, newSportIds, hasLines);
        this.updateUrl(newEventSearchType, newPattern, newLeagueIds, newStatuses, newSportIds, hasLines);
      }

      this.rotationsPattern = newPattern;
      this.selectedLeagueIds = newLeagueIds;
      this.selectedStatuses = newStatuses;
      this.eventSearchType = newEventSearchType;
    });

    this.feedSourceTwoControl.valueChanges.subscribe(feedSource => {
      if (this.events) {
        this.rotationsForFeedSourceTwo = this.filterRotations(Object.keys(this.events), feedSource);
      } else {
        this.rotationsForFeedSourceTwo = [];
      }
    });

    this.feedSourceOneControl.valueChanges.subscribe(feedSource => {
      if (this.events) {
        this.rotationsForFeedSourceOne = this.filterRotations(Object.keys(this.events), feedSource);
      } else {
        this.rotationsForFeedSourceOne = [];
      }
    });
    this.feedSourceEventControl.valueChanges.subscribe(feedSource => {
      this.feedSourceQuery = !feedSource || ( feedSource.source === 'all' ) ? null : feedSource;
    });

    LicenseManager.setLicenseKey(agGridLicense);
  }

  protected hasCodeLikeQuery() {
    return this.rotationsPattern &&
      this.eventSearchType === 'by-rotation' && /[a-z]/i.test(this.rotationsPattern)
    ;
  }

  protected onRowDataChanged(params: RowDataChangedEvent) {
    const keys = Object.keys(this.events ?? {});
    if (this.selectedRotations.size === 0 && keys.length === 1) {
      const rowNode = this.agGridOne.api.getRowNode(keys[0]);
      rowNode.setSelected(true);
    }
    if (this.selectedRotations.size) {
      this.selectedRotationsArray.forEach((rotation) => {
        const rowNode = params.api.getRowNode(`${rotation}`);
        if (rowNode) {
          this.selectedRotations.delete(rotation);
          rowNode.setSelected(true);
        }
      });
    }
  }

  protected onGridReady = (event: GridReadyEvent, id: string) => {
   this[id] = event;
}

  protected getRowHeight ({ node, data, context }: RowHeightParams ) {
    const { platformView } = context.controller;
    return node.detail && platformView === data ? 2000 : undefined;
  }

  protected getRowNodeId (data: number): number {
    return data;
  }

  protected detailCellRendererParams = ({ data: rotation }) => {
    const miscProps = this.getMiscProps(rotation);
    return {
      detailGridOptions: {
        columnDefs: miscProps.map(m => ({
          field: m,
          flex: 1,
          resizable: true,
          cellRenderer: ({ data }) => {
            const span = document.createElement('span');
            span.style.width = '100%';
            span.style.height = 'inherit';
            span.innerHTML = data[m];
            return span;
          },
          colSpan: ({ data }) => data.colSpan ?? 1,
          cellStyle: ({ data }) => data.style ?? {}
        })),
        enableCellTextSelection: true,
        ensureDomOrder: true,
      },
      getDetailRowData: ({ successCallback, data }) => {
        const pKey = miscProps[0];
        const rowData = [
          miscProps.reduce((prev, m) => (prev[m] = this.ensureString(this.events[data].misc[m], m), prev), {})
        ];
        if (this.platformView === data) {
          rowData.push({
            [pKey]: `<iframe style="width:100%; height:inherit" src=${this.platformSource(data)} class="events-platform-iframe"></iframe>`,
            colSpan: miscProps.length,
            style: { height: '1800px' }
          });
        }
        successCallback(rowData);
      },
      refreshStrategy: 'rows',
    };
  }

  protected onRowSelected( e: CellClickedEvent): void {
    if (e) {
      if (this.selectedRotations.has(e.data)) {
        this.selectedRotations.delete(e.data);
      } else {
        this.selectedRotations.add(e.data);
      }
    }
  }

  protected closeMenu(): void {
    this.drawer.close();
    this.activeRotation = null;
  }

  protected saveCorrelationFromPlatformService(rotation: number) {
    let correlations = dup(this.sameGameParlayOptions);
    correlations.forEach(c => {
      c.value = -1;
      c.timestamp = Date.now();
    });
    correlations.forEach(c => {
      this.correlationsService.create(c).subscribe(newCorrelations => {
        this.addMessage(rotation, 'Saved successfully', false);
      }, err => {
        this.addMessage(rotation,  err.message || (isObject(err) ? JSON.stringify(err) : err), true);
      });
    });
  }

  protected saveLeagueCorrelationFromPlatformService(rotation: number) {
    let correlations = dup(this.sameGameParlayOptions);
    correlations.forEach(c => {
      c.value = -1;
      c.timestamp = Date.now();
    });
    correlations.forEach(c => {
      this.leagueCorrelationsService.create(c).subscribe(newCorrelations => {
        this.addMessage(rotation, 'Saved successfully', false);
      }, err => {
        this.addMessage(rotation,  err.message || (isObject(err) ? JSON.stringify(err) : err), true);
      });
    });
  }

  protected get sameGameParlayOptions() {
    return this.platformService.sameGameParlayOptions;
  }

  protected copy() {
    this.clipboard.copy(Array.from(this.selectedRotations).join(','));
  }

  protected deselectRotation(rotation: number): void {
    ['agGridOne', 'agGridTwo'].forEach((id) => {
      if (this[id] && !this[id].api.destroyCalled) {
        const rowNode = this[id].api.getRowNode(`${rotation}`);
        rowNode?.setSelected(false);
      }
    });
  }

  protected getTeamAndPitcher(name: string, rotation: number, idx: number): string {
    let event = this.events[rotation];
    if (event && event.pitchers && event.pitchers[idx]) {
      return `${name} (${(event.pitchers[idx] as any).name})`;
    }
    return name;
  }

  protected isRotationSelected(rotation) {
    return !!this.selectedRotations.has(rotation);
  }

  protected doFilterForSelected() {
    this.isShowingSelected = !this.isShowingSelected;
    const values = this.isShowingSelected ? this.selectedRotationsArray : null;
    ['agGridOne', 'agGridTwo'].forEach((id) => {
      if (this[id] && !this[id].api.destroyCalled) {
        this[id].api.setFilterModel({ rotation: { values, type: 'set' }});
      }
    });
  }

  protected togglePlatformView(rotation) {
    if (this.platformView === rotation) {
      this.platformView = null;
      this.platformService.clear();
    } else {
      this.platformView = rotation;
      setTimeout(() => this.platformService.initialize(document.querySelector('iframe.events-platform-iframe')), 500);
    }
    ['agGridOne', 'agGridTwo'].forEach((id) => {
      if (this[id] && !this[id].api.destroyCalled) {
        this[id].api.applyTransaction({ update: [rotation] });
        this[id].api.resetRowHeights();
        const rowNode = this[id].api.getRowNode(`${rotation}`);
        rowNode?.setExpanded(true);
      }
    });
  }

  protected hasLineSources(): boolean {
    return !!this.lineSources.length;
  }

  protected hasLineSets(): boolean {
    return !!this.lineSets.length;
  }

  protected showEventChangesDialog() {
    this.showEventChanges = !this.showEventChanges;
  }

  protected clear() {
    delete this.rotationsPattern;
    delete this.events;
    this.selectedRotations.clear();
    this.rotationsToDisplay = [];
    this.selectedLeagueIds = [];
    this.selectedStatuses = [];
    this.eventSearchType = 'by-rotation';
    this.logicalFeedSources = [];
  }

  protected reverseGameTime() {
    if (this.selectedRotations.size === 0) {
      alert('Must select rotation');
      return;
    }
    let rotations = Array.from(this.selectedRotations);
    this.reverseGameTimeService.toggle(rotations).subscribe(res => {
      if (Array.isArray(res) && res.length) {
        res.forEach(rot => {
          this.addMessage(rot, `${rot} game time reversed`, false);
        });
      }
    }, err => {
      alert(err.message || err);
    });
  }

  protected expireFromPlatform(force = true) {
    let selectedRotations: Set<number>;
    if (force) {
      selectedRotations = new Set(this.rotationsPattern.split(/[ ,]/).map(Number).filter(b => !!b));
    } else {
      selectedRotations = this.selectedRotations;
    }
    if (selectedRotations.size === 0) {
      alert('Must select rotation');
      return;
    }
    let rotations = Array.from(selectedRotations);
    this.expireFromPlatformService.remove(rotations).subscribe(res => {
      rotations.forEach(rotation => {
        this.addMessage(rotation, `${rotation} removed`, false);
      });
    }, err => {
      alert(err.message || err);
    });
  }

  protected addGanchrowRotation() {
    if (this.selectedRotations.size === 0) {
      alert('Must select rotation');
      return;
    }
    let rotations = Array.from(this.selectedRotations);
    this.addGanchrowRotationService.add(rotations).subscribe(res => {
      if (Array.isArray(res) && res.length) {
        res.forEach((gr, i) => {
          let rotation = rotations[i];
          if (gr > 0) {
            this.addMessage(rotation, `${rotation} => ${gr}`, false);
          } else {
            this.addMessage(rotation, `${rotation} not processed`, true);
          }
        });
      } else {
        alert('No events added to platform');
      }
    }, err => {
      alert(err.message || err);
    });
  }

  protected platformSource(rotation: number): SafeResourceUrl {
    let platformRotation = (this.events[rotation].misc as any).source_rotations?.ganchrow ||
      (this.events[rotation].misc as any).source_rotations?.lsports ||
      rotation
    ;
    return `${this.platformUrl}/event/${platformRotation}`;
  }

  protected isBaseball(rotation): boolean {
    return this.events[rotation] && (this.events[rotation].sport_id === this.baseballSportId);
  }

  protected doChangePitchers(rotation) {
    this.pitchersChange = [ rotation ];
  }

  protected doParentRotationChange($event, rotation) {
    this.parentRotationChange = [ rotation, $event.target.value ];
  }

  protected doSourceOverrideChange($event, rotation) {
    this.sourceOverrideChange = [ rotation, $event.target.value ];
  }

  protected doSaveSourceOverride() {
    let [ rotation, source ] = this.sourceOverrideChange;
    if (rotation && this.events[rotation] && (source !== this.events[rotation].canonical_source_override)) {
      this.eventsService.save(rotation, { rotation, overrideCanonicalSource: source } as any).subscribe((t: any) => {
        this.events[rotation].canonical_source_override = t.canonical_source_override;
        this.addMessage(
          rotation,
          `${this.eventSources.find(s => s[1] === t.canonical_source_override)[0]} set as source for ${rotation}`,
          false
        );
      }, err => {
        this.addMessage(rotation, err.message || (isObject(err) ? JSON.stringify(err) : err), true);
      });
    } else {
      this.addMessage(rotation, 'Nothing to do', true);
    }
  }

  protected doSaveParentRotation() {
    let [ rotation, parentRotation ] = this.parentRotationChange;
    parentRotation = +parentRotation || '';
    if ([+rotation, ((this.events[rotation].misc as any).parent_rotation || '')].includes(parentRotation)) {
      this.addMessage(rotation, 'Nothing to do', true);
      return false;
    }
    let event = this.dupEvent(this.events[rotation]);
    event.misc.parent_rotation = parentRotation;
    event.misc.update_parent_rotation = true;
    this.dateService.stampModel(event);
    this.eventsService.save(rotation, event).subscribe((t: any) => {
      (this.events[rotation].misc as any).parent_rotation = t.parent_rotation || (t.misc as any).parent_rotation;
      this.addMessage(
        rotation,
        `${t.parentRotation} set as parent for ${rotation}`,
        false
      );
    }, err => {
      this.addMessage(rotation, err.message || (isObject(err) ? JSON.stringify(err) : err), true);
    });
  }

  protected getTeamForPitcherChange(idx) {
    return (this.events[this.pitchersChange[0]].teams[idx] || {}).name || 'TBA';
  }

  protected getPitcherToChange(idx) {
    return ((this.events[this.pitchersChange[0]].pitchers || [])[idx] || {}).name || 'TBA';
  }

  protected viewTeams(leagueId: string, teams: { id: number }[], declarationId: string) {
    this.teamDesignatorService.fetch(declarationId || leagueId).subscribe((td: TeamDesignator) => {
      let teamIds = teams.length > 0 && teams.every(t => !!(t.id || (t as any).double_ids)) ?
        { teamIds: flattenArray(teams.map(t => (t as any).double_ids || t.id)) } :
        {}
      ;
      this.router.navigate(['/teams', { leagueIds: td.designator, labels: `${td.designator}: ${td.name}`, ...teamIds, init: 'true' }]);
    });
  }

  protected viewCountry(countryId: string) {
    if (countryId) {
      this.countriesService.fetch(countryId).subscribe((ct: AdminCountryInterface) => {
        this.router.navigate(['/countries', { search: `${ct.name}` }]);
      });
    } else {
      alert('No country to view');
    }
  }

  protected viewLeague(leagueId: string) {
    this.leaguesService.fetch(leagueId).subscribe((lg: AdminLeagueInterface) => {
      this.router.navigate(['/leagues', { search: `${lg.name}:${lg.sport_id}` }]);
    });
  }

  protected hideItemsForAll(prop: string) {
    Object.keys(this[prop]).forEach(rotation => this[prop][rotation] = false);
  }

  protected hideScoreForAll() {
    return this.hideItemsForAll('visibleScores');
  }

  protected hideSettlementsForAll() {
    return this.hideItemsForAll('visibleSettlements');
  }

  protected getMiscProps(rotation: number) {
    return Object.keys(this.events[rotation].misc || {}).filter(f => !['fromBaalm', 'status', 'sport_name', 'league_name'].includes(f));
  }

  protected ensureString(obj, prop) {
    if (prop === 'gsAdminFlags') {
      return obj;
    }
    return typeof obj === 'object' ?
      JSON.stringify(obj) :
      obj;
  }

  protected viewRaw(rotation) {
    this.dialogService
      .alert
      .title(`Rotation ID: ${rotation}`)
      .body(`${JSON.stringify(this.events[rotation], null, 2)}`)
      .asTextArea()
      .open();
  }

  protected isShowingItems(prop: string): boolean {
    return this.rotationsToDisplay.length > 0 &&
      Object.keys(this[prop]).length > 0 &&
      Object.keys(this[prop])
        .map(rotation => this[prop][rotation])
        .reduce((acc, curr) => acc || curr, false);
  }

  protected isShowingScores() {
    return this.isShowingItems('visibleScores');
  }

  protected isShowingScore(rotation) {
    return !!this.visibleScores[rotation];
  }

  protected viewScore(rotation) {
    this.visibleScores[rotation] = !this.visibleScores[rotation];
  }

  protected isShowingSettlements() {
    return this.isShowingItems('visibleSettlements');
  }

  protected isShowingSettlement(rotation) {
    return !!this.visibleSettlements[rotation];
  }

  protected viewSettlements(rotation) {
    this.visibleSettlements[rotation] = !this.visibleSettlements[rotation];
  }

  protected doRemoveLines() {
    this.showWagertypesDialog = false;
    let [displaySource, source] = this.lineSources[this.lineSourceIndexControl.value];
    if (!(this.selectedRotations.size > 0 && source)) {
      alert('Must select at least one rotation to delete');
      return;
    }

    this.confirmModalWithRotations('Remove lines')
      .then(() => {
        const wagertypes = this.getWagertypes();
        this.eventsService
          .batchDelete(Array.from(this.selectedRotations), { action: 'removeLines', source, ...(wagertypes && { wagertypes }) })
          .subscribe(result => {
            this.selectedRotations.forEach(rotation => {
              this.addMessage(
                rotation,
                `removeLines ${displaySource || source} ${rotation}: ${result ? 'success' : 'failure'}`,
                !result
              );
            });
          }, err => {
            this.selectedRotations.forEach(rotation => this.addMessage(
              rotation,
              err.message || (isObject(err) ? JSON.stringify(err) : err),
              true
            ));
          });
      })
      .catch(() => {
        this.selectedRotations.forEach(rotation => this.addMessage(rotation, 'Remove lines canceled', true));
      })
      .finally(() => {
        this.selectedActiveWagertypes = [];
      });
  }

  private getWagertypes() {
    const activeWagertypesLength = Object.keys(this.activeWagertypes).length;
    return activeWagertypesLength &&
      this.selectedActiveWagertypes.length &&
      this.selectedActiveWagertypes.length !== activeWagertypesLength
      ? this.selectedActiveWagertypes.reduce((p, wt) => `${p}&wagertypes=${wt}`, `${this.selectedActiveWagertypes.shift()}`)
      : undefined;
  }

  protected clearSelection() {
    this.selectedRotations.clear();
    this.isShowingSelected = false;
    this.calculateRotations();
  }


  protected doExpireCancel(kind: 'cancel' | 'expire' | 'settlements') {
    if (this.selectedRotations.size === 0) {
      alert('Must select rotation');
      return;
    }

    const title = {
      cancel: 'Canceling',
      expire: 'Expiring',
      'settlements': 'Removing settlements from'
    };

    this.confirmModalWithRotations(`${title[kind]} events`)
      .then(() => {
        this.eventsService
        .batchDelete(Array.from(this.selectedRotations), { action: kind })
        .subscribe(result => {
            Array.from(this.selectedRotations)
              .forEach(rotation => {
                this.addMessage(rotation, `${kind} ${rotation}: ${result ? 'success' : 'failure'}`, !result);
              });
          },
          err => {
            Array.from(this.selectedRotations)
              .forEach(rotation => {
                this.addMessage(rotation, err.message || (isObject(err) ? JSON.stringify(err) : err), true);
              });
          }
        );
      })
      .catch(() => {
        const err = 'Update canceled';
        Array.from(this.selectedRotations)
          .forEach(rotation => {
            this.addMessage(rotation, err, true);
          });
      });
  }

  protected doDeletePitchers() {
    let rotation = this.pitchersChange[0];
    if (rotation) {
      let eventToSave = this.dupEvent(this.events[rotation]);
      eventToSave.deletePitchers = true;
      this.dateService.stampModel(eventToSave);
      this.eventsService.save(rotation, eventToSave).subscribe(resultEvent => {
        if (resultEvent && !resultEvent.pitchers) {
          delete eventToSave.pitchers;
          delete eventToSave.deletePitchers;
          this.addMessage(rotation, 'Pitchers deleted', false);
          this.applyChanges([eventToSave]);
        } else {
          this.addMessage(rotation, 'Unable to delete pitchers', true);
        }
      }, err => {
        this.addMessage(rotation, `Pitchers removal for ${rotation} failure -- ${err}`, true);
      });
    } else {
      this.addMessage(rotation, 'No pitchers to update', false);
    }
  }

  protected doSavePitchers(shouldSave) {
    let [rotation, ...pitchers] = this.pitchersChange;
    this.pitchersChange = [];
    if (!shouldSave) {
      return;
    }
    let activePitchers = (this.events[rotation].pitchers || [ { name: '_' } ]).map(b => b.name);
    pitchers[0] = pitchers[0] || activePitchers[0];
    pitchers[1] = pitchers[1] || activePitchers[1];
    if (rotation && pitchers.length === 2 && (pitchers[0] !== activePitchers[0] || pitchers[1] !== activePitchers[1])) {
      let eventToSave = this.dupEvent(this.events[rotation]);
      eventToSave.newPitchers = pitchers;
      this.dateService.stampModel(eventToSave);
      this.eventsService.save(rotation, eventToSave).subscribe(resultEvent => {
        if (resultEvent && resultEvent.pitchers && (resultEvent.pitchers[0].name !== activePitchers[0] ||
          resultEvent.pitchers[1].name !== activePitchers[1])
        ) {
          this.addMessage(rotation, 'Pitchers updated', false);
          delete eventToSave.newPitchers;
          eventToSave.pitchers = resultEvent.pitchers;
          this.applyChanges([eventToSave]);
        } else {
          this.addMessage(rotation, 'Unable to update pitchers', true);
        }
      }, err => {
        this.addMessage(rotation, `Pitchers update for ${rotation} failure -- ${err}`, true);
      });
    } else {
      this.addMessage(rotation, 'No pitchers to update', false);
    }
  }

  protected doChangeChannel(rotation, streamPlatform) {
    let event = this.events[rotation];
    let channel = event.misc[`${streamPlatform}Channel`] || '';
    this.channelChange = [ rotation, streamPlatform, channel ];
  }

  protected doDeleteChannel(rotation, streamPlatform) {
    const result = confirm(`Delete ${streamPlatform} channel for rotation: ${rotation}?`);
    if (result) {
      this.doUpdateChannel(rotation, streamPlatform, '*');
    } else {
      this.addMessage(rotation, `${streamPlatform} channel update canceled`, true);
    }
  }

  protected doSaveChannel(shouldSave) {
    let [rotation, streamPlatform, channel] = this.channelChange;
    this.channelChange = [];
    if (shouldSave && rotation) {
      this.doUpdateChannel(rotation, streamPlatform, channel);
    } else {
      this.addMessage(rotation, `Stream info update canceled`, true);
    }
  }

  protected doUpdateChannel(rotation, streamPlatform, channel) {
    let eventToSave = this.dupEvent(this.events[rotation]);
    eventToSave.misc[`${streamPlatform}Channel`] = channel || undefined;
    this.dateService.stampModel(eventToSave);
    this.eventsService.save(rotation, eventToSave).subscribe(result => {
      this.addMessage(rotation, `${streamPlatform} channel update ${rotation}: ${result ? 'success' : 'failure'}`, !result);
      this.applyChanges([eventToSave]);
    }, err => {
      this.addMessage(rotation, `${streamPlatform} channel update for ${rotation} failure -- ${err}`, true);
    });
  }

  protected doSaveFeaturedTimeframe(rotation, newValue) {
    const eventToSave = this.dupEvent(this.events[rotation]);
    eventToSave.misc.featuredTimeframe = newValue;
    this.dateService.stampModel(eventToSave);
    this.eventsService.save(rotation, eventToSave).subscribe(result => {
      this.addMessage(rotation, `Featured event timeframe update for ${rotation}: ${result ? 'success' : 'failure'}`, !result);
      this.applyChanges([eventToSave]);
    }, err => {
      this.addMessage(rotation, `Featured event timeframe update for ${rotation} failure -- ${err}`, true);
    });
  }

  protected getFeaturedTimeframe(rotation: string): number {
    return this.events[rotation]?.misc?.featuredTimeframe ?? 0;
  }

  protected addMarketDisclaimer(rotation) {
    if (!this.events[rotation].misc.marketDisclaimers) {
      this.events[rotation].misc.marketDisclaimers = '\n';
    } else {
      this.events[rotation].misc.marketDisclaimers += '\n';
    }
    this.events[rotation] = this.events[rotation];
  }

  protected doDeleteMarketDisclaimers(rotation: string) {
    this.doSaveMarketDisclaimers(rotation, null, ' ');
  }

  protected doSaveMarketDisclaimers(rotation: string, $event, disclaimers?: string) {
    const eventToSave = this.dupEvent(this.events[rotation]);
    eventToSave.misc.marketDisclaimers = (disclaimers ||
      Array.from($event.target.parentElement.parentElement.querySelectorAll('input') || []).map(b => {
        return (b as unknown as any).value;
      }).filter(b => !!b).join('\n')).trim()
    ;
    this.dateService.stampModel(eventToSave);
    this.eventsService.save(rotation, eventToSave).subscribe(result => {
      this.addMessage(rotation, `Market disclaimers for event ${rotation} : ${result ? 'success' : 'failure'}`, !result);
      this.applyChanges([eventToSave]);
    }, err => {
      this.addMessage(rotation, `Market disclaimers for event ${rotation} failed -- ${err}`, true);
    });
  }

  protected getMarketDisclaimers(rotation: string): string[] {
    return (this.events[rotation]?.misc?.marketDisclaimers || '').split('\n');
  }

  protected doChangeGsDescription(rotation) {
    let event = this.events[rotation];
    let gsDescription = event.misc.gsDescription || '';
    this.gsDescriptionChange = [ rotation, gsDescription ];
  }

  protected doSaveGsDescription(shouldSave) {
    let [rotation, gsDescription] = this.gsDescriptionChange;
    this.gsDescriptionChange = [];
    if (shouldSave && rotation) {
      let eventToSave = this.dupEvent(this.events[rotation]);
      eventToSave.misc.gsDescription = gsDescription;
      this.dateService.stampModel(eventToSave);
      this.eventsService.save(rotation, eventToSave).subscribe(result => {
        this.addMessage(rotation, `Ganchrow Description update ${rotation}: ${result ? 'success' : 'failure'}`, !result);
        this.applyChanges([eventToSave]);
      }, err => {
        this.addMessage(rotation, `Ganchrow Description update for ${rotation} failure -- ${err}`, true);
      });
    } else {
      this.addMessage(rotation, `Ganchrow Description update canceled`, true);
    }
  }

  protected doDeleteCutoff() {
    this.batchUpdate(this.getSelectedUpdatedDups(event => {
      delete event.wager_cutoff;
    })).subscribe(() => { /** */ });
  }

  protected doChangeDate(rotation) {
    let rotations = rotation || this.selectedRotations;
    if (!rotation) {
      let it = this.selectedRotations.values();
      rotation = it.next().value;
    }
    let event = this.events[rotation];
    let date = event.start_date;
    this.dateChange = [rotations, (new Date(date - (new Date()).getTimezoneOffset() * 60000)).toISOString().replace(/Z$/, '')];
  }

  protected doSaveDate(shouldSave, cutoff = false) {
    if (shouldSave) {
      let dateFields: [string, string] = cutoff ? ['wager_cutoff', 'Wager Cutoff'] : ['start_date', 'Start Date'];
      if (!this.isMultipleDateChange()) {
        let [rotation, date] = this.dateChange;
        let newDateMS = +new Date(date);
        this.dateChange = [];
        if (rotation && date && newDateMS) {
          let eventToSave = this.dupEvent(this.events[rotation]);
          eventToSave[dateFields[0]] = newDateMS;
          this.dateService.stampModel(eventToSave);
          this.eventsService.save(rotation, eventToSave).subscribe(result => {
            this.addMessage(rotation, `${dateFields[1]} update ${rotation}: ${result ? 'success' : 'failure'}`, !result);
            this.applyChanges([eventToSave]);
          }, err => {
            this.addMessage(rotation, `${dateFields[1]} update for ${rotation} failure -- ${err}`, true);
          });
        } else {
          this.addMessage(rotation, `${dateFields[1]} update canceled`, true);
        }
      } else {
        let eventsToSave = this.getSelectedUpdatedDups(this.saveNewDateOperation(this.dateChange[1], dateFields));
        this.batchUpdate(eventsToSave).subscribe(success => {
          if (success) {
            this.dateChange = [];
          }
        });
      }
    } else {
      this.dateChange = [];
    }
  }

  protected doSaveStatus(newStatus) {
    let eventsToSave = this.getSelectedUpdatedDups(this.saveStatusOperation(newStatus));
    this.batchUpdate(eventsToSave).subscribe(success => {
      if (success) {
        this.resetStateSignal.next(true);
      }
    });
  }

  protected doSearch() {
    this.resetFeedSources();
    this.fetch(this.eventSearchType, this.rotationsPattern,
      this.selectedLeagueIds, this.selectedStatuses, this.selectedSportIds, this.feedSourceQuery && this.feedSourceQuery.source);
    this.updateUrl(this.eventSearchType, this.rotationsPattern,
      this.selectedLeagueIds, this.selectedStatuses, this.selectedSportIds, this.feedSourceQuery && this.feedSourceQuery.source);
  }

  protected getEventLabel(rotation) {
    if (!this.events[rotation]) {
      return rotation;
    }
    let teams = this.events[rotation].teams as any;
    let message = `${rotation}: `;
    if (teams) {
      let teamsMap = teams.map(team => {
          return `${team.name || ''} ${team.nick_name || ''}`;
        });
      if (teams.length > 2) {
        message += teamsMap.join(' / ');
      } else {
        message += teamsMap.join(' vs ');
      }
    }
    return message;
  }

  protected moveToLeague({ league: selectedLeague }) {
    this.newLeague = selectedLeague?.id;
  }

  protected leaguesChanged({leagueIds}) {
    this.selectedLeagueIds = leagueIds;
  }

  protected sportsChanged({sportIds}) {
    this.selectedSportIds = sportIds;
  }

  protected searchStatusChanged({newStatus}) {
    this.selectedStatuses = newStatus;
  }

  protected doMoveLeague() {
    if (!(this.selectedRotations.size > 0 && Number(this.newLeague) > 0)) {
      alert('New league must be valid.');
      return;
    }

    let eventsToSave = this.getSelectedUpdatedDups(this.moveLeagueOperation(+this.newLeague));
    this.batchUpdate(eventsToSave).subscribe(() => {/** */});
  }

  protected doSaveDeclaration(newDeclaration: string) {
    if (!(this.selectedRotations.size > 0 && newDeclaration)) {
      alert('New declaration must be valid.');
      return;
    }

    let eventsToSave = this.getSelectedUpdatedDups(this.updateDeclarationOperation(newDeclaration));
    this.batchUpdate(eventsToSave).subscribe(() => {/** */});
  }

  protected doUpdateStartingTime(timeToAddSubtract: number) {
    let eventsToSave = this.getSelectedUpdatedDups(this.addSubtractTimeOperation(timeToAddSubtract));
    eventsToSave.forEach(event => event.freezeDate = 1);
    this.batchUpdate(eventsToSave).subscribe(() => {/** */});
  }

  protected doFreezeOrUnfreezeDates(freezeOrUnfreeze: boolean) {
    let eventsToSave = this.getSelectedUpdatedDups((event: AdminEventInterface) => {
      event.freezeDate = freezeOrUnfreeze ? 1 : -1;
    });
    this.batchUpdate(eventsToSave).subscribe(() => { /** */});
  }

  protected isMultipleDateChange() {
    return this.dateChange[0] instanceof Set;
  }

  protected manageLines() {
    this.manageLineMode = true;
  }

  protected manageCorrelations() {
    this.manageCorrelationMode = true;
  }

  protected manageLeagueCorrelations() {
    this.manageLeagueCorrelationMode = true;
  }

  protected openCreateEventDialog() {
    this.dialogService
      .confirm
      .title('Create event')
      .body(AddEventComponent)
      .open()
      .then(event => {
        this.eventsMessage = `Event ${event?.rotation} has been created successfully`;
      })
      .catch(r => { /** */ });
  }

  protected openEventRequestorDialog() {
    this.dialogService
      .alert
      .title('Event Sources Requestor')
      .body(RequestEventComponent)
      .open()
      .then(rotation => {
        this.eventsMessage = `Event ${rotation} has been requested successfully`;
      })
      .catch(r => { /** */ });
  }

  protected openLeagueRequestorDialog() {
    this.dialogService
      .alert
      .title('League Sources Requestor')
      .body(RequestLeagueComponent)
      .open()
      .catch(r => { /** */ });
  }

  protected get selectedRotationsArray(): number[] {
    return Array.from(this.selectedRotations);
  }

  protected get selectedLeaguesFromRotationsArray(): number[] {
    return Object.keys(this.selectedRotationsArray.reduce((leagueIds, rotation) => {
      leagueIds[this.events[rotation].league_id] = true;
      return leagueIds;
    }, {})).map(Number);
  }

  protected get selectedGanchrowRotationsArray(): number[] {
    return this.selectedRotationsArray.reduce((ganchrowRotations, rotation) => {
      return ganchrowRotations.concat(
        (this.events[rotation].misc as any).source_rotations?.ganchrow ||
        (this.events[rotation].misc as any).source_rotations?.lsports ||
        rotation
      );
    }, []);
  }

  protected get selectedSportsArray() {
    return this.selectedRotationsArray.map(rotation => {
      let event = this.events[rotation];
      return String(event.misc?.sport_name || event.sport_id).toLowerCase();
    });
  }

  protected get selectedLeaguesArray() {
    return this.selectedRotationsArray.map(rotation => {
      let event = this.events[rotation];
      return String(event.misc?.league_name || event.league_id).toLowerCase();
    });
  }

  protected get activeWagertypesOptions() {
    return Object.entries(this.activeWagertypes).map(([id, name]) => ({ id, name }));
  }

  protected doBlockLineSet(clear?: boolean): void {
    if (!(this.selectedRotations.size > 0 && ( clear || this.selectedLineSets.length > 0 ))) {
      alert(`Must select at least one rotation to ${clear ? 'clear' : 'block'}`);
      return;
    }

    this.confirmModalWithRotations(`${clear ? 'Clear blocked' : 'Block'} lineSets`).then(() => {
      this.lineSetsService.batchSave(
        this.selectedGanchrowRotationsArray.map(rotation => ({
          rotation, blocked: clear ? {} : Array.from(this.selectedLineSets).reduce((prev, curr) => ((prev[curr] = true), prev), {})
        }))).subscribe(result => {
          this.selectedRotations.forEach(rotation => {
            this.addMessage(
              rotation,
              `${clear ? 'Clear blocked' : 'Block'} lines ${rotation}: ${result ? 'success' : 'failure'}`,
              !result
            );
          });
        }, err => {
          this.selectedRotations.forEach(rotation => this.addMessage(
            rotation,
            err.message || (isObject(err) ? JSON.stringify(err) : err),
            true
          ));
        });
    }).catch(() => {
      this.selectedRotations.forEach(rotation => this.addMessage(rotation, `${clear ? 'Clear blocked' : 'Block'} lineSets canceled`, true));
    });
  }

  private batchUpdate(eventsToSave) {
    return this.eventsService.batchSave(eventsToSave)
    .pipe(
      map(result => {
        this.applyChanges(eventsToSave);
        eventsToSave.forEach(event => {
          this.addMessage(event.rotation, `Update on ${event.rotation}: ${result ? 'success' : 'failure'}`, !result);
        });
        return of(true);
      }),
      catchError(err => {
        eventsToSave.forEach(event => this.addMessage(event.rotation, `Update on ${event.rotation}: failure ${err}`, true));
        return of(false);
      })
    );
  }

  private getSelectedUpdatedDups(operation: OperationOnRotationFn) {
    let eventsToSave = [];
    this.selectedRotations.forEach(rotation => {
      let event = this.dupEvent(this.events[rotation]);
      operation(event);
      this.dateService.stampModel(event);
      eventsToSave.push(event);
    });
    return eventsToSave;
  }

  private addSubtractTimeOperation(timeToAddSubtract: number): (event: AdminEventInterface) => void {
    let timeInMs = timeToAddSubtract * 60 * 1000;
    return (event: AdminEventInterface) => event.start_date = event.start_date + timeInMs;
  }

  private moveLeagueOperation(newLeague: number): (event: AdminEventInterface) => void {
    return (event: AdminEventInterface) => event.new_league_id = newLeague;
  }

  private updateDeclarationOperation(newDeclaration: string): (event: AdminEventInterface) => void {
    return (event: AdminEventInterface) => event.declaration = newDeclaration;
  }

  private saveStatusOperation(newStatus: number): (event: AdminEventInterface) => void {
    return (event: AdminEventInterface) => event.status = newStatus;
  }

  private saveNewDateOperation(newDate: number, dateFields: [string, string]): (event: AdminEventInterface) => void {
    let newDateMs = +new Date(newDate);
    return (event: AdminEventInterface) => event[dateFields[0]] = newDateMs;
  }

  private confirmModalWithRotations(title) {
    return this.dialogService
      .confirm
      .title(title)
      .body(this.getSelectedRotationsListHtml())
      .open();
  }

  private applyChanges(eventsToUpdate) {
    eventsToUpdate.forEach(event => {
      this.events[event.rotation] = event;
      ['agGridOne', 'agGridTwo'].forEach((id) => {
        if (this[id] && !this[id].api.destroyCalled) {
          this[id].api.applyTransaction({ update: [event.rotation] });
        }
      });
    });
  }

  private calculateRotations() {
    if (this.isShowingSelected) {
      this.rotationsToDisplay = Array.from(this.selectedRotations);
    } else {
      this.rotationsToDisplay = Object.keys(this.events);
    }
  }

  private dupEvent(event) {
    let eventToSave = dup(event);
    delete eventToSave.score;
    if (eventToSave.misc && (eventToSave.misc as any).source) {
      delete (eventToSave.misc as any).source;
    }
    return eventToSave;
  }

  private async fetch(
    eventSearchType: string, rotationsPattern: string, selectedLeagueIds: number[], selectedStatuses: number[], selectedSportIds: number[],
    feedSourceQuery?: string
  ) {
    let requestParams: Query;
    switch (eventSearchType) {
    case 'by-rotation':
      requestParams = { pattern: rotationsPattern };
      break;
    case 'by-league':
      requestParams = { league_ids: selectedLeagueIds };
      break;
    case 'by-sport':
      requestParams = { sport_ids: selectedSportIds };
      break;
    case 'by-status':
      requestParams = { statuses: selectedStatuses };
      break;
    default:
      requestParams = {};
    }
    if (feedSourceQuery) {
      requestParams.hasLines = (
        (feedSourceQuery as any).feedSourceQuery ? (feedSourceQuery as any).feedSourceQuery.source : feedSourceQuery
      ).replace(/:/, '%3A');
    }
    this.selectedRotations.clear();
    this.activeWagertypes = {};
    this.fetchingResults = true;
    await this.readyToFetch.promise;
    this.eventsService.list(requestParams)
    .pipe( concatMap( async (r) => {
        const events = {};
        for (const [k, v] of Object.entries(r)) {
          events[k] = { ...v, status_display: await this.systemStatusService.getStatusName(v.status) };
        }
        return events;
      }
    )).subscribe((result: Events) => {
      this.fetchingResults = false;
      this.events = result;
      this.populateActiveWagertypes();
      this.populateSettlements();
      this.logicalFeedSources = this.extractFeedSources(result);
      this.visibleScores = {};
      this.visibleSettlements = {};
      this.calculateRotations();
    });
  }

  private populateActiveWagertypes(): void {
    Object.entries(this.events).forEach(([_, ev]) => {
      if (Array.isArray(ev.period_wagertypes) && ev.period_wagertypes.length > 0) {
        ev.period_wagertypes.forEach((w) => {
          const wt = w.split(':')[1];
          this.activeWagertypes[wt] = this.wagertypes[wt]?.name || 'NA';
        });
        delete ev.period_wagertypes;
      }
    });
  }

  private populateSettlements(): void {
    this.activeSettlements = {};
    Object.entries(this.events).forEach(([rot, ev]) => {
      if (Array.isArray(ev.settlements) && ev.settlements.length > 0) {
        ev.settlements.forEach((s) => {
          const [pd, wt, fig] = s.split(':');
          this.activeSettlements[rot] = this.activeSettlements[rot] || [];
          this.activeSettlements[rot].push(
            { period: pd, wagertype: this.wagertypes[wt]?.name || 'NA', figure: fig || '0' }
          );
        });
        delete ev.settlements;
      }
    });
  }

  private updateUrl(
    eventSearchType: string, pattern: string, selectedLeagueIds: number[], selectedStatuses: number[], selectedSportIds: number[],
    feedSourceQuery: string
  ) {
    let pageParams: any = { };
    if (pattern) {
      pageParams.pattern = pattern;
    }
    if (selectedLeagueIds.length) {
      pageParams.league_ids = selectedLeagueIds;
    }
    if (selectedStatuses.length) {
      pageParams.statuses = selectedStatuses;
    }
    if (selectedSportIds.length) {
      pageParams.sport_ids = selectedSportIds;
    }
    if (feedSourceQuery) {
      pageParams.has_lines = feedSourceQuery;
    }
    pageParams.eventSearchType = eventSearchType || 'by-rotation';

    this.router.navigate(['/events', pageParams]);
  }

  private getExpireTitleHtml(kind) {
    return `Do you want to ${kind} the following events?`;
  }

  private getSelectedRotationsListHtml() {
    return Array.from(this.selectedRotations)
      .map(rotation => `${this.getEventLabel(rotation)}`)
      .join('<br/>');
  }

  private getRemoveLinesTitleHtml(source) {
    return `Do you want to remove lines from ${source} for the following events?`;
  }

  private addMessage(rotation, message: string, isError: boolean) {
    isError ? this.snackbarService.addErrorMessage(message) : this.snackbarService.addSuccessMessage(message);
  }

  private shouldFetch(
    newEventSearchType: EventSearchType, newRotations: string, newLeagueIds = [], newStatuses = [], newSportIds = []
  ): boolean {
    if (newEventSearchType !== this.eventSearchType) {
      switch (newEventSearchType) {
      case 'by-rotation' :
        return !!newRotations;
      case 'by-league' :
        return !!newLeagueIds.length;
      case 'by-sport' :
        return !!newSportIds.length;
      case 'by-status' :
        return !!newStatuses.length;
      default :
        return false;
      }
    }

    if (this.eventSearchType === 'by-rotation') {
      return newRotations !== this.rotationsPattern && !!newRotations;
    } else if (this.eventSearchType === 'by-league') {
      return JSON.stringify(newLeagueIds) !== JSON.stringify(this.selectedLeagueIds) && !!newLeagueIds.length;
    } else if (this.eventSearchType === 'by-sport') {
      return JSON.stringify(newSportIds) !== JSON.stringify(this.selectedSportIds) && !!newSportIds.length;
    } else {
      return JSON.stringify(newStatuses) !== JSON.stringify(this.selectedStatuses) && !!newStatuses.length;
    }
  }

  private createUniqueSourceRecord(events: Events): Record<string, string> {
    return Object.keys(events).reduce((obj, rotation) => {
      let event = events[rotation];
      return (obj[event.logical_feed_source] = event.source_display || event.logical_feed_source, obj);
    }, {});
  }

  private extractFeedSources(events: Events): { source: string, alias: string }[] {
    let obj = this.createUniqueSourceRecord(events);
    return Object.keys(obj).map(source => ({ source, alias: obj[source] }));
  }

  private filterRotations(rotations, logicalFeedSource) {
    return rotations.filter(rotation => this.events[rotation].logical_feed_source === logicalFeedSource);
  }

  private resetFeedSources() {
    this.logicalFeedSources = [];
    this.feedSourceOneControl.setValue('');
    this.feedSourceTwoControl.setValue('');
  }

  private isValidTimeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return isNaN(control.value) ? { isValidTime: 'Invalid time' } : null;
    };
  }

  private ensureEnvironment() {
    this.serverEnvironmentService.request().subscribe(env => {
      this.canAddToPlatform = (env as any)?.canAddToPlatform;
      this.baseballSportId = (env as any).baseballSportId || 1;
      this.platformUrl = (env as any).platformUrl;
    }, err => {
      this.canAddToPlatform = false;
    });
  }
}


function extractFields(params: Params, field: 'league_ids' | 'statuses' | 'sport_ids' | 'has_lines' = 'league_ids') {
  try {
    return JSON.parse('[' + (params as any)[field] + ']') || [];
  } catch (e) {
    // ignore malformed state param
    return [];
  }
}
