/* Copyright © 2022-2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { toArray } from 'gs-utils/lib/utilities';

import { Validator, ValidationResponse } from './validator';
import { Correlations } from '../models/gsModel';

const GLOBAL_PASSTHRU = [3.14159 * 100, 3.14159 * 200];

export class CorrelationValidator extends Validator<Correlations> {
  public validate(rotation: string, instances: Correlations): ValidationResponse {
    let errors = [];
    if (!instances) {
      errors.push('Must supply correlation info.');
    }
    errors.push(...this.checkInteger(rotation, 'rotation'));
    instances.forEach(instance => {
      if (!instance) {
        errors.push('Must supply correlation info.');
      }

      errors.push(...this.checkInteger(instance.side, 'side', -1));
      errors.push(...this.checkInteger(instance.period, 'period', -1));
      errors.push(...this.checkInteger(instance.wagertype, 'wagertype', -1));
      errors.push(...this.checkInteger(Math.abs(instance.minFigure * 100), 'minFigure', -1, GLOBAL_PASSTHRU));
      errors.push(...this.checkInteger(Math.abs(instance.maxFigure * 100), 'maxFigure', -1, GLOBAL_PASSTHRU));
      errors.push(...this.checkInteger(instance.correlatedSide, 'correlatedSide', -1));
      errors.push(...this.checkInteger(instance.correlatedPeriod, 'correlatedPeriod', -1));
      errors.push(...this.checkInteger(instance.correlatedWagertype, 'correlatedWagertype', -1));
      errors.push(...this.checkInteger(Math.abs(instance.correlatedMinFigure * 100), 'correlatedMinFigure', -1, GLOBAL_PASSTHRU));
      errors.push(...this.checkInteger(Math.abs(instance.correlatedMaxFigure * 100), 'correlatedMaxFigure', -1, GLOBAL_PASSTHRU));
      if (!(instance.value >= -1 && instance.value <= 1)) {
        errors.push('correlation must be between -1 and 1');
      }
    });

    if (errors.length) {
      return {
        success: false,
        reasons: errors
      };
    }
    return {
      success: true
    };
  }

  private checkInteger(field: string | number, name: string, gt = 0, passThru = null): string[] {
    field = Number(field);
    if (!(Number.isInteger(field) || (toArray(passThru).some(p => field === p)))) {
      return [`${name} must be an integer`];
    }
    if (field <= gt) {
      return [`${name} must be greater than ${gt}`];
    }
    return [];
  }
}
