/* Copyright © 2017-2022 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

const ACTIVE_EVENTS_PATH = '/api/eventSource';

@Injectable()
export class ActiveEventsService<T extends { result: any; rawInstance: any; } = { result: any; rawInstance: any }> {

  constructor(protected http: HttpClient) {
    /**/
  }

  public delete(key: string) {
    return this.http.delete(`${ACTIVE_EVENTS_PATH}/${key}`, { withCredentials: true });
  }

  public update(key: string, body: T['rawInstance']): Observable<T> {
    return this.http.post<T>(`${ACTIVE_EVENTS_PATH}/${key}`, body, { withCredentials: true });
  }

  public list(key: string): Observable<any> {
    return this.http.get(`${ACTIVE_EVENTS_PATH}/${key}`);
  }
}
