/* Copyright © 2023 Ganchrow Scientific, SA all rights reserved */
'use strict';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface Message {
  message: string;
  status: string;
}

@Injectable()
export class SnackBarService {
  private processingMessage = false;
  private messageQueue: Message[] = [];
  private snackbarDuration = 1000;

  constructor(private snackBar: MatSnackBar) {}

  get duration() {
    return this.snackbarDuration;
  }

  set duration(duration) {
    this.snackbarDuration = duration ?? this.snackbarDuration;
  }

  private displaySnackbar(): void {
    const nextMessage = this.getNextMessage();

    if (!nextMessage) {
      this.processingMessage = false;
      return;
    }
    this.processingMessage = true;
    this.snackBar
      .open(nextMessage.message, undefined, {
        duration: this.duration,
        panelClass: [`${nextMessage.status}-snackbar`],
        horizontalPosition: 'center',
        verticalPosition: 'top',
      })
      .afterDismissed()
      .subscribe(() => {
        this.displaySnackbar();
      });
  }

  private getNextMessage(): Message | undefined {
    return this.messageQueue.length ? this.messageQueue.shift() : undefined;
  }

  private addMessage(message: string, status = 'error'): void {
    this.messageQueue.push({ message, status });
    if (!this.processingMessage) {
      this.displaySnackbar();
    }
  }

  public addErrorMessage(message) {
    this.addMessage(message);
  }

  public addSuccessMessage(message) {
    this.addMessage(message, 'success');
  }
}
