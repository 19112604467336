/* Copyright © 2021-2022 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, Input, OnInit } from '@angular/core';

import { FilterListPipe } from '../filters/filterListPipe.filter';
import { MatchupsService } from './matchups.service';

@Component({
  selector: 'matchups',
  templateUrl: 'matchups/matchups.component.html',
  styles: [
    require('./matchups.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe, MatchupsService]
})

export class MatchupsComponent implements OnInit {
  @Input()
  protected sportId: number;

  protected matchups: {
    displayName?: string; originalNames: string[]; figure: number; names: string
  }[] = [];

  protected selectedFigures: Set<number> = new Set();
  protected hasError: Record<string, { message: string; isError: boolean }> = {};

  constructor(private matchupsService: MatchupsService) { /**/ }

  public ngOnInit(): void {
    this.matchupsService.fetch(`${this.sportId}`).subscribe(matchups => {
      this.matchups = Object.entries(
        matchups as Record<string, { names: string[]; displayName?: string }>
      ).reduce((obj, [figure, record]) => {
        return obj.concat({
          originalNames: record.names, names: record.names.join('\n'), figure, displayName: record.displayName || ''
        });
      }, []);
      this.matchups.sort((a, b) => {
        return a.figure - b.figure;
      });
    });
  }

  protected reset(): void {
    this.matchups = [];
    this.ngOnInit();
  }

  protected ready(): boolean {
    return !!(this.sportId && this.matchups.length);
  }

  protected doSave($event, figures: number[]) {
    let toSave = figures.map(figure => {
      let [mappings, displayName] = this.getMappingsAndDisplayName(figure);
      return { displayName, figure: +figure, names: mappings.split('\n').map(b => b.trim()) };
    });
    this.matchupsService.save(this.sportId, toSave).subscribe(results => {
      results.forEach(res => {
        this.addMessage(res.figure, 'Saved successfully!', false);
      });
    }, err => {
      this.addMessage(
        figures.length === 1 ? figures[0] : -1, err.error || err.message || JSON.stringify(err), true
      );
    });
  }

  protected getMappingsAndDisplayName(figure: number): [string, string] {
    return [
      (document.querySelector(`#mappings-edit-${figure}`) as HTMLInputElement).value,
      (document.querySelector(`#display-name-edit-${figure}`) as HTMLInputElement).value
    ];
  }

  protected select($event, figure: number) {
    if (this.selectedFigures.has(figure)) {
      this.selectedFigures.delete(figure);
    } else {
      this.selectedFigures.add(figure);
    }
  }

  protected batchSave($event) {
    this.doSave($event, Array.from(this.selectedFigures));
  }

  protected clear() {
    this.selectedFigures.clear();
  }

  private addMessage(figure: number, message = '', isError = false) {
    this.hasError[figure] = { message, isError };
    setTimeout(() => {
      delete this.hasError[figure];
    }, 5000);
  }

}
