
/* Copyright © 2017-2021 Ganchrow Scientific, SA all rights reserved */
'use catrict';

import { Component, OnInit } from '@angular/core';

import { MarketTypeCategoriesService } from './marketTypeCategories.service';

@Component({
  selector: 'market-categories',
  templateUrl: 'wagertypes/marketCategories.component.html',
  styles: [
    require('./marketCategories.component.scss')  // tslint:disable-line
  ],
  providers: [MarketTypeCategoriesService]
})

export class MarketCategoriesComponent implements OnInit {
  protected marketTypeCategories: { id?: number; name: string }[] = [];
  protected creating = false;

  constructor(
    private marketTypeCategoriesService: MarketTypeCategoriesService
  ) { /**/ }

  public ngOnInit(): void {
    this.marketTypeCategoriesService.list().subscribe(marketTypeCategories => {
      this.marketTypeCategories = Object.values(marketTypeCategories).sort((a, b) => a.name.localeCompare(b.name));
      this.sort();
    });
  }

  protected doSave(evt, id: number) {
    let name = evt.target.parentElement.parentElement.querySelector('input').value;
    let category = this.marketTypeCategories.find(cat => {
      return cat.id === id;
    });
    if (category.name === name) {
      alert('Nothing to do');
    } else {
      this.marketTypeCategoriesService.save(id, { id, name }).subscribe(newCat => {
        alert(JSON.stringify(newCat));
        this.marketTypeCategories = this.marketTypeCategories.map(cat => {
          if (cat.id === newCat.id) {
            cat.name = newCat.name;
          }
          return cat;
        });
        this.sort();
      }, err => {
        alert(JSON.stringify(err));
      });
    }
  }
  protected doCreate(name: string) {
    if (name) {
      this.marketTypeCategoriesService.create({ name }).subscribe(newCat => {
        this.creating = !this.creating;
        alert(JSON.stringify(newCat));
        if (!this.marketTypeCategories.find(cat => cat.id === newCat.id)) {
          this.marketTypeCategories = this.marketTypeCategories.concat(newCat);
        }
      }, err => {
        alert(err.error || err.message || JSON.stringify(err));
        this.creating = !this.creating;
      });
    } else {
      this.creating = !this.creating;
      alert('Invalid Name');
    }
  }

  private sort() {
    return this.marketTypeCategories.sort((m1, m2) => m1.id - m2.id);
  }
}
