/* Copyright © 2023 Ganchrow Scientific, SA all rights reserved */

'use strict';

import { Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RotationChangeInterface, RotationChangesService } from './rotationChanges.service';
import { FilterListPipe } from '../filters/filterListPipe.filter';
import { Subscription, switchMap, tap } from 'rxjs';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { AgGridAngular, ICellRendererAngularComp } from 'ag-grid-angular';
import { LicenseManager } from 'ag-grid-enterprise';
import agGridLicense from '../agGridLicense';
import { SnackBarService } from '../services/snackbar.service';

@Component({
  selector: 'rotation-changes',
  templateUrl: 'rotationChanges/rotationChanges.component.html',
  styles: [
    require('./rotationChanges.component.scss'), // tslint:disable-line
  ],
  providers: [FilterListPipe],
})
export class RotationChangesComponent implements OnInit, OnDestroy {
  @ViewChild(AgGridAngular) protected agGrid: AgGridAngular;
  protected rotationChanges: RotationChangeInterface[];
  protected subscriptions = new Subscription();
  protected columnDefs: ColDef[] = [
    {
      headerName: '#',
      valueGetter: 'node.rowIndex + 1',
      sortable: false,
      filter: false,
    },
    ...[
      ['fromRotation', 'From rotation'],
      ['toRotation', 'To rotation'],
    ].map(([field, headerName]) => ({
      field,
      headerName,
    })),
    ...[
      ['startTimestamp', 'Start date'],
      ['creationTimestamp', 'Creation date'],
    ].map(([field, headerName]) => ({
      field,
      filter: 'agDateColumnFilter',
      headerName,
      valueGetter: ({ data }: { data: RotationChangeInterface }) => {
        const date = new DatePipe(this.locale);
        return date.transform(data[field], 'medium');
      },
    })),
    {
      field: 'actions',
      headerName: 'Actions',
      cellRendererFramework: RotationChangesActionsCellRenderer,
    },
  ];
  protected context = { controller: this };
  protected defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  };

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private rotationChangesService: RotationChangesService,
    private snackbarService: SnackBarService
  ) {}

  public ngOnInit(): void {
    const subs = this.rotationChangesService
      .listChanges()
      .pipe(tap((value) => (this.rotationChanges = value)))
      .subscribe();
    this.subscriptions.add(subs);
    LicenseManager.setLicenseKey(agGridLicense);
  }

  protected getRowNodeId(data: RotationChangeInterface): number {
    return data.fromRotation;
  }

  protected deleteRotation(rotation: number): void {
    const subs = this.rotationChangesService
      .deleteRotation(rotation)
      .pipe(switchMap(() => this.rotationChangesService.listChanges()))
      .subscribe({
        next: (data) => {
          this.rotationChanges = data;
          this.snackbarService.addSuccessMessage(`Rotation ${rotation} deleted successfully`);
        },
        error: (err) => this.snackbarService.addErrorMessage(JSON.stringify(err)),
      });
    this.subscriptions.add(subs);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

@Component({
  template: `<a
      mat-raised-button
      [routerLink]="['/events', { pattern: data.fromRotation + ' ' + data.toRotation, eventSearchType: 'by-rotation' }]"
      >Visit rotations
    </a>
    <button
      color="primary"
      (click)="remove()"
      mat-raised-button>
      Remove
    </button>`,
})
export class RotationChangesActionsCellRenderer implements ICellRendererAngularComp {
  private params: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  protected get data(): RotationChangeInterface {
    return this.params.data;
  }

  public refresh(): boolean {
    return false;
  }

  protected remove(): void {
    this.params.context.controller.deleteRotation(this.data.fromRotation);
  }
}
