/* Copyright © 2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TeamInterface } from '../../server/shared/models/team';

import { IMultiSelectOption, IMultiSelectSettings } from '../multiselect/multiselect.types';

import { FilterListPipe } from '../filters/filterListPipe.filter';

import { TeamsService, EMPTY_TEAM } from './teams.service';

@Component({
  selector: 'teams-drop-down',
  templateUrl: 'teams/teamsDropDown.component.html',
  styles: [
    require('./teamsDropDown.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe]
})

export class TeamsDropDownComponent implements OnInit, OnChanges {
  protected teamOptions: IMultiSelectOption[] = [];

  protected selectSettings: IMultiSelectSettings = {
    enableSearch: true,
    containerClasses: 'teams-drop-down__select__container',
    buttonClasses: 'form-control teams-drop-down__select__selector',
    checkedStyle: 'glyphicon',
    showUncheckAll: true
  };

  @Input() protected selectedTeamIds: number[] = [];
  @Input() protected forLeagues: number[] = [];
  @Input() protected filterMappedTeamsForPlayers = false;

  @Output() protected teamsChanged = new EventEmitter<{teamIds: number[], labels: string[]}>();
  @Output() protected searching = new EventEmitter();
  public loading = false;
  private searchingTimeout: number;

  constructor(private teamsService: TeamsService) { /**/ }

  public ngOnInit(): void {
    this.search(this.forLeagues, this.filterMappedTeamsForPlayers);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.forLeagues) {
      this.selectedTeamIds = [];
      if (this.searchingTimeout) {
        window.clearTimeout(this.searchingTimeout);
      }
      this.searchingTimeout = window.setTimeout(() => this.search(this.forLeagues), 1000);
    }
  }

  protected getTeamLabel(team: TeamInterface): string {
    return `${team.id}: ${team.name}`;
  }

  protected handleTeamsChanged() {
    this.teamsChanged.emit({
      teamIds: this.selectedTeamIds,
      labels: this.selectedTeamIds
        .map(id => (this.teamOptions.find(opt => Number(opt.id) === id) || EMPTY_TEAM).name)
        .filter(name => !!name)
    });
  }

  private sort(): void {
    this.teamOptions.sort((u1, u2) => Number(u1.id) - Number(u2.id));
  }

  /**
   * Fetches for the list of teams
   *
   * If {leagueIds} is set, then {withFilter} is ignored.
   *
   * @param leagueIds array of leagues to search the teams on
   * @param withFilter returns leagues with players
   */
  private search(leagueIds: number[], withFilter = false) {
    delete this.searchingTimeout;
    this.loading = true;
    this.searching.emit(true);
    this.teamsService.list({ withFilter, league_id: leagueIds.length > 0 ? leagueIds.join(',') : undefined })
      .subscribe(teams => {
        this.loading = false;
        this.teamOptions = Object.keys(teams).map(id => ({ id: Number(id), name: this.getTeamLabel(teams[id]) }));
        this.sort();
        this.searching.emit(false);
      });
  }

}
