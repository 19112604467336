/* Copyright © 2022-2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { BaseRequestorService } from '../baseRequestor.service';

import { ValidationResponse } from '../../server/shared/validation/validator';
import { Correlations, CorrelationInterface } from '../../server/shared/models/gsModel';
import { CorrelationValidator } from '../../server/shared/validation/correlationValidator';

@Injectable()
export class CorrelationsService extends BaseRequestorService<any, any> {
  private correlationValidator = new CorrelationValidator();  // TODO: should be injected

  constructor(protected http: HttpClient) {
    super('correlations', http, 10000);
  }

  public validate(id: string, model: Correlations): ValidationResponse {
    return this.correlationValidator.validate(id, model);
  }
}
