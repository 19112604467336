/* Copyright © 2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Validator, ValidationResponse } from './validator';
import { TeamDesignator } from '../models/teamDesignator';

export class TeamDesignatorValidator extends Validator<TeamDesignator> {
  public validate(id: string | number, instance: TeamDesignator): ValidationResponse {
    let errors = [];
    if (!id) {
      errors.push('Must supply a league.');
    }

    if (!instance) {
      errors.push('Must supply team designator info.');
    }

    if (Number(id) !== Number(Object.keys(instance)[0])) {
      errors.push(`Team designator is invalid. ${id} does not match ${Object.keys(instance)[0]}`);
    }

    if (errors.length) {
      return {
        success: false,
        reasons: errors
      };
    }
    return {
      success: true
    };
  }

  }
