/* Copyright © 2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, Input, OnInit } from '@angular/core';

import { toArray, dup } from 'gs-utils/lib/utilities';

import { ValidationResponse } from '../../server/shared/validation/validator';
import { ExpireTimeInterface } from '../../server/shared/models/expireTime';

import { FilterListPipe } from '../filters/filterListPipe.filter';

import { ExpireTimesService } from './expireTimes.service';

type ExpireTimeHourInterface = Partial<ExpireTimeInterface> & { hours: string };

@Component({
  selector: 'expireTimes',
  templateUrl: 'expireTimes/expireTimes.component.html',
  styles: [
    require('./expireTimes.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe]
})
export class ExpireTimesComponent implements OnInit {
  protected newType: 'expire' | 'final';
  protected newSport: string;
  protected newHours: string;
  protected showAddDiv = false;
  protected types = this.expireTimesService.types;
  protected expireTimes: ExpireTimeInterface[] = [];
  protected activeType: string;

  private pendingChanges = {};

  constructor(private expireTimesService: ExpireTimesService) { /**/ }

  public ngOnInit(): void {
    this.activeType = this.types[0];
    this.expireTimesService.list().subscribe(expireTimes => {
      this.handleExpireTimes(expireTimes);
    }, err => console.error(err));
  }

  protected validate(expireTimeName: string, expireTime: ExpireTimeInterface): ValidationResponse {
    if (expireTimeName && this.types.find(t => t === expireTime.type) && expireTime.sport && (expireTime.seconds > 0)) {
      return { success: true, reasons: [''] };
    }
    return { success: false, reasons: ['Invalid args'] };
  }

  protected ready(): boolean {
    return !!(this.expireTimes && this.activeType);
  }

  protected prettifyExpire(seconds: number): string {
    return (seconds / 3600).toFixed(2);
  }

  protected reset() {
    this.pendingChanges = {};
  }

  protected changeExpireTimes(obj: any, expire: ExpireTimeInterface) {
    let uniqueKey = `${expire.type}:${expire.sport}`;
    this.pendingChanges[uniqueKey] = { ...dup(expire), hours: obj.target.value };
  }

  protected saveNewExpire() {
    let obj = { sport: this.newSport, type: this.newType, hours: this.newHours };
    if (this.isValid(obj)) {
      this.saveExpire([obj]);
    }
    this.resetAddNew();
  }

  protected finalizeExpires() {
    let failures = [];
    let toSave = [];
    Object.keys(this.pendingChanges).map(k => this.pendingChanges[k]).forEach(expire => {
      if (this.isValid(expire)) {
        toSave.push(expire);
      } else {
        failures.push(JSON.stringify(expire));
      }
    });
    if (toSave.length) {
      this.saveExpire(toSave);
    }
    if (failures.length) {
      alert(`FAILURES: ${failures.join('\n')}`);
    }
  }

  protected toggleAddNew() {
    if (!this.showAddDiv) {
      this.resetAddNew();
    }
    this.showAddDiv = !this.showAddDiv;
  }

  private resetAddNew() {
    this.newSport = undefined;
    this.newType = undefined;
    this.newHours = undefined;
  }

  private isValid(expire: ExpireTimeHourInterface): boolean {
    return expire.sport && expire.type && expire.hours && Number(expire.hours) > 0;
  }

  private saveExpire(expires: ExpireTimeHourInterface[]) {
    this.expireTimesService.save(
      'expire', expires.map(e => (e.seconds = +e.hours * 3600, e))
    ).subscribe(expireTimes => {
      this.handleExpireTimes(expireTimes);
    }, err => console.error(err));
    this.expireTimes = [];
  }

  private handleExpireTimes(expireTimes) {
    Object.keys(expireTimes).forEach(sport => {
      this.types.forEach((type: 'expire' | 'final') => {
        if (expireTimes[sport][type]) {
          this.expireTimes.push({ type, seconds: Number(expireTimes[sport][type]), sport });
        }
      });
    });
    this.sort();
  }

  private sort(): void {
    /**/
  }
}
