/* Copyright © 2017-2018 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseRequestorService } from '../baseRequestor.service';
import { Offertypes } from '../../server/shared/models/gsModel';
import { OffertypeInterface } from '../../server/shared/models/offertype';
import { OffertypeValidator } from '../../server/shared/validation/offertypeValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';


@Injectable()
export class OffertypesService extends BaseRequestorService<OffertypeInterface, Offertypes> {
  public source: string;
  private offertypeValidator = new OffertypeValidator();  // TODO: should be injected

  constructor(protected http: HttpClient) {
    super('offertypes', http);
  }

  public validate(id: string, model: OffertypeInterface): ValidationResponse {
    return this.offertypeValidator.validate(id, model);
  }
}
