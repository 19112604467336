/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseRequestorService } from '../baseRequestor.service';
import { Group, Groups } from '../../server/shared/models/group';
import { GroupValidator } from '../../server/shared/validation/groupValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';

@Injectable()
export class GroupsService extends BaseRequestorService<Group, Groups> {
  private groupValidator = new GroupValidator();  // TODO: should be injected

  constructor(protected http: HttpClient) {
    super('groups', http);
  }

  public validate(id: string, model: Group): ValidationResponse {
    return this.groupValidator.validate(id, model);
  }
}
