/* Copyright © 2017-2018 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { toArray } from 'gs-utils/lib/utilities';

import { StandardPoints } from '../../server/shared/models/standardPoints';
import { ValidationResponse } from '../../server/shared/validation/validator';
import { StandardPointsValidator } from '../../server/shared/validation/standardPointsValidator';
import { FilterListPipe } from '../filters/filterListPipe.filter';

import { StandardPointsService } from './standardPoints.service';

@Component({
  selector: 'standardPoints',
  templateUrl: 'standardPoints/standardPoints.component.html',
  styles: [
    require('./standardPoints.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe]
})

export class StandardPointsComponent implements OnInit {
  @Input()
  protected source: string;
  protected standardPoints: StandardPoints[] = [];

  private standardPointForm: FormGroup;
  private validator = new StandardPointsValidator();
  private formMessages: Record<string, string> = {};
  private hasError: Record<string, boolean> = {};

  constructor(private fb: FormBuilder, private standardPointsService: StandardPointsService) { /**/ }

  public ngOnInit(): void {
    this.standardPointsService.source = this.source;
    this.standardPointsService.list().subscribe(standardPoints => {
      Object.keys(standardPoints).forEach(standardPointId => {
        this.standardPoints.push(...toArray(standardPoints[standardPointId]));
      });
      this.sort();
      this.buildForm(this.standardPoints);
    }, err => console.error(err));
    this.buildForm([]);
    this.standardPointsService.source = '';
  }

  protected validate(standardPointName: string, standardPoints: StandardPoints): ValidationResponse {
    return this.validator.validate(standardPointName, standardPoints);
  }

  protected buildForm(standardPoints: StandardPoints[]): void {
    this.standardPoints = standardPoints;
    let fields: any = { };
    this.standardPoints.forEach(standardPoint => {
      fields[`${standardPoint.id}-name`] = [ standardPoint.id, [Validators.required] ];
    });
    fields['standard-points-filter'] = ['', []];
    this.standardPointForm = this.fb.group(fields);
  }

  private sort(): void {
    this.standardPoints.sort((u1, u2) => u1.sport < u2.sport ? -1 : 1);
  }
}
