/* Copyright © 2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component } from '@angular/core';

@Component({
  selector: 'gs-admin-app',
  templateUrl: 'app/app.component.html',
  styles: [require('./app.component.scss')] // tslint:disable-line
})
export class AppComponent {}
