/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'add-sport',
  templateUrl: 'sports/addSport.component.html',
  styles: [
    require('./addSport.component.scss')  // tslint:disable-line
  ]
})
export class AddSportComponent {
  public sportNameControl: FormControl = new FormControl('', [Validators.required]);
  public error = '';

  constructor() {/** */}

  public onClose() {
    if (this.sportNameControl.valid) {
      return [null, {
        name: this.sportNameControl.value
      }];
    } else {
      const ALERT_TIMER = 2500;
      this.error = 'Please enter the sport name';
      setTimeout(() => this.error = '', ALERT_TIMER);
      return [true];
    }
  }
}
