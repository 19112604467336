/* Copyright © 2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseRequestorService } from '../baseRequestor.service';
import { ExpireTimeInterface, ExpireTimes } from '../../server/shared/models/expireTime';
import { ValidationResponse } from '../../server/shared/validation/validator';


@Injectable()
export class ExpireTimesService extends BaseRequestorService<any, ExpireTimes> {

  public types = ['expire', 'final'];

  constructor(protected http: HttpClient) {
    super('expireTimes', http);
  }

  public validate(id: string, model: ExpireTimeInterface): ValidationResponse {
    if (id && this.types.find(t => t === model.type) && model.sport && (model.seconds > 0)) {
      return { success: true, reasons: [''] };
    }
    return { success: false, reasons: ['Invalid args'] };
  }
}
