/* Copyright © 2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

interface Success {
  username: string;
  success: boolean;
}

export interface LogInResponse {
  fetching: boolean;
  error: boolean;
  username?: string;
  isLoggedIn?: boolean;
}

@Injectable()
export class LoginService {
  private loggedIn: BehaviorSubject<LogInResponse> = new BehaviorSubject<LogInResponse>({
    fetching: true,
    error: false
  });

  constructor(protected http: HttpClient, private router: Router) {
    this.fetchForCurrentSession();
  }

  public get isLoggedIn(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.loggedIn.subscribe(loggedInStatus => {
        if (!loggedInStatus.fetching) {
          if (loggedInStatus.isLoggedIn) {
            resolve(true);
          } else {
            reject(loggedInStatus.error);
          }
        }
      });
    });
  }

  public get loggedInStatus() {
    return this.loggedIn.asObservable();
  }

  public logout() {
    this.loggedIn.next({ fetching: true, error: false });
    this.http.get('/api/logout').subscribe(
      () => {
        this.loggedIn.next({ fetching: false, username: null, isLoggedIn: false, error: false });
        this.router.navigate(['login'], { queryParams: { error: 'logout-success' } });
      },
      err => this.handleLoginError(err)
    );
  }

  public fetchForCurrentSession() {
    this.http.get('/api/login').subscribe(
      (response: Success) => this.handleLoginSuccess(response),
      err => this.handleLoginError(err)
    );
  }

  public login(user: string, pass: string) {
    this.loggedIn.next({ fetching: true, error: false });

    let headers = new HttpHeaders({
      'x-gs-username': user,
      'x-gs-password': pass
    });

    this.http.get('/api/login', { headers }).subscribe(
      (response: Success) => this.handleLoginSuccess(response),
      err => {
        console.log(err); //tslint:disable-line
        this.handleLoginError(err);
        this.router.navigate(['login'], { queryParams: err });
      }
    );
  }

  private handleLoginSuccess(s: Success) {
    if (s.success) {
      this.loggedIn.next({ fetching: false, username: s.username, isLoggedIn: true, error: false });
    } else {
      this.handleLoginError();
    }
  }

  private handleLoginError(err?) {
    this.loggedIn.next({ fetching: false, isLoggedIn: false, error: err });
  }
}
