/* Copyright © 2023 Ganchrow Scientific, SA all rights reserved */

'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface RotationChangeInterface {
  fromRotation: number;
  toRotation: number;
  startTimestamp: Date;
  creationTimestamp: Date;
}

const ROTATION_CHANGES_PATH = '/api/rotationChanges';

@Injectable()
export class RotationChangesService {
  constructor(protected http: HttpClient) {}

  public listChanges(): Observable<RotationChangeInterface[]> {
    return this.http.get(ROTATION_CHANGES_PATH).pipe(
      map((json: any[]) => {
        return json.map((item) => ({
          fromRotation: item[0],
          toRotation: item[1],
          startTimestamp: new Date(item[2]),
          creationTimestamp: new Date(item[3]),
        }));
      })
    );
  }

  public deleteRotation(rotation: number): Observable<any> {
    return this.http.get(`${ROTATION_CHANGES_PATH}/${rotation}`);
  }
}
