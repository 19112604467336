/* Copyright © 2021-2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EventSourcesService } from './eventSources.service';
import { RequestLeagueIdService } from './requestLeagueId.service';
import { RequestOutrightLeagueIdService } from './requestOutrightLeagueId.service';

@Component({
  selector: 'request-league',
  templateUrl: 'events/requestLeague.component.html',
  styles: [
    require('./requestLeague.component.scss')  // tslint:disable-line
  ]
})
export class RequestLeagueComponent {
  public isProcessing = false;
  public eventSources: [string, string][] = [];
  private canConfirmSubject = new BehaviorSubject(true);
  private requestLeagueSources: Record<string, number> = {};

  constructor(
    private eventSourcesService: EventSourcesService,
    protected requestLeagueIdService: RequestLeagueIdService,
    protected requestOutrightLeagueIdService: RequestOutrightLeagueIdService
  ) { }

  public ngOnInit() {
    this.eventSourcesService.list().subscribe(e => this.eventSources = e);
  }

  public get canConfirm() {
    return this.canConfirmSubject.asObservable();
  }

  protected isAllowed(source) {
    return ['kibl', 'bet_genius', 'bet-genius', 'lsports'].includes(source);
  }

  protected requestLeagueSourcesRecord(source: string, $event) {
    this.requestLeagueSources[source] = $event.target.value;
  }

  protected requestLeagueId(source: string, $event) {
    if (this.requestLeagueSources[source]) {
      let isOutright = this.findIsOutrightChecked($event, source, 'league');
      let key = `request${ isOutright ? 'Outright' : '' }LeagueIdService`;
      this[key].fetch(`${source}/${this.requestLeagueSources[source]}`).subscribe(() => {
        alert(`${source} id ${this.requestLeagueSources[source]} requested!`);
      });
    }
  }

  private findIsOutrightChecked($event: any, source: string, kind: 'event' | 'league'): boolean {
    if (source === 'lsports') {
      let supplemental = kind === 'league' ? 'league--' : '';
      return $event.target.parentElement.parentElement.querySelector(`#eventSources--${supplemental}${source}--outright`).checked;
    }
  }
}
