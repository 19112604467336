/* Copyright © 2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OffertypeListService } from './offertypeList.service';
import { Observable, combineLatest, map } from 'rxjs';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

const SELF = '/offertypeList';

@Component({
  selector: 'offertypeList',
  templateUrl: 'offertypes/offertypeList.component.html',
  styles: [
    require('./offertypes.component.scss'), // tslint:disable-line
  ],
  providers: [OffertypeListService],
})
export class OffertypeListComponent implements OnInit {
  protected offertypeList$: Observable<[string, string][]>;
  protected activeOffertypeList: string;

  constructor(private offertypeListService: OffertypeListService, private state: ActivatedRoute, private router: Router) {}

  public ngOnInit(): void {
    this.offertypeList$ = combineLatest([this.offertypeListService.list(), this.state.params]).pipe(
      map(([list, params]) => {
        this.activeOffertypeList = list.find((o) => o[0] === params.type) ? params.type : null;
        return list;
      })
    );
  }

  protected navigate({ value }: MatButtonToggleChange): void {
    this.router.navigate([SELF, { type: value }]);
  }

  protected capitalize(str: string): string {
    return str
      .split('-')
      .map((s) => s.toLowerCase().replace(/^[a-z]/, (s[0] || '').toUpperCase()))
      .join('-');
  }
}
