/* Copyright © 2023-2024 Ganchrow Scientific, SA all rights reserved */
'use strict';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { LogInResponse, LoginService } from '../login/login.service';
import { ServerEnvironmentService } from '../serverEnvironment.service';

const TITLE = 'GS ADMIN';

@Component({
  selector: 'content-wrapper',
  templateUrl: 'contentWrapper/contentWrapper.component.html',
  styles: [require('./contentWrapper.component.scss')], // tslint:disable-line
  providers: [ServerEnvironmentService]
})
export class ContentWrapperComponent implements OnInit {
  protected title = TITLE;
  protected loggedInStatus$: Observable<LogInResponse>;

  constructor(private loginService: LoginService, private serverEnvironmentService: ServerEnvironmentService<{ flavor?: string }>) {
    this.loggedInStatus$ = this.loginService.loggedInStatus;
  }

  public ngOnInit() {
    this.serverEnvironmentService.request().subscribe(env => {
      if (env.flavor) {
        this.title = `${TITLE} ${env.flavor.toUpperCase()}`;
      }
    });
  }

  protected logout(): void {
    this.loginService.logout();
  }
}
