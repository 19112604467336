/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */

import { NG_ASYNC_VALIDATORS, AsyncValidator, AbstractControl, ValidationErrors } from '@angular/forms';
import { ScoringRulesService } from './scoringRules.service';
import { Observable } from 'rxjs';
import { Directive, Input } from '@angular/core';
import { first, map } from 'rxjs/operators';

@Directive({
  selector: '[ruleNameValidator]',
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting: RuleNameAsyncValidatorDirective, multi: true }
  ]
})
export class RuleNameAsyncValidatorDirective implements AsyncValidator {
  @Input() private ruleKey: string;

  constructor(private scoringRulesService: ScoringRulesService) { }

  public validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.doValidation(control);
  }

  private doValidation(control: AbstractControl) {
    return this.scoringRulesService.list()
    .pipe(
      map(rules => {
        let doesNameExists = Object.keys(rules || {}).find(key => rules[key].name === control.value && key !== this.ruleKey);
        if (doesNameExists) {
          return { ruleNameAsyncValidator: 'Rule name duplicated, choose a new name or edit the current rule.' };
        } else {
          return null;
        }
      }),
      first()
    );
  }
}
