/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'page-header',
  templateUrl: 'page-header/page-header.component.html',
  styles: [
    require('./page-header.component.scss')  // tslint:disable-line
  ]
})
export class PageHeaderComponent {
  @Input() public pageTitle = '';
}
