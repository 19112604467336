/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'add-league',
  templateUrl: 'leagues/add-league.component.html',
  styles: [
    require('./add-league.component.scss')  // tslint:disable-line
  ]
})
export class AddLeagueComponent {
  public leagueNameControl: FormControl = new FormControl('', [Validators.required]);
  public error = '';
  private selectedSport;

  constructor() {/** */}

  public selectSport(sport) {
    if (sport.sportIds.length > 0) {
      this.selectedSport = this.extractSportName(sport.labels[0]);
    } else {
      this.selectedSport = null;
    }
  }

  public onClose() {
    if (this.leagueNameControl.valid && this.selectedSport) {
      return [null, {
        name: this.leagueNameControl.value,
        sport_id: this.selectedSport
      }];
    } else {
      const ALERT_TIMER = 2500;
      this.error = 'Please select a sport and enter the league name';
      setTimeout(() => this.error = '', ALERT_TIMER);
      return [true];
    }
  }

  private extractSportName(sportLabel) {
    return sportLabel.split(':')[1].trim();
  }
}
