/* Copyright © 2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { isString } from 'gs-utils/lib/utilities';

import { BaseRequestorService } from '../baseRequestor.service';
import { ValidationResponse } from '../../server/shared/validation/validator';
import { TeamDesignatorValidator } from '../../server/shared/validation/teamDesignatorValidator';
import { TeamDesignator } from '../../server/shared/models/teamDesignator';

@Injectable()
export class TeamDesignatorService extends BaseRequestorService<TeamDesignator, any> {

  private teamDesignatorValidator = new TeamDesignatorValidator();

  constructor(protected http: HttpClient) {
    super('teamsDesignator', http);
  }

  public validate(id: string, model: TeamDesignator): ValidationResponse {
    return this.teamDesignatorValidator.validate(id, model);
  }
}
