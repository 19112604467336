/* Copyright © 2018-2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseRequestorService } from '../baseRequestor.service';

export interface PartnerInterface {
  address: string;
  name: string;
}

@Injectable()
export class PartnersService extends BaseRequestorService<any, [string, string][]> {
  constructor(protected http: HttpClient) {
    super('partners', http);
  }

  public parsePartners(partners: [string, string][]): PartnerInterface[] {
    return partners.map(partner => ({ address: partner[0], name: partner[1] }));
  }
}
