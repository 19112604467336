/* Copyright © 2017-2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { ScoreInterface } from 'gs-templates/lib/score';

import { ScoreValidator } from '../../server/shared/validation/scoreValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';

import { ScoresService } from './scores.service';
import { FilterListPipe } from '../filters/filterListPipe.filter';
import { DateService } from '../filters/date.service';
import { dup } from 'gs-utils/lib/utilities';

const MESSAGE_TIMEOUT = 3000;

const FINISHED_STATUS = '3';

@Component({
  selector: 'scores',
  templateUrl: 'scores/scores.component.html',
  styles: [
    require('./scores.component.scss')  // tslint:disable-line
  ]
})
export class ScoresComponent implements OnInit {
  @Input() private key: string;

  private isSuccess: boolean;
  private isFailure: boolean;
  private scoreValidator = new ScoreValidator();
  private score: ScoreInterface;
  private originalScore: ScoreInterface;
  private markGameAsComplete = false;

  constructor(private scoresService: ScoresService, private dateService: DateService, /*private cd: ChangeDetectorRef*/) { /**/ }

  public ngOnInit(): void {
    this.scoresService.fetch(this.key).subscribe(score => {
      this.score = this.postProcessScore(this.originalScore = score);
    }, () => {
      this.score = this.postProcessScore(this.originalScore = this.generateInitialScore());
    });
  }


  public ngAfterViewChecked(): void {
    if (this.score) {
      let newPeriod = Array.isArray(this.score.period_scores) ? this.score.period_scores.length : 0;

      if (newPeriod !== this.score.period) {
        // abides by angular's rule to avoid making model changes in the same tick as they are being checked
        setTimeout(() => {
          this.score.period = newPeriod;
        }, 10);
      }
    }
  }

  protected get rawScore() {
    return this.score ? JSON.stringify(this.score, null, 2) : '{}';
  }

  protected reset() {
    this.score = dup(this.originalScore);
  }

  protected save() {
    if (this.validate().success) {
      if (this.markGameAsComplete) {
        this.score.status = FINISHED_STATUS;
      }
      this.dateService.stampModel(this.score);
      this.scoresService.save(this.key, this.score).subscribe(score => {
        this.score = this.postProcessScore(this.originalScore = score);
        this.isSuccess = true;
        this.scheduleRemoveMessage();
      }, () => {
        this.isFailure = true;
        this.scheduleRemoveMessage();
      });
    } else {
      this.isFailure = true;
      this.scheduleRemoveMessage();
    }
  }

  protected getValidationText() {
    let response = this.validate();
    return response.reasons;
  }

  protected isValid() {
    return this.validate().success;
  }

  protected addPeriodScore() {
    this.score.period_scores.push([]);
  }

  protected removePeriodScore() {
    this.score.period_scores.pop();
  }

  private validate(): ValidationResponse {
    return this.scoreValidator.validate(this.key, this.score);
  }

  private postProcessScore(origScore: ScoreInterface): ScoreInterface {
    let newScore = dup(origScore);
    newScore.period_scores = Array.isArray(newScore.period_scores) ? newScore.period_scores : [];
    delete newScore.current_period_score;
    return newScore;
  }

  private scheduleRemoveMessage() {
    setTimeout(() => {
      this.isFailure = false;
      this.isSuccess = false;
    }, MESSAGE_TIMEOUT);
  }

  private generateInitialScore(): ScoreInterface {
    return this.dateService.stampModel({
      period_scores: [[]],
      rotation: Number(this.key) || 0,
      timestamp: 0
    } as any);
  }
}
