/* Copyright © 2018-2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TeamInterface } from '../../server/shared/models/team';

import { BaseRequestorService } from '../baseRequestor.service';
import { Teams, AdminLeagueInterface } from '../../server/shared/models/gsModel';
import { TeamValidator } from '../../server/shared/validation/teamValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';
import { Subject } from 'rxjs';

export const EMPTY_TEAM = {
  label: '', name: ''
};


@Injectable()
export class TeamsService extends BaseRequestorService<TeamInterface, Teams> {
  private teamValidator = new TeamValidator();  // TODO: should be injected
  private teams: Subject<any> = new Subject<any>();
  private filters: Subject<any> = new Subject<any>();
  private loadingTeams: Subject<any> = new Subject<any>();

  constructor(protected http: HttpClient) {
    super('teams', http);
  }

  public validate(id: string, model: TeamInterface): ValidationResponse {
    return this.teamValidator.validate(id, model);
  }

  public filterTeams(filterVal) {
    this.filters.next(filterVal);
  }

  get isLoadingTeams() {
    return this.loadingTeams.asObservable();
  }

  get teamFilter() {
    return this.filters.asObservable();
  }

  get searchedTeams() {
    return this.teams.asObservable();
  }

  public getTeamsForLeagues(leagues: { id: number; teamIds?: number[]; }[], teamName?: string) {
    this.loadingTeams.next(true);
    let payload: any = { league_id: leagues.map(lg => lg.id), ids: leagues.map(lg => lg.teamIds) };
    if (teamName) {
      payload.name = teamName;
    }
    this.list(payload).subscribe(teams => {
      this.loadingTeams.next(false);
      this.teams.next(teams);
    });
  }
}
