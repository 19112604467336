/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EventInterface } from 'gs-templates/lib/event';
import { BehaviorSubject } from 'rxjs';
import { DateService } from '../filters/date.service';
import { EventsService } from './events.service';

@Component({
  selector: 'add-event',
  templateUrl: 'events/addEvent.component.html',
  styles: [
    require('./addEvent.component.scss')  // tslint:disable-line
  ]
})
export class AddEventComponent implements OnInit {
  public eventTeam1Control: FormControl = new FormControl('', [Validators.required]);
  public eventTeam2Control: FormControl = new FormControl('', [Validators.required]);
  public leagueControl: FormControl = new FormControl('', [Validators.required]);
  public sportControl: FormControl = new FormControl('', [Validators.required]);
  public dateControl: FormControl = new FormControl('', [Validators.required]);
  public error;
  public success;
  public isProcessing = false;
  private eventGroup = new FormGroup({
    team1: this.eventTeam1Control,
    team2: this.eventTeam2Control,
    league: this.leagueControl,
    date: this.dateControl,
    sport: this.sportControl
  });
  private canConfirmSubject = new BehaviorSubject(true);

  constructor(public eventsService: EventsService, public dateService: DateService) {
    this.eventGroup.statusChanges.subscribe(() => this.updateConfirm());
  }

  public ngOnInit() {
    this.updateConfirm();
  }

  public get canConfirm() {
    return this.canConfirmSubject.asObservable();
  }

  public async onClose() {
    this.isProcessing = true;
    this.updateConfirm();
    let result;
    try {
      result = await this.eventsService.create(this.buildPayload()).toPromise();
      this.success = `Rotation: ${result.rotation}`;
    } catch (e) {
      this.error = e.error;
    }
    this.isProcessing = false;
    this.updateConfirm();
    setTimeout(() => {
      this.error = undefined;
      this.success = undefined;
    }, 5000);
    return result;
  }

  public handleDateChange(date) {
    this.dateControl.setValue(date);
  }

  public handleLeagueChange({ league: leagueSelected }) {
    this.leagueControl.setValue(leagueSelected?.id ?? null);
    this.sportControl.setValue(leagueSelected?.sport_id ?? null);
  }

  private updateConfirm() {
    this.canConfirmSubject.next(this.eventGroup.valid && !this.isProcessing);
  }

  private buildPayload(): Partial<EventInterface> {
    const baseEvent = {
      teams: [this.eventTeam1Control.value, this.eventTeam2Control.value],
      start_date: +new Date(this.dateControl.value),
      league_id: this.leagueControl.value,
      sport_id: this.sportControl.value,
      feed_source: ''
    };

    return this.dateService.stampModel(baseEvent);
  }
}
