/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EventInterface } from 'gs-templates/lib/event';

import { BaseRequestorService } from '../baseRequestor.service';
import { Events } from '../../server/shared/models/gsModel';

@Injectable()
export class EventsService extends BaseRequestorService<EventInterface, Events> {
  constructor(protected http: HttpClient) {
    super('events', http);
  }
}
