/* Copyright © 2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Observable, map } from 'rxjs';

export interface Results {
  success: number;
  error?: string;
  pager?: Pager;
  results?: Event[];
}

interface Pager {
  page: number;
  per_page: number;
  total: number;
}

export interface Event {
  Id: number;
  SportId: number;
  SportName: string;
  RegionId: number;
  RegionName: string;
  LeagueId: number;
  LeagueName: string;
  BetRadarId: number;
  Date: string;
  HomeTeam: string;
  HomeTeamShort: string;
  AwayTeam: string;
  AwayTeamShort: string;
  Scoreboard: Scoreboard;
  IsOutright: false;
  updated_at: string;
  isPreMatch?: boolean;
  Markets?: PrematchMarket[];
  optionMarkets?: PrematchMarket[];
  our_event_id?: number;
}

export interface Item {
  Ordinal: number;
  MessageType: number;
  Content: string;
  Timer: string;
}

export interface Messages {
  Items: Item[];
}

export interface Scoreboard {
  periodId: number;
  Messages: Messages;
  EventId: Event['Id'];
  Timer: Timer;
  SportId: Event['SportId'];
  Score: PeriodScores;
  period?: string;
  Period: {
    ParentPeriod: number;
    Visible: number;
    Id: number;
  };
}

export interface Timer {
  Visible: number;
  Seconds: number;
  ReferenceDate: number;
  Running: number;
}

export interface PeriodScores {
  Team1: PeriodScore;
  Team2: PeriodScore;
  Visible: number;
  id?: string;
}

export interface PeriodScore {
  Counters: Counter[];
}

export interface Counter {
  Value: number;
  id: number;
  Visible: boolean;
  PeriodId: number;
}

export interface PrematchMarket {
  status?: string;
  id?: number;
  options?: PrematchMarketResult[];
  visibility: string;
  grouping: Grouping;
  results: PrematchMarketResult[];
  name: GenericName;
  resultOrder: string;
  combo2: string;
  templateId: number;
  templateCategory: TemplateCategory;
  category: string;
  parameters: Parameters[];
  attr?: string;
  player1?: {
    value: string;
    short: string;
  };
  player2?: {
    value: string;
    short: string;
  };
}

interface PrematchMarketPrice {
  odds: number;
  americanOdds: number;
  id: number;
  denominator: number;
  numerator: number;
}

export interface PrematchMarketResult extends PrematchMarketPrice {
  price?: PrematchMarketPrice;
  status?: string;
  sourceName: GenericName;
  name: GenericName;
  attr?: string;
  visibility?: string;
}

export interface Grouping {
  categoryId: number;
  combo1: string;
  gridGroups: any;
}

export interface GenericName {
  value: string;
  sign: string;
}

export interface TemplateCategory {
  name: GenericName;
  isMain: boolean;
  id: number;
  category: string;
}

export interface Market {
  Results: SingleMarket[];
  isMainBook?: boolean;
  Name: string;
  GameTemplateId: number;
}

export interface SingleMarket {
  Name: string;
  GameTemplateId: number;
  Id: number;
  Odds: number;
}

export interface Parameters {
  key: string;
  type: string;
  value: string;
}

export class BWinTemplateManagement {
  private static url = 'https://api.b365api.com/v1/bwin/event?token=13291-rdebYGh8BcO5tg&event_id=';

  public static formatResult(result: Observable<Results>): Observable<{ id: string; name: string }[]> {
    return result.pipe(
      map((res) =>
        res.results.flatMap((m) => {
          if (m.optionMarkets && m.optionMarkets.length) {
            return m.optionMarkets.map((o) => {
              const id = o.parameters.find((p) => p.key === 'TemplateId').value;
              const name = o.name.value;
              return { name: `${id}:${name}`, id };
            });
          }
          return m.Markets.map((o) => {
            const id = `${o.templateId}`;
            const name = o.name.value;
            return { name: `${id}:${name}`, id };
          });
        })
      )
    );
  }

  public static goToEvent(eventId: number | string): void {
    window.open(`${this.url}${eventId}`, '_blank');
  }
}
