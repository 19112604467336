/* Copyright © 2017-2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { LeagueValidator } from '../../server/shared/validation/leagueValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';
import { FilterListPipe } from '../filters/filterListPipe.filter';
import { AdminLeagueInterface } from '../../server/shared/models/gsModel';
import { TeamDesignator } from '../../server/shared/models/teamDesignator';
import { Clipboard } from '@angular/cdk/clipboard';
import { LeaguesService } from './leagues.service';
import { SportsService } from '../sports/sports.service';
import { CreateTeamService } from '../teams/createTeam.service';
import { SportIdByNameService } from '../leagues/sportIdByName.service';
import { TeamDesignatorService } from '../teams/teamDesignator.service';
import { DialogService } from '../dialog/dialog.service';
import { AddLeagueComponent } from './add-league.component';
import { AgGridAngular } from 'ag-grid-angular';
import { RowDragEndEvent, CellValueChangedEvent, ColDef } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import agGridLicense from '../agGridLicense';

interface UILeague {
  id: number;
  name: string;
  shortName: string;
  platformOrder: number;
  platformPeriod: string;
  platformMarkets: string;
  marketNames: string;
  periodNames: string;
  mappings: string[];
  raw: string;
  twitchChannel: string;
  youtubeChannel: string;
  declaration?: string;
  class?: string;
  selectedSportIds: number[];
  showLeagueStream?: boolean;
  overrideLeague?: boolean;
  blocked?: boolean;
  sportId?: number;
  iconName?: string;
}

@Component({
  selector: 'leagues',
  templateUrl: 'leagues/leagues.component.html',
  styles: [
    require('./leagues.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe]
})

export class LeaguesComponent implements OnInit {
  public creatingLeague = false;

  protected leagues: UILeague[] = [];
  protected sportOptions: { id: number; name: string }[];

  @ViewChild('notifier') private notifier: ElementRef;
  private excludedLeagues: UILeague[] = [];
  private leagueForm: FormGroup;
  private validator = new LeagueValidator();
  private formMessages: Record<string, string> = {};
  private hasError: Record<string, boolean> = {};
  private activeLeague: UILeague = null;
  @ViewChild(AgGridAngular) protected agGrid: AgGridAngular;
  @ViewChild('actionMenu') protected actionMenu: ElementRef;
  @HostListener('document:keydown.escape', ['$event'])
  protected onKeydownHandler() {
    this.closeMenu();
  }
  @HostListener('document:click', ['$event'])
  protected clickout(event) {
    const leagueContainer = document.querySelector('.leagues');
    if (
      leagueContainer.contains((event.target)) &&
      !event.target.classList.contains('fa-edit') &&
      !this.actionMenu?.nativeElement.contains(event.target)) {
        this.closeMenu();
      }
  }
  protected onRowDragEnd(e: RowDragEndEvent) {
    let from = e && e.node && e.node.data;
    let to = e && e.overNode && e.overNode.data;
    if (from.id === to.id) {
      this.addMessage(to.id, 'Nothing to do');
    } else {
      if (confirm(`Copy all mappings from ${from.name} to ${to.name}?`)) {
        this.leaguesService.list({ from: from.id, to: to.id }).subscribe(lg => {
          if (lg[to.id]) {
            this.addMessage(to.id, `${from.name} successfully merged into ${to.name}`);
          } else {
            this.addMessage(to.id, `Error in merging ${from.name} into ${to.name}`, true);
          }
        }, err => {
          this.addMessage(to.id, String(err.message || err), true);
        });
      }
    }
  }

  protected isActionMenuOpen = false;
  protected columnDefs: ColDef[] = [
    ['leagueId', 'League ID', 'id', 'draggable'],
    ['leagueName', 'League name', 'name', 'draggable'],
    ['shortName', 'Short name'],
    ['sportId', 'Sport ID'],
    ['platformOrder', 'Platform order'],
    ['platformPeriod', 'Platform period'],
    ['marketNames', 'Market names'],
    ['periodNames', 'Period names'],
    ['platformMarkets', 'Platform Markets'],
    ['leagueClass', 'League class', 'class'],
    ['declaration', 'Declaration'],
  ].map(([field, headerName, key, rowDrag], i) => ({
    field,
    headerName,
    flex: 1,
    rowDrag: !!rowDrag,
    ...(key && { valueGetter: ({ data }) => data[key] }),
    ...(!i && { cellRenderer: 'agGroupCellRenderer'}),
  } as ColDef))
  .concat({
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    resizable: false,
    sortable: false,
    filter: false,
    cellRenderer: ({ data }) => {
      const span = document.createElement('span');
      span.classList.add('event-action-icon');
      span.innerHTML = '<i class="fas fa-edit"></i>';
      span.onclick = () => {
        this.isActionMenuOpen = true;
        this.activeLeague = data;
      };
      return span;
    }
  });
  protected defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };
  protected overlayLoadingTemplate = '<div class="loading"> &#1161</div>';
  protected detailCellRendererParams = {
    detailGridOptions : {
      columnDefs: [{
        field: 'mappings',
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: { maxLength: 2000 },
        minWidth: 550,
        flex: 1,
      }, {
        field: 'mappringsAction',
        headerName: 'Actions',
        cellRenderer: ({ data }) => {
          const span = document.createElement('span');
          const saveButton = document.createElement('button');
          saveButton.textContent = 'Save';
          const copyButton = document.createElement('button');
          copyButton.textContent = 'Copy';
          saveButton.classList.add('btn', 'btn-success');
          copyButton.classList.add('btn', 'btn-warning');
          const { id, raw } = data;
          const mappingsControl = this.getFormControl(id, 'mappings');
          saveButton.onclick = () => this.saveLeague( id, raw );
          copyButton.onclick = () => this.clipBoard.copy(mappingsControl.value);
          span.appendChild(copyButton);
          span.appendChild(saveButton);
          return span;
        }
      }],
      onCellValueChanged: this.onCellValueChanged.bind(this),
      enableCellTextSelection: true,
      ensureDomOrder: true,
      stopEditingWhenCellsLoseFocus: true
    },
    getDetailRowData: ({ successCallback, data }) => {
      successCallback([data]);
    },
  };

  constructor(private fb: FormBuilder, private leaguesService: LeaguesService,
    private createTeamService: CreateTeamService, private sportIdByNameService: SportIdByNameService, private router: Router,
    private state: ActivatedRoute, private teamDesignatorService: TeamDesignatorService, private clipBoard: Clipboard,
    private dialog: DialogService, private sportsService: SportsService) { /**/ }

  public async ngOnInit(): Promise<void> {
    this.buildForm([]);
    await this.getPlatformSports();
    this.state.params.subscribe((params: Params) => {
      let field = this.leagueForm.get('leagues-search');
      if (params.search && field.value !== params.search) {
        field.setValue(params.search.replace(/:[0-9]+$/, ''));
        this.searchForLeagues(params.search);
      }
    });
    LicenseManager.setLicenseKey(agGridLicense);

  }

  protected closeMenu(): void {
    this.isActionMenuOpen = false;
    this.activeLeague = null;
  }

  protected onCellValueChanged(params: CellValueChangedEvent) {
    const key = params.column.getId();
    if ( key === 'mappings' ) {
      const { id, mappings } = params.data;
      const mappingsControl = this.getFormControl(id, 'mappings');
      mappingsControl.setValue(mappings.replace(/,/g, '\n'));
    }
  }

  protected getFormControl( leagueId: number, key: string ) {
    const leagueForm = this.leagueForm.get(`${leagueId}-form`);
    return leagueForm.get(`${leagueId}-${key}`);
  }

  public searchForLeagues(search: string) {
    this.agGrid.api.showLoadingOverlay();
    this.leagues = [];
    this.excludedLeagues = [];
    this.updateUrl(search);
    this.leaguesService.list({ search, includeMappings: true }).subscribe(leagues => {
      Object.keys(leagues).forEach(leagueId => {
        let league = leagues[leagueId];
        this.leagues.push({
          id: Number(leagueId),
          name: league.name,
          shortName: league.short_name,
          platformOrder: league.platformOrder,
          platformPeriod: league.platformPeriod,
          platformMarkets: league.platformMarkets,
          marketNames: league.marketNames,
          periodNames: league.periodNames,
          mappings: league.mappings || [],
          raw: JSON.stringify(league, null, 2),
          twitchChannel: league.twitchChannel,
          youtubeChannel: league.youtubeChannel,
          selectedSportIds: league.platformSport ? [ league.platformSport ] : [],
          showLeagueStream: !!league.showLeagueStream,
          overrideLeague: !!league.overrideLeague,
          blocked: !!league.blocked,
          declaration: league.declaration,
          class: league.class,
          sportId: Number(league.sport_id),
          iconName: league.iconName
        });
      });
      this.leagues = [...this.sort(this.leagues)];
      this.buildForm(this.leagues);
      this.agGrid.api.hideOverlay();
    });
  }

  public createLeague() {
    this.dialog
      .confirm
      .body(AddLeagueComponent)
      .title('Creating a League')
      .open()
      .then(league => {
        this.creatingLeague = true;
        this.leaguesService
          .create(league)
          .subscribe(
            () => {
              this.showAlert(true, 'League created successfully');
              this.creatingLeague = false;
            },
            (err) => {
              this.showAlert(false, 'Must supply name and sport id');
              this.creatingLeague = false;
            }
          );
      })
      .catch(c => this.showAlert(false, 'League creation canceled'));
  }

  protected viewRaw(league) {
    let sportId = JSON.parse(league.raw).sport_id;
    this.dialog
      .alert
      .title(`League: ${league.name}`)
      .body(`${JSON.stringify({ sport_id: sportId, ...league }, (k, v) => ( k !== 'raw' ? v : undefined ), 2)}`)
      .asTextArea()
      .open();
  }

  protected saveLeague(leagueId, rawLeague) {
    let leagueObject: AdminLeagueInterface = JSON.parse(rawLeague);
    let {
      name,
      shortName,
      platformOrder,
      platformPeriod,
      platformMarkets,
      marketNames,
      periodNames,
      twitchChannel,
      youtubeChannel,
      mappings,
      declaration,
      showLeagueStream,
      overrideLeague,
      blocked, ...rest
    }: any = this.extractLeagueValues(leagueId);
    leagueObject.name = name;
    leagueObject.short_name = shortName || undefined;
    leagueObject.platformOrder = platformOrder || undefined;
    leagueObject.platformPeriod = platformPeriod || undefined;
    leagueObject.platformMarkets = platformMarkets || undefined;
    leagueObject.marketNames = marketNames || undefined;
    leagueObject.periodNames = periodNames || undefined;
    leagueObject.originalMappings = leagueObject.mappings;
    leagueObject.twitchChannel = twitchChannel || undefined;
    leagueObject.youtubeChannel = youtubeChannel || undefined;
    leagueObject.mappings = mappings.split('\n');
    leagueObject.declaration = declaration || undefined;
    leagueObject.class = rest.class || undefined;
    leagueObject.iconName = rest.iconName || undefined;
    leagueObject.platformSport = (this.leagues.find(league => league.id === leagueId).selectedSportIds || [])[0];
    leagueObject.showLeagueStream = showLeagueStream ? 1 : undefined;
    leagueObject.overrideLeague = overrideLeague ? 1 : undefined;
    leagueObject.blocked = !!blocked;
    this.doSaveLeagueChanges(leagueId, leagueObject);
  }

  protected saveTeam(newTeamName: string, leagueId: number) {
    this.createTeamService.save('', { league_id: +leagueId, name: newTeamName }).subscribe((result: any) => {
      if (!result.error) {
        this.addMessage(leagueId, `Team ${newTeamName} save`);
      }
    });
  }

  protected viewSport(raw: string) {
    this.router.navigate(['/sports', { search: `%id ${JSON.parse(raw).sport_id}` }]);
  }

  protected viewTeams(leagueId: string) {
    this.teamDesignatorService.fetch(leagueId).subscribe((td: TeamDesignator) => {
      this.router.navigate(['/teams', { leagueIds: td.designator, labels: `${td.designator}: ${td.name}` }]);
    });
  }

  protected viewDeclaration(leagueId: string) {
    this.searchForLeagues(`%id ${leagueId}`);
  }

  protected validate(leagueId: number, league: AdminLeagueInterface): ValidationResponse {
    return this.validator.validate(leagueId, league);
  }

  protected buildForm(leagues: UILeague[]): void {
    let originalSearch = this.leagueForm ? this.leagueForm.get('leagues-search').value : '';
    this.leagues = leagues;
    let fields: any = { };
    this.leagues.forEach(league => {
      let fieldsForLeague = {};
      fieldsForLeague[`${league.id}-name`] = [ league.name, [Validators.required] ];
      fieldsForLeague[`${league.id}-shortName`] = [ league.shortName, [] ];
      fieldsForLeague[`${league.id}-platformOrder`] = [ league.platformOrder, [] ];
      fieldsForLeague[`${league.id}-platformPeriod`] = [ league.platformPeriod, [] ];
      fieldsForLeague[`${league.id}-platformMarkets`] = [ league.platformMarkets, [] ];
      fieldsForLeague[`${league.id}-marketNames`] = [ league.marketNames, [] ];
      fieldsForLeague[`${league.id}-periodNames`] = [ league.periodNames, [] ];
      fieldsForLeague[`${league.id}-mappings`] = [ (league.mappings || []).join('\n'), [Validators.required] ];
      fieldsForLeague[`${league.id}-twitchChannel`] = [ league.twitchChannel, [] ];
      fieldsForLeague[`${league.id}-youtubeChannel`] = [ league.youtubeChannel, [] ];
      fieldsForLeague[`${league.id}-declaration`] = [ league.declaration, [] ];
      fieldsForLeague[`${league.id}-class`] = [ league.class, [] ];
      fieldsForLeague[`${league.id}-iconName`] = [ league.iconName, [] ];
      fieldsForLeague[`${league.id}-showLeagueStream`] = [ !!league.showLeagueStream, [] ];
      fieldsForLeague[`${league.id}-overrideLeague`] = [ !!league.overrideLeague, [] ];
      fieldsForLeague[`${league.id}-blocked`] = [ !!league.blocked, [] ];
      fields[`${league.id}-form`] = this.fb.group(fieldsForLeague);
    });

    fields['leagues-search'] = [originalSearch, []];
    this.leagueForm = this.fb.group(fields);
  }

  protected revertChanges(leagueId) {
    const league = this.leagues.find(u => (u.id === leagueId));
    const leagueForm = this.leagueForm.get(`${leagueId}-form`);
    const nameControl = leagueForm.get(`${leagueId}-name`);
    const shortNameControl = leagueForm.get(`${leagueId}-shortName`);
    const orderControl = leagueForm.get(`${leagueId}-platformOrder`);
    const periodControl = leagueForm.get(`${leagueId}-platformPeriod`);
    const marketsControl = leagueForm.get(`${leagueId}-platformMarkets`);
    const marketNamesControl = leagueForm.get(`${leagueId}-marketNames`);
    const periodNamesControl = leagueForm.get(`${leagueId}-periodNames`);
    const mappingsControl = leagueForm.get(`${leagueId}-mappings`);
    const twitchChannelControl = leagueForm.get(`${leagueId}-twitchChannel`);
    const youtubeChannelControl = leagueForm.get(`${leagueId}-youtubeChannel`);
    const showLeagueStreamControl = leagueForm.get(`${leagueId}-showLeagueStream`);
    const overrideLeagueControl = leagueForm.get(`${leagueId}-overrideLeague`);
    const blockedControl = leagueForm.get(`${leagueId}-blocked`);
    const declarationControl = leagueForm.get(`${leagueId}-declaration`);
    const classControl = leagueForm.get(`${leagueId}-class`);
    const iconNameControl = leagueForm.get(`${leagueId}-iconName`);
    nameControl.setValue(league.name);
    shortNameControl.setValue(league.shortName);
    orderControl.setValue(league.platformOrder);
    periodControl.setValue(league.platformPeriod);
    marketsControl.setValue(league.platformMarkets);
    marketNamesControl.setValue(league.marketNames);
    periodNamesControl.setValue(league.periodNames);
    mappingsControl.setValue((league.mappings || []).join('\n'));
    twitchChannelControl.setValue(league.twitchChannel);
    youtubeChannelControl.setValue(league.youtubeChannel);
    showLeagueStreamControl.setValue(league.showLeagueStream);
    overrideLeagueControl.setValue(league.overrideLeague);
    declarationControl.setValue(league.declaration);
    classControl.setValue(league.class);
    iconNameControl.setValue(league.iconName);
    blockedControl.setValue(!!league.blocked);
    this.addMessage(leagueId, 'Changes undone');
  }

  protected deleteStreamChannel(player, leagueId, rawLeague) {
    let leagueObject: AdminLeagueInterface = JSON.parse(rawLeague);
    leagueObject[`${player}Channel`] = '';
    leagueObject.originalMappings = leagueObject.mappings;
    this.doSaveLeagueChanges(leagueId, leagueObject);
  }

  protected clearDeclaration(leagueId) {
    this.leagueForm.get(`${leagueId}-form`).get(`${leagueId}-declaration`).setValue('_');
  }

  private getPlatformSports(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.sportsService.list({ fromPlatform: true }).subscribe(sports => {
        this.sportOptions = Object.keys(sports || {}).map(id => {
          return { id: Number(id), name: sports[id].name };
        });
        resolve();
      });
    });
  }

  private doSaveLeagueChanges(leagueId, leagueObject) {
    let validation = this.validate(leagueId, leagueObject);
    if (validation.success) {
      this.leaguesService.save(leagueId, leagueObject).subscribe((newLeague: AdminLeagueInterface) => {
        let oldLeague = this.leagues.find(league => Number(league.id) === Number(newLeague.id));
        let declarationFailed = newLeague.declarationFailed;
        let leagueClassFailed = newLeague.leagueClassFailed;
        delete newLeague.declarationFailed;
        delete newLeague.leagueClassFailed;
        if (oldLeague) {
          oldLeague.name = newLeague.name;
          oldLeague.shortName = newLeague.short_name;
          oldLeague.platformOrder = newLeague.platformOrder;
          oldLeague.platformPeriod = newLeague.platformPeriod;
          oldLeague.platformMarkets = newLeague.platformMarkets;
          oldLeague.marketNames = newLeague.marketNames;
          oldLeague.periodNames = newLeague.periodNames;
          oldLeague.mappings = newLeague.mappings;
          oldLeague.raw = JSON.stringify(newLeague, null, 2);
          oldLeague.twitchChannel = newLeague.twitchChannel;
          oldLeague.youtubeChannel = newLeague.youtubeChannel;
          oldLeague.selectedSportIds = newLeague.platformSport ? [ newLeague.platformSport ] : [];
          oldLeague.showLeagueStream = !!newLeague.showLeagueStream;
          oldLeague.overrideLeague = !!newLeague.overrideLeague;
          oldLeague.declaration = newLeague.declaration;
          oldLeague.class = newLeague.class;
          oldLeague.iconName = newLeague.iconName;
          oldLeague.blocked = !!newLeague.blocked;
        }
        this.buildForm(this.leagues);
        this.addMessage(leagueId, 'League saved');
        if (declarationFailed) {
          this.addMessage(leagueId, 'Declaration Failed', true);
        }
        if (leagueClassFailed) {
          this.addMessage(leagueId, 'League Class Failed', true);
        }
      }, err => {
        this.addMessage(leagueId, err.error, true);
      });
    } else {
      this.addMessage(leagueId, validation.reasons.join(',\n'), true);
    }
  }

  private addMessage(leagueId: number, message = '', isError = false) {
    this.formMessages[leagueId] = message;
    this.hasError[leagueId] = isError;
    setTimeout(() => {
      delete this.formMessages[leagueId];
      this.hasError[leagueId] = false;
    }, 2000);
  }

  private sort(leagues: UILeague[]): UILeague[] {
    return leagues.sort((l1, l2) => l1.id - l2.id);
  }

  private updateUrl(search: string) {
    this.router.navigate(['/leagues', search ? { search } : {}]);
  }

  private sportsChanged({sportIds}, leagueId) {
    this.leagues.find((league) => league.id === leagueId).selectedSportIds = sportIds;
  }

  private extractLeagueValues(leagueId) {
    let leagueValues = this.leagueForm.get(`${leagueId}-form`).value;
    return Object.keys(leagueValues)
      .reduce((newValues, key) => {
        if (key !== 'declaration' || (leagueValues[key] === this.leagues[leagueId].declaration)) {
          newValues[this.getRealPropName(key)] = this.coerceValues(key, leagueValues[key]);
        }
        return newValues;
      }, {});
  }

  private getRealPropName(key: string) {
    return key.split('-', 2)[1];
  }

  private coerceValues(key: string, value: any): any {
    return ['declaration', 'class', 'iconName'].includes(key) && typeof value === 'string' ? value.trim() : value;
  }

  private showAlert(success, msg) {
    const ALERT_TIMER = 300;
    this.notifier.nativeElement.classList.remove(success ? 'alert-danger' : 'alert-success');
    this.notifier.nativeElement.classList.add(success ? 'alert-success' : 'alert-danger');
    this.notifier.nativeElement.innerText = msg;

    setTimeout(() => {
      this.notifier.nativeElement.classList.remove('alert-success', 'alert-danger');
      this.notifier.nativeElement.innerText = '';
    }, ALERT_TIMER);
  }

}
