/* Copyright © 2017-2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const SYSTEM_STATUSES_PATH = '/api/systemStatuses';

export interface SystemStatus {
  id: number;
  status: string;
}

@Injectable()
export class SystemStatusesService {

  private cachedStatuses: Promise<SystemStatus[]>;

  constructor(protected http: HttpClient) {
    /**/
  }

  public listStatuses(): Promise<SystemStatus[]> {
    if (!this.cachedStatuses) {
      this.cachedStatuses = new Promise((resolve, reject) => {
        this.http.get(SYSTEM_STATUSES_PATH)
          .subscribe((statuses: SystemStatus[]) => {
            resolve(this.sortStatuses(statuses));
          }, (err) => {
            reject(err);
            this.cachedStatuses = null;
          });
      });
    }

    return this.cachedStatuses;
  }

  public getStatusName(statusId) {
    if (this.cachedStatuses) {
      return this.cachedStatuses.then(cachedStatuses => cachedStatuses.find(status => status.id === statusId).status);
    } else {
      this.listStatuses().then(cachedStatuses => cachedStatuses.find(status => status.id === statusId).status);
    }
  }

  private sortStatuses(statuses: SystemStatus[]): SystemStatus[] {
    return statuses.sort((s1, s2) => s1.id - s2.id);
  }
}
