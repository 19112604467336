/* Copyright © 2017-2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { dup } from 'gs-utils/lib/utilities';

import { SportValidator } from '../../server/shared/validation/sportValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';
import { FilterListPipe } from '../filters/filterListPipe.filter';
import { AdminSportInterface } from '../../server/shared/models/gsModel';
import { PatternInterface, PatternRecord as LeaguePatternRecord } from '../../server/shared/models/leaguePattern';

import { DialogService } from '../dialog/dialog.service';

import { SportsService } from './sports.service';
import { LeaguePatternService } from './leaguePattern.service';
import { AddSportComponent } from './addSport.component';
import { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { Clipboard } from '@angular/cdk/clipboard';
import { LicenseManager } from 'ag-grid-enterprise';
import agGridLicense from '../agGridLicense';

interface UISport {
  id: number;
  name: string;
  platformOrder: number;
  marketNames: string;
  periodNames: string;
  platformMarkets: string;
  platformPeriodOrder: string;
  platformMarketOrder: string;
  mappings: string[];
  iconName: string;
  raw: string;
}

interface LeaguePatternInterface extends PatternInterface {
  raw?: string;
}

@Component({
  selector: 'sports',
  templateUrl: 'sports/sports.component.html',
  styles: [
    require('./sports.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe]
})

export class SportsComponent implements OnInit {
  protected creatingSport = false;

  protected sports: UISport[] = [];
  protected searching = false;

  @ViewChild('notifier') private notifier: ElementRef;
  private sportForm: FormGroup;
  private leaguePatterns: LeaguePatternRecord<LeaguePatternInterface> = {};
  private validator = new SportValidator();
  private viewLeaguePattern = {};
  private formMessages: Record<string, string> = {};
  private hasError: Record<string, boolean> = {};

  private activeSport: UISport = null;
  @ViewChild(AgGridAngular) protected agGrid: AgGridAngular;
  @ViewChild('actionMenu') protected actionMenu: ElementRef;
  @HostListener('document:keydown.escape', ['$event'])
  protected onKeydownHandler() {
    this.closeMenu();
  }
  @HostListener('document:click', ['$event'])
  protected clickout(event) {
    if (
      !event.target.classList.contains('fa-edit') &&
      !this.actionMenu?.nativeElement.contains(event.target)) {
        this.closeMenu();
      }
  }
  protected isActionMenuOpen = false;
  protected columnDefs: ColDef[] = [
    ['id', 'Sport ID'],
    ['name', 'Sport name'],
    ['platformOrder', 'Platform order'],
    ['platformMarkets', 'Platform Markets'],
    ['periodNames', 'Period names'],
    ['marketNames', 'Market names'],
    ['platformPeriodOrder', 'Platform Period Order'],
    ['platformMarketOrder', 'Platform Market Order']
  ].map(([field, headerName], i) => ({
    field,
    headerName,
    flex: 1,
    ...(!i && { cellRenderer: 'agGroupCellRenderer'}),
  } as ColDef))
  .concat({
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    resizable: false,
    sortable: false,
    filter: false,
    cellRenderer: ({ data }) => {
      const span = document.createElement('span');
      span.classList.add('event-action-icon');
      span.innerHTML = '<i class="fas fa-edit"></i>';
      span.onclick = () => {
        this.isActionMenuOpen = true;
        this.activeSport = data;
      };
      return span;
    }
  });
  protected defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };
  protected detailCellRendererParams = {
    detailGridOptions : {
      columnDefs: [{
        field: 'mappings',
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: { maxLength: 2000 },
        minWidth: 550,
        flex: 1,
      }, {
        field: 'mappingsAction',
        headerName: 'Actions',
        cellRenderer: ({ data }) => {
          const span = document.createElement('span');
          const saveButton = document.createElement('button');
          saveButton.textContent = 'Save';
          saveButton.classList.add('btn', 'btn-success');
          saveButton.onclick = () => this.saveSport( id, raw );
          const copyButton = document.createElement('button');
          copyButton.textContent = 'Copy';
          copyButton.classList.add('btn', 'btn-warning');
          copyButton.onclick = () => this.clipBoard.copy(mappingsControl.value);
          const { id, raw } = data;
          const mappingsControl = this.getFormControl(id, 'mappings');
          span.appendChild(copyButton);
          span.appendChild(saveButton);
          return span;
        }
      }
    ],
      onCellValueChanged: this.onCellValueChanged.bind(this),
      enableCellTextSelection: true,
      ensureDomOrder: true,
      stopEditingWhenCellsLoseFocus: true
    },
    getDetailRowData: ({ successCallback, data }) => {
      successCallback([data]);
    },
  };

  constructor(private fb: FormBuilder, private sportsService: SportsService, private leaguePatternService: LeaguePatternService,
    private router: Router, private state: ActivatedRoute, private dialog: DialogService, private clipBoard: Clipboard) { /**/ }

  public ngOnInit(): void {
    this.buildForm([]);
    this.state.params.subscribe((params: Params) => {
      let field = this.sportForm.get('sports-search');
      if (field.value !== params.search) {
        field.setValue(params.search);
        this.searchForSports(params.search);
      }
    });
    LicenseManager.setLicenseKey(agGridLicense);
  }

  protected closeMenu(): void {
    this.isActionMenuOpen = false;
    this.activeSport = null;
  }

  protected onCellValueChanged(params: CellValueChangedEvent): void {
    const key = params.column.getId();
    if ( key === 'mappings' ) {
      const { id, mappings } = params.data;
      const mappingsControl = this.getFormControl(id, 'mappings');
      mappingsControl.setValue(mappings.replace(/,/g, '\n'));
    }
  }

  protected getFormControl( sportId: number, key: string ): AbstractControl {
    return this.sportForm.get(`${sportId}-${key}`);
  }

  protected viewRaw(sport) {
    this.dialog
      .alert
      .title(`Sport: ${sport.name}`)
      .body(`${JSON.stringify(sport, (k, v) => ( k !== 'raw' ? v : undefined ), 2)}`)
      .asTextArea()
      .open();
  }

  protected searchForSports(search: string) {
    this.searching = true;
    this.sports = [];
    this.updateUrl(search);
    this.sportsService.list({ search, includeMappings: true }).subscribe(sports => {
      Object.keys(sports).forEach(sportId => {
        let sport = sports[sportId];
        this.sports.push({
          id: Number(sportId),
          name: sport.name,
          platformOrder: sport.platformOrder,
          iconName: sport.iconName,
          mappings: sport.mappings || [],
          raw: JSON.stringify(sport, null, 2),
          platformMarkets: sport.platformMarkets,
          marketNames: sport.marketNames,
          periodNames: sport.periodNames,
          platformPeriodOrder: sport.platformPeriodOrder,
          platformMarketOrder: sport.platformMarketOrder
        });
      });
      this.sports = [...this.sort(this.sports)];
      this.buildForm(this.sports);
      this.searching = false;
    });
  }

  protected createSport() {
    this.dialog
      .confirm
      .body(AddSportComponent)
      .title('Creating a Sport')
      .open()
      .then(sport => {
        this.creatingSport = true;
        this.sportsService
          .create(sport)
          .subscribe(
            () => {
              this.showAlert(true, 'Spport created successfully');
              this.creatingSport = false;
            },
            (err) => {
              this.showAlert(false, 'Must supply name');
              this.creatingSport = false;
            }
          );
      })
      .catch(c => this.showAlert(false, 'Sport creation canceled'));
  }

  protected saveSport( sportId: number, raw: string ) {
    const {
      name,
      platformOrder,
      platformMarkets,
      mappings,
      marketNames,
      periodNames,
      platformPeriodOrder,
      platformMarketOrder,
      iconName
    } = this.extractValues(sportId);
    let sportObject: AdminSportInterface = JSON.parse(raw);
    sportObject.name = name;
    sportObject.originalMappings = sportObject.mappings;
    sportObject.platformOrder = platformOrder;
    sportObject.platformMarkets = platformMarkets;
    sportObject.mappings = mappings.split('\n');
    sportObject.marketNames = marketNames || undefined;
    sportObject.periodNames = periodNames || undefined;
    sportObject.platformPeriodOrder = platformPeriodOrder || undefined;
    sportObject.platformMarketOrder = platformMarketOrder || undefined;
    sportObject.iconName = iconName || undefined;
    let validation = this.validate(sportId, sportObject);
    if (validation.success) {
      this.sportsService.save(sportId, sportObject).subscribe((newSport: AdminSportInterface) => {
        let oldSport = this.sports.find(sport => Number(sport.id) === Number(newSport.id));
        if (oldSport) {
          oldSport.name = newSport.name;
          oldSport.platformOrder = newSport.platformOrder;
          oldSport.platformMarkets = newSport.platformMarkets;
          oldSport.mappings = newSport.mappings;
          oldSport.periodNames = newSport.periodNames;
          oldSport.marketNames = newSport.marketNames;
          oldSport.platformPeriodOrder = newSport.platformPeriodOrder;
          oldSport.platformMarketOrder = newSport.platformMarketOrder;
          oldSport.iconName = newSport.iconName;
          oldSport.raw = JSON.stringify(newSport, null, 2);
        }
        this.buildForm(this.sports);
        this.addMessage(sportId, 'Sport saved');
      });
    } else {
      this.addMessage(sportId, validation.reasons.join(',\n'), true);
    }
  }

  protected toggleLeaguePattern(id: string) {
    this.viewLeaguePattern[id] = !this.viewLeaguePattern[id];
    if (this.viewLeaguePattern[id]) {
      this.leaguePatternService.fetch(id).subscribe(leaguePatterns => {
        this.leaguePatterns[id] = dup(leaguePatterns[id]);
        this.leaguePatterns[id].forEach(pattern => {
          pattern.raw = JSON.stringify(pattern);
        });
      });
    } else {
      this.leaguePatterns[id] = undefined;
    }
  }

  protected validate(sportId: number, sport: AdminSportInterface): ValidationResponse {
    return this.validator.validate(sportId, sport);
  }

  protected buildForm(sports: UISport[]): void {
    let originalSearch = this.sportForm ? this.sportForm.get('sports-search').value : '';
    this.sports = [...sports];
    let fields: any = { };
    this.sports.forEach(sport => {
      fields[`${sport.id}-name`] = [ sport.name, [Validators.required] ];
      fields[`${sport.id}-platform-order`] = [ sport.platformOrder, [] ];
      fields[`${sport.id}-platform-markets`] = [ sport.platformMarkets, [] ];
      fields[`${sport.id}-mappings`] = [ (sport.mappings || []).join('\n'), [Validators.required] ];
      fields[`${sport.id}-market-names`] = [ sport.marketNames, [] ];
      fields[`${sport.id}-period-names`] = [ sport.periodNames, [] ];
      fields[`${sport.id}-period-order`] = [ sport.platformPeriodOrder, [] ];
      fields[`${sport.id}-market-order`] = [ sport.platformMarketOrder, [] ];
      fields[`${sport.id}-iconName`] = [ sport.iconName, [] ];
    });

    fields['sports-search'] = [originalSearch, []];
    this.sportForm = this.fb.group(fields);
  }

  protected extractValues(sportId: number) {
    return [
      ['name'],
      ['platform-order', 'platformOrder'],
      ['platform-markets', 'platformMarkets'],
      ['period-names', 'periodNames'],
      ['market-names', 'marketNames'],
      ['period-order', 'platformPeriodOrder'],
      ['market-order', 'platformMarketOrder'],
      ['mappings'],
      ['iconName']
    ].reduce((prev, [key, name]) => (prev[name ?? key] = this.getFormControl( sportId, key ).value, prev), Object.create(null));
  }

  protected revertChanges(sportId) {
    const sport = this.sports.find(u => (u[0] === sportId));
    const nameControl = this.sportForm.get(`${sportId}-name`);
    const orderControl = this.sportForm.get(`${sportId}-platform-order`);
    const platformMarketsControl = this.sportForm.get(`${sportId}-platform-markets`);
    const periodNamesControl = this.sportForm.get(`${sportId}-period-names`);
    const marketNamesControl = this.sportForm.get(`${sportId}-market-names`);
    const platformPeriodOrderControl = this.sportForm.get(`${sportId}-period-order`);
    const platformMarketOrderControl = this.sportForm.get(`${sportId}-market-order`);
    const iconNameControl = this.sportForm.get(`${sportId}-iconName`);
    const mappingsControl = this.sportForm.get(`${sportId}-mappings`);
    nameControl.setValue(sport.name);
    orderControl.setValue(sport.platformOrder);
    platformMarketsControl.setValue(sport.platformMarkets);
    marketNamesControl.setValue(sport.marketNames);
    periodNamesControl.setValue(sport.periodNames);
    platformPeriodOrderControl.setValue(sport.platformPeriodOrder);
    platformMarketOrderControl.setValue(sport.platformMarketOrder);
    iconNameControl.setValue(sport.iconName);
    mappingsControl.setValue((sport.mappings || []).join('\n'));
    this.addMessage(sportId, 'Changes undone');
  }

  private addMessage(sportId: number, message = '', isError = false) {
    this.formMessages[sportId] = message;
    this.hasError[sportId] = isError;
    setTimeout(() => {
      delete this.formMessages[sportId];
      this.hasError[sportId] = false;
    }, 2000);
  }

  private sort(sports: UISport[]): UISport[] {
    return sports.sort((l1, l2) => l1.id - l2.id);
  }

  private updateUrl(search: string) {
    this.router.navigate(['/sports', search ? { search } : {}]);
  }

  private showAlert(success, msg) {
    const ALERT_TIMER = 300;
    this.notifier.nativeElement.classList.remove(success ? 'alert-danger' : 'alert-success');
    this.notifier.nativeElement.classList.add(success ? 'alert-success' : 'alert-danger');
    this.notifier.nativeElement.innerText = msg;

    setTimeout(() => {
      this.notifier.nativeElement.classList.remove('alert-success', 'alert-danger');
      this.notifier.nativeElement.innerText = '';
    }, ALERT_TIMER);
  }

}
