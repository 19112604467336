/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Pipe, PipeTransform } from '@angular/core';
import { SystemStatusesService, SystemStatus } from '../statuses/systemStatus.service';

@Pipe({ name: 'statusName' })
export class StatusNamePipe implements PipeTransform {
  constructor(private systemStatusService: SystemStatusesService) {
    /** */
  }

  public transform(stateId: number): Promise<string> {
    return this.systemStatusService.getStatusName(stateId);
  }
}
