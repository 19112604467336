/* Copyright © 2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseRequestorService } from '../baseRequestor.service';
import { AggregatorTemplateInterface } from '../../server/shared/models/AggregatorTemplate';
import { Observable } from 'rxjs';
import { BWinTemplateManagement } from './sources/BWinTemplateManagement';

export interface AggregatorInterface {
  templateTypes: string[];
  templates: Record<string, string>;
}

const sources = {
  'betsapi-bwin': BWinTemplateManagement,
};

@Injectable()
export class AggregatorTemplatesService extends BaseRequestorService<AggregatorTemplateInterface, any> {
  constructor(protected http: HttpClient) {
    super('aggregatorTemplates', http);
  }

  public getList(source: string): Observable<AggregatorInterface> {
    return this.listWithSource(source);
  }

  public getEvent(source: string, eventId: string): Observable<{ id: number; name: string }[]> {
    this.source = source;
    const result = this.fetch(eventId);
    return sources[source].formatResult(result);
  }

  public saveTemplate(source: string, model: AggregatorTemplateInterface): Observable<any> {
    return this.createWithSource(source, model);
  }

  public goToEvent(source: string, eventId: string | number) {
    sources[source].goToEvent(eventId);
  }
}
