/* Copyright © 2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const PATH = '/api/expireFromPlatform';

@Injectable()
export class ExpireFromPlatformService {
  constructor(protected http: HttpClient) { /**/ }

  public remove(rotations: number[]): Observable<number[]> {
    return this.http.put<number[]>(PATH, { rotations: rotations });
  }
}
