/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Validator, ValidationResponse } from './validator';
import { StandardPoints } from '../models/standardPoints';

export class StandardPointsValidator extends Validator<StandardPoints> {
  public validate(id: StandardPoints['id'], instance: StandardPoints): ValidationResponse {
    let errors = [];
    if (!id) {
      errors.push('Must supply a standardPoints id.');
    }

    if (!instance) {
      errors.push('Must supply standardPoints info.');
    }

    if (!instance.id) {
      errors.push('Must supply a standardPoints id.');
    }

    if (errors.length) {
      return {
        success: false,
        reasons: errors
      };
    }
    return {
      success: true
    };
  }
}
