/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Pipe, PipeTransform } from '@angular/core';
import { isObject } from 'gs-utils/lib/utilities';

@Pipe({name: 'filterList'})
/**
 * Filters an array of objects based on a string
 *
 */
export class FilterListPipe implements PipeTransform {

  public transform(input: any[], config: string): any {
    if (!Array.isArray(input) || !config || typeof config !== 'string') {
      return input;
    }
    return input.filter(item => this.search(item, config.toLowerCase()));
  }

  private search(item: any, config: string, depth = 0) {
    if (depth > 2) {
      return false;
    }
    if (typeof item === 'number') {
      item = String(item);
    }

    if (typeof item === 'string') {
      return item.toLowerCase().includes(config);
    } else if (isObject(item)) {
      return Object.keys(item).some(key =>
        this.search(item[key], config, depth + 1));
    }
  }
}
