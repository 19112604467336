/* Copyright © 2017-2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { NgModule } from '@angular/core';
import { HttpClient, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { LoginComponent } from '../login/login.component';
import { TaskMenuComponent } from '../taskMenu/taskMenu.component';
import { MTPermissionsDialog, UsersActionsCellRenderer, UsersComponent } from '../users/users.component';
import { LeaguesComponent } from '../leagues/leagues.component';
import { SportsComponent } from '../sports/sports.component';
import { CountriesComponent } from '../countries/countries.component';
import { LeaguesDropDownComponent } from '../leagues/leaguesDropDown.component';
import { PeriodsDropDownComponent } from '../periods/periodsDropDown.component';
import { WagertypesDropDownComponent } from '../wagertypes/wagertypesDropDown.component';
import { SportsDropDownComponent } from '../sports/sportsDropDown.component';
import { GroupsComponent } from '../groups/groups.component';
import { EventsComponent } from '../events/events.component';
import { StatusSelectorComponent } from '../events/statusSelector.component';
import { PageNotFoundComponent } from './pageNotFound.component';
import { NotAuthorizedComponent } from './notAuthorized.component';
import { PasswordGeneratorComponent } from '../passwordGenerator/passwordGenerator.component';
import { RotationChangesActionsCellRenderer, RotationChangesComponent } from '../rotationChanges/rotationChanges.component';
import { WebsocketSpelunkerComponent } from '../websocketSpelunker/websocketSpelunker.component';
import { ScoresComponent } from '../scores/scores.component';
import { LinesActionsCellRenderer, LinesComponent } from '../lines/lines.component';
import { CorrelationsComponent } from '../correlations/correlations.component';
import { LeagueCorrelationsComponent } from '../correlations/leagueCorrelations.component';
import { WagertypesComponent } from '../wagertypes/wagertypes.component';
import { WagertypesHeaderComponent } from '../wagertypes/header.component';
import { StatisticActionsCellRenderer, StatisticsComponent } from '../wagertypes/statistics.component';
import { LimitGroupsActionsCellRenderer, LimitGroupsComponent } from '../wagertypes/limitGroups.component';
import { MarketCategoriesComponent } from '../wagertypes/marketCategories.component';
import { DisplayStylesComponent } from '../wagertypes/displayStyles.component';
import { OffertypesComponent } from '../offertypes/offertypes.component';
import { OffertypeListComponent } from '../offertypes/offertypeList.component';
import { SingleWagertypeComponent } from '../wagertypes/singleWagertype.component';
import { SingleLeagueComponent } from '../leagues/singleLeague.component';
import { TeamsComponent } from '../teams/teams.component';
import { TeamsInitComponent } from '../teams/teamsInit.component';
import { TeamsDropDownComponent } from '../teams/teamsDropDown.component';
import { PlayersComponent } from '../players/players.component';
import { PlayersInitComponent } from '../players/playersInit.component';
import { ExpireTimesComponent } from '../expireTimes/expireTimes.component';
import { BodyComponent } from './body.component';
import { StandardPointsComponent } from '../standardPoints/standardPoints.component';
import { MediatorComponent } from '../mediator/mediator.component';
import { ActiveEventsActionsCellRenderer, ActiveEventsComponent } from '../activeEvents/activeEvents.component';
import { EventChangesComponent } from '../events/eventChanges.component';
import { MatchupsComponent } from '../matchups/matchups.component';
import { MatchupsHeaderComponent } from '../matchups/header.component';
import { StoresHeaderComponent } from '../stores/header.component';
import { StoresComponent } from '../stores/stores.component';

import { NotAuthorizedInterceptor } from './notAuthorized.interceptor';
import { JsonContentTypeInterceptor } from './jsonContentType.interceptor';

import { UsersService } from '../users/users.service';
import { GroupsService } from '../groups/groups.service';
import { EventsService } from '../events/events.service';
import { LeaguesService } from '../leagues/leagues.service';
import { SportsService } from '../sports/sports.service';
import { LeaguePatternService } from '../sports/leaguePattern.service';
import { CountriesService } from '../countries/countries.service';
import { PasswordGeneratorService } from '../passwordGenerator/passwordGenerator.service';
import { RotationChangesService } from '../rotationChanges/rotationChanges.service';
import { WebsocketSpelunkerService } from '../websocketSpelunker/websocketSpelunker.service';
import { ScoresService } from '../scores/scores.service';
import { OffertypesService } from '../offertypes/offertypes.service';
import { WagertypesService } from '../wagertypes/wagertypes.service';
import { TeamsService } from '../teams/teams.service';
import { CreateTeamService } from '../teams/createTeam.service';
import { SportIdByNameService } from '../leagues/sportIdByName.service';
import { PlayersService } from '../players/players.service';
import { CreatePlayerService } from '../players/createPlayer.service';
import { ExpireTimesService } from '../expireTimes/expireTimes.service';
import { TeamDesignatorService } from '../teams/teamDesignator.service';
import { StandardPointsService } from '../standardPoints/standardPoints.service';
import { ActiveEventsService } from '../activeEvents/activeEvents.service';

import { JsonStringifyPipe } from '../filters/jsonStringifyPipe.filter';
import { StringOrderByPipe, NumberOrderByPipe } from '../filters/orderByPipes.filter';
import { FilterListPipe } from '../filters/filterListPipe.filter';
import { WagertypePipe } from '../filters/wagertypePipe.filter';
import { PeriodPipe } from '../filters/periodPipe.filter';
import { ValuePipe } from '../filters/valuePipe.filter';
import { DateService } from '../filters/date.service';
import { SystemStatusesService } from '../statuses/systemStatus.service';
import { GamePartitionComponent } from '../gamePartition/gamePartition.component';
import { GamePartitionService } from '../gamePartition/gamePartition.service';
import { PartnersService } from '../services/partners.service';
import { LoginService } from '../login/login.service';
import { StatusNamePipe } from '../pipes/statusName.pipe';
import { SortByNamePipe } from '../pipes/sortByName.pipe';
import { ContentWrapperComponent } from '../contentWrapper/contentWrapper.component';
import { AuthGuard } from './auth.guard';
import { ScoringRulesComponent } from '../scoringRules/scoringRules.component';
import { ScoringRulesService } from '../scoringRules/scoringRules.service';
import { ScoringRuleDialogComponent } from '../scoringRules/scoringRuleDialog.component';
import { ScoringRulesDropdownComponent } from '../scoringRules/scoringRulesDropdown.component';
import { ScoringRulesMapperService } from '../scoringRules/scoringRulesMapper.service';
import { RuleNameAsyncValidatorDirective } from '../scoringRules/RuleNameAsyncValidator.directive';
import { DateTimeComponent } from '../dateTime/dateTime.component';
import { DialogModule } from '../dialog/dialog.module';
import { AddLeagueComponent } from '../leagues/add-league.component';
import { AddSportComponent } from '../sports/addSport.component';
import { AddEventComponent } from '../events/addEvent.component';
import { PageHeaderComponent } from '../page-header/page-header.component';
import { RequestEventComponent } from '../events/requestEvent.component';
import { RequestLeagueComponent } from '../events/requestLeague.component';
import { RequestIdService } from '../events/requestId.service';
import { RequestOutrightIdService } from '../events/requestOutrightId.service';
import { RequestOutrightLeagueIdService } from '../events/requestOutrightLeagueId.service';
import { RequestLeagueIdService } from '../events/requestLeagueId.service';
import { EventSourcesService } from '../events/eventSources.service';
import { MultiSelectComponent } from '../multiselect/multiselect.component';
import { AgGridModule } from 'ag-grid-angular';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { SnackBarService } from '../services/snackbar.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AggregatorTemplatesService } from '../offertypes/aggregatorTemplates.service';
import { AggregatorTemplates } from '../offertypes/aggregatorTemplates.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MTpermissionsComponent, TriStateCheckboxComponent } from '../users/mtPermissions.component';
import { MatTreeModule } from '@angular/material/tree';
import { ConfirmDialogComponent } from '../confirmDialog/confirmDialog.component';
import { CorrelationsActionsCellRenderer } from '../correlations/baseCorrelations.component';
import { CorrelationsFormComponent } from '../correlations/correlationsForm.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule.forRoot(),
    AgGridModule,
    ScrollingModule,
    MatButtonModule,
    MatGridListModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatIconModule,
    MatTabsModule,
    MatSelectModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatTooltipModule,
    MatChipsModule,
    MatListModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatTreeModule,
    RouterModule.forRoot([
      {
        path: '',
        component: ContentWrapperComponent,
        canActivateChild: [AuthGuard],
        children: [
          { path: '', redirectTo: 'events', pathMatch: 'full' },
          {
            path: 'users',
            component: UsersComponent,
          },
          {
            path: 'leagues',
            component: LeaguesComponent,
          },
          {
            path: 'sports',
            component: SportsComponent,
          },
          {
            path: 'countries',
            component: CountriesComponent,
          },
          {
            path: 'teams',
            component: TeamsInitComponent,
          },
          {
            path: 'players',
            component: PlayersInitComponent,
          },
          {
            path: 'expireTimes',
            component: ExpireTimesComponent,
          },
          {
            path: 'events',
            component: EventsComponent,
          },
          {
            path: 'groups',
            component: GroupsComponent,
          },
          {
            path: 'password',
            component: PasswordGeneratorComponent,
          },
          {
            path: 'mediator',
            component: MediatorComponent,
          },
          {
            path: 'offertypeList',
            component: OffertypeListComponent,
          },
          {
            path: 'spelunk',
            component: WebsocketSpelunkerComponent,
          },
          {
            path: 'wagertypes',
            component: WagertypesHeaderComponent,
          },
          {
            path: 'matchups',
            component: MatchupsHeaderComponent,
          },
          {
            path: 'game-partitions',
            component: GamePartitionComponent,
          },
          {
            path: 'scoring-rules',
            component: ScoringRulesComponent,
          },
          {
            path: 'standardPoints',
            component: StandardPointsComponent,
          },
          {
            path: 'notAuthorized',
            component: NotAuthorizedComponent,
          },
          {
            path: 'stores',
            component: StoresHeaderComponent,
          },
        ],
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ]),
  ],
  declarations: [
    ContentWrapperComponent,
    TaskMenuComponent,
    LoginComponent,
    AppComponent,
    UsersComponent,
    GroupsComponent,
    SportsComponent,
    LeaguesComponent,
    CountriesComponent,
    LeaguesDropDownComponent,
    PeriodsDropDownComponent,
    WagertypesDropDownComponent,
    SportsDropDownComponent,
    PasswordGeneratorComponent,
    PageNotFoundComponent,
    ScoresComponent,
    WagertypesComponent,
    LinesComponent,
    CorrelationsComponent,
    LeagueCorrelationsComponent,
    OffertypesComponent,
    OffertypeListComponent,
    TeamsComponent,
    TeamsInitComponent,
    TeamsDropDownComponent,
    PlayersComponent,
    PlayersInitComponent,
    StatusSelectorComponent,
    SingleWagertypeComponent,
    SingleLeagueComponent,
    MatchupsHeaderComponent,
    MatchupsComponent,
    StoresHeaderComponent,
    StoresComponent,
    WagertypesHeaderComponent,
    StatisticsComponent,
    LimitGroupsComponent,
    DisplayStylesComponent,
    MarketCategoriesComponent,
    BodyComponent,
    JsonStringifyPipe,
    StringOrderByPipe,
    NumberOrderByPipe,
    SortByNamePipe,
    FilterListPipe,
    WagertypePipe,
    PeriodPipe,
    ValuePipe,
    EventsComponent,
    ExpireTimesComponent,
    RotationChangesComponent,
    MediatorComponent,
    WebsocketSpelunkerComponent,
    NotAuthorizedComponent,
    GamePartitionComponent,
    StatusNamePipe,
    ContentWrapperComponent,
    ScoringRulesComponent,
    ScoringRuleDialogComponent,
    ScoringRulesDropdownComponent,
    RuleNameAsyncValidatorDirective,
    DateTimeComponent,
    StandardPointsComponent,
    AddLeagueComponent,
    AddSportComponent,
    AddEventComponent,
    PageHeaderComponent,
    RequestEventComponent,
    RequestLeagueComponent,
    ActiveEventsComponent,
    EventChangesComponent,
    MultiSelectComponent,
    AutocompleteComponent,
    RotationChangesActionsCellRenderer,
    ActiveEventsActionsCellRenderer,
    LinesActionsCellRenderer,
    AggregatorTemplates,
    UsersActionsCellRenderer,
    MTPermissionsDialog,
    MTpermissionsComponent,
    TriStateCheckboxComponent,
    ConfirmDialogComponent,
    CorrelationsActionsCellRenderer,
    StatisticActionsCellRenderer,
    LimitGroupsActionsCellRenderer,
    CorrelationsFormComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    LoginService,
    GamePartitionService,
    PartnersService,
    SportsService,
    LeaguesService,
    CountriesService,
    UsersService,
    GroupsService,
    PasswordGeneratorService,
    EventsService,
    RotationChangesService,
    WebsocketSpelunkerService,
    CreateTeamService,
    SportIdByNameService,
    CreatePlayerService,
    ScoresService,
    OffertypesService,
    WagertypesService,
    ExpireTimesService,
    LeaguePatternService,
    TeamsService,
    PlayersService,
    DateService,
    SystemStatusesService,
    TeamDesignatorService,
    HttpClient,
    AuthGuard,
    ScoringRulesService,
    ScoringRulesMapperService,
    StandardPointsService,
    ActiveEventsService,
    SnackBarService,
    AggregatorTemplatesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotAuthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonContentTypeInterceptor,
      multi: true,
    },
    RequestIdService,
    RequestOutrightIdService,
    RequestOutrightLeagueIdService,
    RequestLeagueIdService,
    EventSourcesService,
  ],
})
export class AppModule {}
