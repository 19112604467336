/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortByName' })

export class SortByNamePipe implements PipeTransform {
  public transform<T extends { name: string }>(values: T[]): T[] {
    return values.sort((a, b) => a.name.localeCompare(b.name));
  }
}
