/* Copyright © 2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseRequestorService } from '../baseRequestor.service';
import { PatternInterface, PatternRecord } from '../../server/shared/models/leaguePattern';
import { LeaguePatternValidator } from '../../server/shared/validation/leaguePatternValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';


@Injectable()
export class LeaguePatternService extends BaseRequestorService<PatternInterface[], PatternRecord> {

  private leaguePatternValidator = new LeaguePatternValidator();

  constructor(protected http: HttpClient) {
    super('leaguePattern', http, 30000);
  }

  public validate(id: string, model: PatternInterface): ValidationResponse {
    return this.leaguePatternValidator.validate(id, model);
  }
}
