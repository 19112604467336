/* Copyright © 2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseRequestorService } from '../baseRequestor.service';

@Injectable()
export class LineSetsService extends BaseRequestorService<any, string[]> {

  constructor(protected http: HttpClient) {
    super('lineSets', http);
  }
}
