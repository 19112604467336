/* Copyright © 2018 Ganchrow Scientific, SA all rights reserved */
'use strict';

import {Validator, ValidationResponse} from './validator';
import {TeamInterface} from '../models/team';

export class TeamValidator extends Validator<TeamInterface> {
  public validate(id: TeamInterface['id'], instance: TeamInterface): ValidationResponse {
    let errors = [];
    if (!id) {
      errors.push('Must supply a team id.');
    }

    if (Number(id) !== instance.id) {
      errors.push(`Team id is invalid. ${id} does not match ${instance.id}`);
    }

    if (!instance) {
      errors.push('Must supply team info.');
    }

    if (!instance.id) {
      errors.push('Must supply a team id.');
    }

    if (!instance.name) {
      errors.push('Must supply a team name.');
    }

    if (!instance.league_id || !Number.isInteger(Number(instance.league_id))) {
      errors.push('Must supply a league id.');
    }

    if (errors.length) {
      return {
        success: false,
        reasons: errors
      };
    }
    return {
      success: true
    };
  }
}
