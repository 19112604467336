/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const SPELUNK_BASE_PATH = '/api/spelunk/';
const LOG_SOURCES_PATH = '/api/logSources';

@Injectable()
export class WebsocketSpelunkerService {

  constructor(protected http: HttpClient) {
    /**/
  }

  public getLogSources(): Observable<string[]> {
    return this.http.get(LOG_SOURCES_PATH)
      .pipe(
        map((r: { result: string[] }) => r.result)
      );
  }

  public executeCommand(logSource: string, command: string): Observable<string> {
    return this.http.put(`${SPELUNK_BASE_PATH}${logSource}?command=${command}`, '')
      .pipe(
        map((r: { result: string }) => r.result)
      );
  }
}
