/* Copyright © 2018-2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { dup } from 'gs-utils/lib/utilities';

import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { FilterListPipe } from '../filters/filterListPipe.filter';
import { PlayerInterface } from '../../server/shared/models/player';

import { PlayerValidator } from '../../server/shared/validation/playerValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';

import { PlayersService } from './players.service';

import { EMPTY_TEAM } from '../teams/teams.service';

@Component({
  selector: 'players',
  templateUrl: 'players/players.component.html',
  styles: [
    require('./players.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe]
})

export class PlayersComponent implements OnInit {
  protected searching = false;
  protected page = 1;

  @Input()
  private teams: { id: number, label: string }[];
  private players: PlayerInterface[] = [];
  private playersStage: PlayerInterface[] = [];
  private playerForm: FormGroup;
  private validator = new PlayerValidator();
  private formMessages: Record<string, string> = {};
  private hasError: Record<string, boolean> = {};
  private step = 100;

  constructor(private fb: FormBuilder, private playersService: PlayersService, private state: ActivatedRoute) { /**/ }

  public ngOnInit(): void {
    this.state.params.subscribe((params: Params) => {
      let labels = params.labels ? params.labels.split(',') : '';
      this.teams = params.teamIds ? params.teamIds.split(',').map((tm, idx) => ({ id: +tm, label: labels[idx] })) : '';
      this.search();
    });
    this.buildForm([]);
  }

  protected get needsPage(): boolean {
    return this.playersStage.length > this.step;
  }

  protected get needsPreviousPage(): boolean {
    return this.page > 1;
  }

  protected get needsNextPage(): boolean {
    return this.playersStage.slice(this.page * this.step, (this.page + 1) * this.step).length > 0;
  }

  protected doPage(page: number) {
    if (page > 0) {
      this.page = page;
      this.buildForm(this.playersStage.slice((page - 1) * this.step, this.step * page));
    }
  }

  protected search() {
    if (this.teams.length) {
      this.searching = true;
      this.playersService.list({ team_id: this.teams.map(tm => tm.id) }).subscribe(players => {
        this.playersStage = Object.keys(players).map(id => players[id]);
        this.buildForm(this.playersStage.slice(0, this.step));
        this.searching = false;
      });
    } else {
      this.searching = false;
      this.buildForm([]);
    }
  }

  protected buildForm(players: PlayerInterface[]): void {
    this.players = players;
    let fields: any = { };
    this.players.forEach(player => {
      fields[`${player.id}-name`] = [ player.name, [Validators.required] ];
      fields[`${player.id}-mappings`] = [ this.extractMappings(player), [] ];
      fields[`${player.id}-short-name`] = [ player.short_name, [Validators.required] ];
    });
    fields['players-filter'] = [(this.playerForm && this.playerForm.get('players-filter').value) || '', []];
    this.playerForm = this.fb.group(fields);
  }

  protected savePlayer(id, name: string, shortName: string) {
    let originalIndex = this.players.findIndex(t => t.id === Number(id));
    let index = this.step * (this.page - 1) + originalIndex;
    let player: PlayerInterface = this.players[originalIndex];
    if (!player) {
      return;
    }
    let savePlayer: PlayerInterface = dup(player);
    savePlayer.name = name;
    savePlayer.short_name = shortName || undefined;
    savePlayer.originalMappings = dup(savePlayer.mappings);
    savePlayer.mappings = this.gatherMappings(player.id, player.team_id);

    let validation = this.validate(id, savePlayer);
    let controlName = id;
    if (validation.success) {
      this.playersService.save(id, savePlayer).subscribe((newPlayer: PlayerInterface) => {
        if (this.validate(id, newPlayer).success) {
          this.players[originalIndex] = newPlayer;
          this.playersStage[index] = newPlayer;
          this.addMessage(controlName, 'Player Saved');
          this.buildForm(this.players);
        } else {
          this.addMessage(controlName, validation.reasons.join(',\n'), true);
        }
      });
    } else {
      this.addMessage(controlName, validation.reasons.join(',\n'), true);
    }
  }

  protected validate(playerId: string, player: PlayerInterface): ValidationResponse {
    return this.validator.validate(playerId, player);
  }

  protected revertChanges(id) {
    let player = this.players.find(t => t.id === Number(id));
    if (player) {
      let playerControl = this.playerForm.get(`${player.id}-name`);
      playerControl.setValue(player.name);
      let playerShortNameControl = this.playerForm.get(`${player.id}-short-name`);
      playerShortNameControl.setValue(player.short_name);
      let playerMappingsControl = this.playerForm.get(`${player.id}-mappings`);
      playerMappingsControl.setValue(this.extractMappings(player));
      this.addMessage(player.name, 'Changes undone');
    }
  }

  protected findTeamLabel(id: number) {
    return (this.teams.find(tm => id === tm.id) || EMPTY_TEAM).label;
  }

  private addMessage(playerName: string, message = '', isError = false) {
    this.formMessages[playerName] = message;
    this.hasError[playerName] = isError;
    setTimeout(() => {
      delete this.formMessages[playerName];
      this.hasError[playerName] = false;
    }, 2000);
  }

  private extractMappings(player: PlayerInterface): string {
    return player.mappings.join('\n');
  }

  private gatherMappings(id, teamId) {
    return this.playerForm.get(`${id}-mappings`).value
      .split('\n')
      .filter(mapping => mapping)
      .map((mapping: string) => mapping.toLocaleLowerCase())
    ;
  }
}
