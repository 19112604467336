/* Copyright © 2018-2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { dup } from 'gs-utils/lib/utilities';

import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FilterListPipe } from '../filters/filterListPipe.filter';
import { TeamInterface } from '../../server/shared/models/team';

import { TeamValidator } from '../../server/shared/validation/teamValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';

import { CreatePlayerService } from '../players/createPlayer.service';

import { TeamsService } from './teams.service';

import { EMPTY_LEAGUE } from '../leagues/leagues.service';

@Component({
  selector: 'teams',
  templateUrl: 'teams/teams.component.html',
  styles: [
    require('./teams.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe]
})

export class TeamsComponent implements OnInit, OnChanges {
  protected searching = false;
  protected page = 1;

  @Input()
  private leagues: { id: number, label: string }[];
  private teams: TeamInterface[] = [];
  private teamsStage: TeamInterface[] = [];
  private teamForm: FormGroup;
  private validator = new TeamValidator();
  private formMessages: Record<string, string> = {};
  private hasError: Record<string, boolean> = {};
  private filter = '';
  private step = 100;

  constructor(private fb: FormBuilder, private teamsService: TeamsService,
    private createPlayerService: CreatePlayerService, private router: Router) { /**/ }

  public ngOnInit(): void {
    this.buildForm([]);

    this.teamsService.searchedTeams.subscribe(teams => {
      this.teamsStage = Object.keys(teams).map(id => teams[id]);
      this.buildForm(this.teamsStage.slice(0, this.step));
    });

    this.teamsService.teamFilter.subscribe(filter => {
      this.filter = filter;
    });

    this.teamsService.isLoadingTeams.subscribe(loading => {
      this.searching = loading;
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.leagues && (
      !changes.leagues.currentValue ||
      !changes.leagues.currentValue.length)
    ) {
      this.buildForm([]);
    }
  }

  protected get needsPage(): boolean {
    return this.teamsStage.length > this.step;
  }

  protected get needsPreviousPage(): boolean {
    return this.page > 1;
  }

  protected get needsNextPage(): boolean {
    return this.teamsStage.slice(this.page * this.step, (this.page + 1) * this.step).length > 0;
  }

  protected doPage(page: number) {
    if (page > 0) {
      this.page = page;
      this.buildForm(this.teamsStage.slice((page - 1) * this.step, this.step * page));
    }
  }

  protected buildForm(teams: TeamInterface[]): void {
    this.teams = teams;
    let fields: any = { };
    this.teams.forEach(team => {
      fields[`${team.id}-name`] = [ team.name, [Validators.required] ];
      fields[`${team.id}-mappings`] = [ this.extractMappings(team), [] ];
      fields[`${team.id}-short-name`] = [ team.short_name, [Validators.required] ];
    });
    fields['teams-filter'] = [(this.teamForm && this.teamForm.get('teams-filter').value) || '', []];
    this.teamForm = this.fb.group(fields);
  }

  protected viewPlayers(teamId: string, teamName: string) {
    this.router.navigate(['/players', { teamIds: teamId, labels: `${teamId}: ${teamName}` }]);
  }

  protected savePlayer(newPlayerName: string, teamId: number) {
    this.createPlayerService.save('', { team_id: +teamId, name: newPlayerName }).subscribe((result: any) => {
      if (!result.error) {
        this.addMessage(String(teamId), `Player ${newPlayerName} save`);
      }
    });
  }

  protected saveTeam(id, name: string, shortName: string) {
    let originalIndex = this.teams.findIndex(t => t.id === Number(id));
    let index = this.step * (this.page - 1) + originalIndex;
    let team: TeamInterface = this.teams[originalIndex];
    if (!team) {
      return;
    }
    let saveTeam: TeamInterface = dup(team);
    saveTeam.name = name;
    saveTeam.short_name = shortName || undefined;
    saveTeam.originalMappings = dup(saveTeam.mappings);
    saveTeam.mappings = this.gatherMappings(team.id, team.league_id);

    let validation = this.validate(id, saveTeam);
    let controlName = id;
    if (validation.success) {
      this.teamsService.save(id, saveTeam).subscribe((newTeam: TeamInterface) => {
        if (this.validate(id, newTeam).success) {
          this.teams[originalIndex] = newTeam;
          this.teamsStage[index] = newTeam;
          this.addMessage(controlName, 'Team Saved');
          this.buildForm(this.teams);
        } else {
          this.addMessage(controlName, validation.reasons.join(',\n'), true);
        }
      });
    } else {
      this.addMessage(controlName, validation.reasons.join(',\n'), true);
    }
  }

  protected deleteTeam(id) {
    let originalIndex = this.teams.findIndex(t => t.id === Number(id));
    let index = this.step * (this.page - 1) + originalIndex;
    let team: TeamInterface = this.teams[originalIndex];
    if (!team) {
      return;
    }

    let validation = this.validate(id, team);
    if (validation.success) {
      let mappings = this.gatherMappings(team.id, team.league_id).reduce((o, mapping) => {
        o[mapping] = true;
        return o;
      }, {});
      this.teamsService.delete(id, mappings).subscribe(() => {
        this.addMessage(id, 'Team deleted');
        this.teamsStage = this.teamsStage.filter((t, i) => i !== index);
        this.buildForm(this.teams.filter((t, i) => i !== originalIndex ));
      }, err => {
        this.addMessage(id, `Unable to delete team because of : ${JSON.stringify(err)}`, true);
      });
    } else {
      this.addMessage(id, validation.reasons.join(',\n'), true);
    }
  }


  protected validate(teamId: string, team: TeamInterface): ValidationResponse {
    return this.validator.validate(teamId, team);
  }

  protected revertChanges(id) {
    let team = this.teams.find(t => t.id === Number(id));
    if (team) {
      let teamControl = this.teamForm.get(`${team.id}-name`);
      teamControl.setValue(team.name);
      let teamShortNameControl = this.teamForm.get(`${team.id}-short-name`);
      teamShortNameControl.setValue(team.short_name);
      let teamMappingsControl = this.teamForm.get(`${team.id}-mappings`);
      teamMappingsControl.setValue(this.extractMappings(team));
      this.addMessage(team.name, 'Changes undone');
    }
  }

  protected findLeagueLabel(id: number) {
    return (this.leagues.find(lg => id === lg.id) || EMPTY_LEAGUE).label;
  }

  private addMessage(teamName: string, message = '', isError = false) {
    this.formMessages[teamName] = message;
    this.hasError[teamName] = isError;
    setTimeout(() => {
      delete this.formMessages[teamName];
      this.hasError[teamName] = false;
    }, 2000);
  }

  private extractMappings(team: TeamInterface): string {
    return team.mappings.join('\n');
  }

  private gatherMappings(id, leagueId) {
    return this.teamForm.get(`${id}-mappings`).value
      .split('\n')
      .filter(mapping => mapping)
      .map((mapping: string) => mapping.toLocaleLowerCase().trim())
    ;
  }
}
