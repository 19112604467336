/* Copyright © 2018 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, Input, OnInit } from '@angular/core';

import { WagertypesService } from './wagertypes.service';

@Component({
  selector: 'single-wagertype',
  templateUrl: 'wagertypes/singleWagertype.component.html'
})
export class SingleWagertypeComponent implements OnInit {
  @Input()
  protected wagertype: number;
  protected transformedWagertype = '';

  constructor(private wagertypesService: WagertypesService) { /**/ }

  public ngOnInit(): void {
    if (this.wagertype || (Number(this.wagertype) === 0)) {
      this.wagertypesService.fetch(String(this.wagertype)).subscribe(wagertype => {
        this.transformedWagertype = wagertype.name;
      }, err => {
        this.transformedWagertype = String(this.wagertype);
      });
    }
  }
}
