/* Copyright © 2017-2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { toArray } from 'gs-utils/lib/utilities';

import {Validator, ValidationResponse} from './validator';
import {User} from '../models/user';


export class UserValidator extends Validator<User> {
  public validate(id: string, instance: User): ValidationResponse {
    let errors = [];
    if (!id) {
      errors.push('Must supply a user name.');
    }

    if (!instance) {
      errors.push('Must supply a user.');
    }

    if (!instance.passwordHash) {
      errors.push('Must supply a password hash.');
    } else if (toArray(instance.passwordHash).every(p => typeof p !== 'string')) {
      errors.push('Password hash must be a string.');
    }

    if (!instance.passwordSalt) {
      errors.push('Must supply a password salt.');
    } else if (typeof instance.passwordSalt !== 'string') {
      errors.push('Password salt must be a string.');
    }

    if (!instance.permissions) {
      errors.push('Must supply a permissions block.');
    } else if (typeof instance.permissions !== 'object') {
      errors.push('Permissions must be an object.');
    }

    if (errors.length) {
      return {
        success: false,
        reasons: errors
      };
    }
    return {
      success: true
    };
  }
}
