/* Copyright © 2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit } from '@angular/core';

import { LimitGroupsService } from './limitGroups.service';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { take } from 'rxjs';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { SnackBarService } from '../services/snackbar.service';

@Component({
  selector: 'limit-groups',
  templateUrl: 'wagertypes/limitGroups.component.html',
  styles: [
    require('./limitGroups.component.scss'), // tslint:disable-line
  ],
  providers: [LimitGroupsService],
})
export class LimitGroupsComponent implements OnInit {
  protected creating = false;
  protected limitGroups: { id?: number; name: string }[] = [];

  constructor(private limitGroupsService: LimitGroupsService, private snackbarService: SnackBarService) {}

  protected context = { controller: this };
  protected columnDefs: ColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      resizable: false,
      sortable: false,
      filter: false,
      cellRendererFramework: LimitGroupsActionsCellRenderer,
    },
  ];
  protected defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  };

  protected getRowNodeId(data: { id?: number; name: string }): number {
    return data.id;
  }

  public ngOnInit(): void {
    this.limitGroupsService
      .list()
      .pipe(take(1))
      .subscribe((limitGroups) => {
        this.limitGroups = Object.values(limitGroups);
        this.sort();
      });
  }

  protected doSave({ name, id }: { id?: number; name: string }): void {
    const group = this.limitGroups.find((st) => st.id === id);
    if (group.name === name) {
      this.snackbarService.addErrorMessage('Nothing to do');
    } else {
      this.limitGroupsService.save(id, { id, name }).subscribe(
        (newGroup) => {
          this.snackbarService.addSuccessMessage(JSON.stringify(newGroup));
          this.limitGroups = this.limitGroups.map((st) => {
            if (st.id === newGroup.id) {
              st.name = newGroup.name;
            }
            return st;
          });
          this.sort();
        },
        (err) => {
          this.snackbarService.addErrorMessage(JSON.stringify(err));
        }
      );
    }
  }

  protected doCreate(name: string): void {
    if (name) {
      this.limitGroupsService.create({ name }).subscribe(
        (newGroup) => {
          this.creating = !this.creating;
          this.snackbarService.addSuccessMessage(JSON.stringify(newGroup));
          if (!this.limitGroups.find((st) => st.id === newGroup.id)) {
            this.limitGroups = this.limitGroups.concat(newGroup);
          }
        },
        (err) => {
          this.snackbarService.addErrorMessage(err.error || err.message || JSON.stringify(err));
          this.creating = !this.creating;
        }
      );
    } else {
      this.creating = !this.creating;
      this.snackbarService.addErrorMessage('Invalid Name');
    }
  }

  private sort(): void {
    this.limitGroups.sort((s1, s2) => s1.id - s2.id);
  }
}

@Component({
  template: ` <button
    color="primary"
    (click)="save()"
    mat-raised-button>
    Save
  </button>`,
})
export class LimitGroupsActionsCellRenderer implements ICellRendererAngularComp {
  private params: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  protected get data(): { id?: number; name: string } {
    return this.params.data;
  }

  public refresh(): boolean {
    return true;
  }

  protected save(): void {
    this.params.context.controller.doSave(this.data);
  }
}
