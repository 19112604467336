/* Copyright © 2017-2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseRequestorService } from '../baseRequestor.service';
import { Countries, AdminCountryInterface } from '../../server/shared/models/gsModel';
import { CountryValidator } from '../../server/shared/validation/countryValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';


export const EMPTY_LEAGUE = {
  label: '', name: ''
};

@Injectable()
export class CountriesService extends BaseRequestorService<AdminCountryInterface, Countries> {
  private countryValidator = new CountryValidator();  // TODO: should be injected

  constructor(protected http: HttpClient) {
    super('countries', http, 30000);
  }

  public validate(id: string, model: AdminCountryInterface): ValidationResponse {
    return this.countryValidator.validate(id, model);
  }
}
