/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseRequestorService } from '../baseRequestor.service';
import { Leagues, AdminLeagueInterface } from '../../server/shared/models/gsModel';
import { LeagueValidator } from '../../server/shared/validation/leagueValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';


export const EMPTY_LEAGUE = {
  label: '', name: ''
};

@Injectable()
export class LeaguesService extends BaseRequestorService<AdminLeagueInterface, Leagues> {
  private leagueValidator = new LeagueValidator();  // TODO: should be injected

  constructor(protected http: HttpClient) {
    super('leagues', http, 30000);
  }

  public validate(id: string, model: AdminLeagueInterface): ValidationResponse {
    return this.leagueValidator.validate(id, model);
  }
}
