/* Copyright © 2021-2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'wagertypeFilter' })
export class WagertypePipe implements PipeTransform {
  public transform(input: { key: string; value: any }[], wagertype: number): { key: string; value: any }[] {
    return input.filter(item => {
      return wagertype === -1 ||
        +item.value.wagertype === wagertype ||
        +item.value.correlatedWagertype === wagertype
      ;
    });
  }
}

