/* Copyright © 2018 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PlayerInterface } from '../../server/shared/models/player';

import { BaseRequestorService } from '../baseRequestor.service';
import { Players } from '../../server/shared/models/gsModel';
import { PlayerValidator } from '../../server/shared/validation/playerValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';


@Injectable()
export class PlayersService extends BaseRequestorService<PlayerInterface, Players> {
  private playerValidator = new PlayerValidator();  // TODO: should be injected

  constructor(protected http: HttpClient) {
    super('players', http);
  }

  public validate(id: string, model: PlayerInterface): ValidationResponse {
    return this.playerValidator.validate(id, model);
  }
}
