/* Copyright © 2021-2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'periodFilter' })
export class PeriodPipe implements PipeTransform {
  public transform(input: { key: string; value: any }[], period: number): { key: string; value: any }[] {
    return input.filter(item => {
      return period === -1 ||
        item.value.period === period ||
        item.value.correlatedPeriod === period
      ;
    });
  }
}

