/* Copyright © 2017-2018 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component } from '@angular/core';

@Component({
  selector: 'task-menu',
  templateUrl: 'taskMenu/taskMenu.component.html',
  styles: [
    require('./taskMenu.component.scss') // tslint:disable-line
  ]
})
export class TaskMenuComponent {
  private pages = [
    { name: 'Events', href: ['', 'events'] },
    { name: 'Leagues', href: ['', 'leagues'] },
    { name: 'Sports', href: ['', 'sports'] },
    { name: 'Countries', href: ['', 'countries'] },
    { name: 'Teams', href: ['', 'teams'] },
    { name: 'Players', href: ['', 'players'] },
    { name: 'Expire Times', href: ['', 'expireTimes'] },
    { name: 'Offer Types', href: ['', 'offertypeList'] },
    { name: 'Wager Types', href: ['', 'wagertypes'] },
    { name: 'Users', href: ['', 'users'] },
    { name: 'Groups', href: ['', 'groups'] },
    { name: 'Mediator', href: ['', 'mediator'] },
    { name: 'Matchups', href: ['', 'matchups'] },
    { name: 'Spelunk', href: ['', 'spelunk'] },
    { name: 'Game Partitions', href: ['', 'game-partitions'] },
    { name: 'Scoring rules', href: ['', 'scoring-rules'] },
    { name: 'Stores', href: ['', 'stores'] }
  ];
}
