/* Copyright © 2018-2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseRequestorService } from '../baseRequestor.service';

@Injectable()
export class GamePartitionService extends BaseRequestorService<any, any> {
  constructor(protected http: HttpClient) {
    super('gamePartitions', http);
  }

  public getPartition(query: { partner; partition }) {
    return this.fetch(`${query.partner}/${query.partition}`);
  }

  public extractPartitions(result = {}) {
    return Object.keys(result).map(key => ({ key, value: result[key] }));
  }

  public deletePartition(query: { partner; partition; id }) {
    let [period, wagertype] = query.id.split(':');
    let args: { period: string; wagertype?: string } = { period };
    if (+wagertype >= 0) {
      args.wagertype = wagertype;
    }
    return this.delete(`${query.partner}/${query.partition}`, args);
  }
}
