/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const BLOCKED_EVENTS_PATH = '/api/blockSourceBook';

@Injectable()
export class BlockedBooksService {

  constructor(protected http: HttpClient) {
    /**/
  }

  public save(key: string, body: Object): Observable<any> {
    return this.http.put(`${BLOCKED_EVENTS_PATH}/${key}`, body, { withCredentials: true });
  }

  public list(key: string): Observable<any> {
    return this.http.get(`${BLOCKED_EVENTS_PATH}/${key}`);
  }
}
