/* Copyright © 2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';

@Injectable()
export class PlatformService {

  public sameGameParlayOptions: Object;

  private iframe: HTMLIFrameElement;

  constructor() { /**/ }

  public clear() {
    this.iframe = null;
  }

  public initialize(iframe: HTMLIFrameElement) {
    this.iframe = iframe;
    let handlers = [
      this.betSlipContentsHandler.bind(this),
      this.betSlipContentsListener.bind(this)
    ];
    handlers.forEach(handler => {
      window.removeEventListener('message', handler);
      window.addEventListener('message', handler);
    });
    this.getBetSlipContents();
  }

  private betSlipContentsListener({ data }) {
    let contents = data['bet-slip-item-added'];
    if (
      data['bet-slip-item-added'] || data['bet-slip-item-removed'] || data['bet-slip-type-changed']
    ) {
      this.getBetSlipContents();
    }
  }

  private getBetSlipContents() {
    this.iframe.contentWindow.postMessage({ 'get-bet-slip-contents': {} }, '*');
  }

  private betSlipContentsHandler({ data }) {
    let contents = data['get-bet-slip-contents'];
    if (!contents) {
      return;
    }
    this.sameGameParlayOptions = undefined;
    if (contents.betType === 'parlay' &&
      contents.betItems.length >= 2 && contents.betItems.every(bet => {
        return bet.eventId === contents.betItems[0].eventId;
      })
    ) {
      let options = [];
      let descriptions = contents.description.split(' & ');
      for (let i = 0; i < contents.betItems.length; i++) {
        for (let j = i + 1; j < contents.betItems.length; j++) {
          let [rotation, period, wagertype, side, figure] = this.getParlayOptions(contents.betItems[i]);
          let [correlatedRotation, correlatedPeriod, correlatedWagertype, correlatedSide, correlatedFigure] =
            this.getParlayOptions(contents.betItems[j])
          ;
          let [correlatedMinFigure, correlatedMaxFigure] = this.getWagertypeOptions(+correlatedWagertype, correlatedFigure);
          let [minFigure, maxFigure] = this.getWagertypeOptions(+wagertype, figure);
          options.push({
            rotation, period, wagertype, side, minFigure, maxFigure,
            correlatedPeriod, correlatedWagertype, correlatedSide, correlatedMinFigure,
            correlatedMaxFigure, comment: [descriptions[i], descriptions[j]].join(' & '),
            allowSameMarket: wagertype === correlatedWagertype && contents.betItems[i].pfm
          });
        }
      }
      this.sameGameParlayOptions = options;
    } else {
      this.sameGameParlayOptions = undefined;
    }
  }

  private getWagertypeOptions(wagertype: number, figure: number): number[] {
    if ([5, 6, 7, 8].includes(wagertype)) {
      return [(figure | 0) - 0.5, (figure | 0) + 0.5]; // tslint:disable-line:no-bitwise
    }
    return [figure, figure];
  }

  private getParlayOptions(bet: any): any[] {
    return [
      bet.eventId,
      this.findPeriod(bet.groupId),
      bet.marketId,
      bet.oddsId,
      bet.line
    ];
  }

  private findPeriod(code: string): number {
    let period = 0;
    switch (code) {
      case 'h1':
        period = 1;
        break;
      case 'h2':
        period = 2;
        break;
      default:
        if (/^s/.test(code)) {
          period = +code.replace(/^s/, '') + 2;
        }
        break;
    }
    return period;
  }
}
