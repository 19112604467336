/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseRequestorService } from '../baseRequestor.service';
import { User, Users } from '../../server/shared/models/user';
import { UserValidator } from '../../server/shared/validation/userValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';


@Injectable()
export class UsersService extends BaseRequestorService<User, Users> {
  private userValidator = new UserValidator();  // TODO: should be injected

  constructor(protected http: HttpClient) {
    super('users', http);
  }

  public validate(id: string, model: User): ValidationResponse {
    return this.userValidator.validate(id, model);
  }
}
