/* Copyright © 2017-2022 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings } from '../multiselect/multiselect.types';

import { FilterListPipe } from '../filters/filterListPipe.filter';

import { WagertypesService } from './wagertypes.service';

@Component({
  selector: 'wagertypes-drop-down',
  templateUrl: 'wagertypes/wagertypesDropDown.component.html',
  styles: [
    require('./wagertypesDropDown.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe]
})

export class WagertypesDropDownComponent implements OnInit {
  protected manualChange = false;
  protected selectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 2,
    containerClasses: 'wagertypes-drop-down--select--container',
    buttonClasses: 'form-control wagertypes-drop-down--select--selector',
    checkedStyle: 'glyphicon',
    showUncheckAll: true
  };
  private singleSelection = false;

  @Input() public simple = false;
  @Input() protected header = 'Select Wagertypes';
  @Input() protected selectedWagertypeIds: number[] = [];
  @Input() protected enableSelection = true;
  @Input() protected selectionLimit;
  @Input() protected wagertypeOptions: IMultiSelectOption[] = [];

  @Output() protected wagertypesChanged = new EventEmitter<{wagertype: number, wagertypeIds: number[], labels: string[]}>();

  constructor(private wagertypesService: WagertypesService) { /**/ }

  public ngOnInit(): void {
    if (this.selectionLimit) {
      this.singleSelection = this.selectionLimit === 1;
      this.selectSettings.selectionLimit = this.selectionLimit;
      this.selectSettings.closeOnSelect = this.singleSelection;
    }
  }

  protected getWagertypeLabel(wagertype: { id: number; name: string }): string {
    return `${wagertype.id}: ${wagertype.name}`;
  }

  protected handleWagertypesChanged() {
    if (this.manualChange) {
      let selectedWagertypeIds = this.selectedWagertypeIds;
      this.wagertypesChanged.emit({
        wagertype: this.singleSelection ? this.selectedWagertypeIds[0] ?? null : null,
        wagertypeIds: !this.singleSelection ? selectedWagertypeIds : null,
        labels: (selectedWagertypeIds || [])
          .map(id => (this.wagertypeOptions.find(opt => Number(opt.id) === id) || { label: '', name: '' }).name)
          .filter(name => !!name)
      });
    }
  }

  private sort(): void {
    this.wagertypeOptions.sort((u1, u2) => Number(u1.id) - Number(u2.id));
  }
}
