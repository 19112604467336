/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ScoringRuleMap } from '../../server/shared/models/gsModel';
import { BaseRequestorService } from '../baseRequestor.service';

import { ScoringRuleMapInterface } from '../../server/shared/models/scoringRule';

@Injectable()
export class ScoringRulesMapperService extends BaseRequestorService<ScoringRuleMapInterface, ScoringRuleMap> {

  constructor(protected http: HttpClient) {
    super('scoringRulesMapper', http);
  }
}
