/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, Input, OnInit } from '@angular/core';

import { toArray, lastItem } from 'gs-utils/lib/utilities';

import { StoresService } from './stores.service';


@Component({
  selector: 'stores',
  templateUrl: 'stores/stores.component.html',
  styles: [
    require('./stores.component.scss')  // tslint:disable-line
  ],
  providers: [StoresService]
})

export class StoresComponent implements OnInit {
  @Input()
  protected source: string;
  protected stores: { id: string; name: string }[] = [];

  constructor(
    private storesService: StoresService,
  ) { /**/ }

  public ngOnInit(): void {
    this.storesService.listWithSource(this.source).subscribe(stores => {
      this.stores = stores.map(store => {
        let [id, name] = toArray(store);
        name ||= id;
        return { id, name };
      });
    });
  }

  protected doCreate(store) {
    if (this.stores.find(st => st.name.toLowerCase() === store.toLowerCase())) {
      alert(`${store} already exists`);
    } else {
      this.storesService.createWithSource(this.source, { store }).subscribe(res => {
        alert(res);
        if (lastItem(res as any) === store) {
          this.stores = this.stores.concat({ id: store, name: store });
        }
      });
    }
  }
}
