/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Directive, HostBinding, HostListener } from '@angular/core';
import { DialogService } from './dialog.service';

@Directive({
  selector: '[dialogOverlay]'
})
export class DialogOverlayDirective {
  @HostBinding('class.gs-overlay--open') private isModalOpen = false;

  constructor(private dialogService: DialogService) {
    this.dialogService.isShowingModal$.subscribe(isShowing => {
      this.isModalOpen = isShowing;
    });
  }

  @HostListener('click')
  private onClick() {
    this.dialogService.handleOverlayClick();
  }
}
