/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'stores-header',
  templateUrl: 'stores/header.component.html',
  styles: [
    require('./header.component.scss') // tslint:disable-line
  ],
  providers: []
})
export class StoresHeaderComponent implements OnInit {
  protected mode: string;
  protected sources: string[];
  constructor(private http: HttpClient) { /**/ }

  public ngOnInit() {
    this.http.get<{ sources: string[] }>('/api/stores', {}).subscribe(sources => {
      this.sources = sources.sources;
    });
  }
}
