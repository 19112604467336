/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { GsModel } from '../models/gsModel';

export interface ValidationResponse {
  success: boolean;
  reasons?: string[];
}

export abstract class Validator<T extends GsModel> {
  public abstract validate(id: string, instance: T, optionalArgs?: any): ValidationResponse;
}
