/* Copyright © 2022-2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit, ViewChild } from '@angular/core';
import { dup } from 'gs-utils/lib/utilities';
import { delay } from 'gs-utils/lib/delay';
import { CorrelationValidator } from '../../server/shared/validation/correlationValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';
import { CorrelationInterface, AdminWagertypeInterface, Correlations } from '../../server/shared/models/gsModel';
import { WagertypesService } from '../wagertypes/wagertypes.service';
import { PeriodsService } from '../periods/periods.service';
import { CorrelationsService } from './correlations.service';
import { LeagueCorrelationsService } from './leagueCorrelations.service';
import { AgGridAngular, ICellRendererAngularComp } from 'ag-grid-angular';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { SnackBarService } from '../services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { CorrelationsFormComponent } from './correlationsForm.component';
import { combineLatest } from 'rxjs';

const PASSTHRU_FIGURE = 3.14159;
const PASSTHRU_FIGURE_OVERLAPPING = PASSTHRU_FIGURE * 2;
const WT_PREFIX = '_';
const SYSTEM_WAGERTYPES = [
  {
    long_name: '3Way Moneyline',
    name: '3way moneyline',
    id: -3,
    platformWagertype: 1,
    behavior: 0,
    baalm_name: '3way_moneyline',
    short_name: '3way',
    feed_source: 'GSAdmin',
  },
  {
    long_name: 'Double Chance',
    name: 'double chance',
    id: -2,
    platformWagertype: 2,
    behavior: 0,
    baalm_name: 'double_chance',
    short_name: 'DC',
    feed_source: 'GSAdmin',
  },
  {
    long_name: 'Draw No Bet',
    name: 'draw no bet',
    id: -4,
    platformWagertype: 4,
    behavior: 0,
    baalm_name: 'draw_no_bet',
    short_name: 'DNB',
    feed_source: 'GSAdmin',
  },
];

export abstract class BaseCorrelationsComponent implements OnInit {
  protected correlationsService: CorrelationsService | LeagueCorrelationsService;
  protected wagertypesService: WagertypesService;
  protected periodsService: PeriodsService;
  protected rotations: number[];
  protected sportNames: string[];
  protected leagueNames: string[];
  protected idType: string;
  private correlations: Record<CorrelationInterface['classifier'], CorrelationInterface> = {};
  private wagertypes: AdminWagertypeInterface[] = [];
  private periodsByRotation: Record<number, { id: number; name: string }[]> = {};
  private selected: Set<string> = new Set();
  private validator = new CorrelationValidator();
  protected sideOptions = [];
  protected isLeagueCorrelation = false;
  protected groupedCorrelations: Record<string, CorrelationInterface[]> = {};

  @ViewChild(AgGridAngular) protected agGrid: AgGridAngular;
  protected columnDefs: ColDef[] = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      field: 'period',
      headerName: 'Period',
      valueGetter: ({ data: { value } }: { data: { value: CorrelationInterface[] } }) => {
        return this.getPeriod(value[0].rotation, value[0].period);
      },
    },
    {
      field: 'wagertype',
      headerName: 'Wagertype',
      valueGetter: ({ data: { value } }: { data: { value: CorrelationInterface[] } }) => {
        return this.getWagertypeName(value[0].wagertype);
      },
    },
    {
      field: 'correlatedPeriod',
      headerName: 'Correlated Period',
      valueGetter: ({ data: { value } }: { data: { value: CorrelationInterface[] } }) => {
        return this.getPeriod(value[0].rotation, value[0].correlatedPeriod);
      },
    },
    {
      field: 'correlatedWagertype',
      headerName: 'Correlated Wagertype',
      valueGetter: ({ data: { value } }: { data: { value: CorrelationInterface[] } }) => {
        return this.getWagertypeName(value[0].correlatedWagertype);
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      cellRendererFramework: CorrelationsActionsCellRenderer,
    },
  ];

  protected context = { controller: this };
  protected defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    filterParams: { newRowsAction: 'keep' },
  };

  constructor(private snackBarService: SnackBarService, protected dialogService: MatDialog) {
    this.snackBarService.duration = 3 * 1000;
  }

  public async ngOnInit(): Promise<void> {
    await this.getWagertypes();
    await this.getPeriods();
    await this.fetchCorrelations();
  }

  protected async fetchCorrelations() {
    await Promise.all(
      this.rotations.map(async (rotation) => {
        await this.getCorrelations(rotation);
        await delay(0.1);
      })
    );
    this.groupedCorrelations = Object.values(this.correlations).reduce((acc, item) => {
      const key = this.getGroupedCorrelationsKey(item);
      (acc[key] ||= []).push(item);
      return acc;
    }, {});
  }

  protected getRowNodeId(data: { key: string }): string {
    return data.key;
  }

  protected rotationLabelGetter(rotation: number): string | number {
    return rotation;
  }

  protected getSide(side: number): string | number {
    return side as number;
  }

  protected get items(): { key: string; value: number }[] {
    return this.rotations.map((rot) => ({ key: String(rot), value: rot }));
  }

  protected getGroupedCorrelationsKey(item: CorrelationInterface = Object.create(null)) {
    return `${item.rotation}-${item.period}-${item.wagertype}-${item.correlatedPeriod}-${item.correlatedWagertype}`;
  }

  protected onRowSelected(): void {
    this.selected = new Set(this.agGrid.api.getSelectedRows().map(({ key }) => key));
  }

  protected getWagertypeName(id: number): string {
    return this.getWagertype(id).name;
  }

  protected getPeriod(rotation: number, id: number): string {
    return this.periodsByRotation[rotation][id]?.name || `${id}`;
  }

  protected getFigureLabel(figure: number): string {
    if (+figure === PASSTHRU_FIGURE) {
      return 'ALL';
    }
    if (+figure === PASSTHRU_FIGURE_OVERLAPPING) {
      return 'ALL OVERLAPPING';
    }
    return String(figure);
  }

  protected validate(correlationName: string, correlations: Correlations): ValidationResponse {
    return this.validator.validate(correlationName, correlations);
  }

  protected doSave(correlations: CorrelationInterface[]): void {
    const corr = correlations.map((c) => dup(c));
    this.correlationsService.batchSave(corr).subscribe(
      async () => {
        this.snackBarService.addSuccessMessage(this.prettify(corr[0]));
        await this.fetchCorrelations();
      },
      (err) => {
        this.snackBarService.addErrorMessage(err.error || err.message || JSON.stringify(err));
      }
    );
  }

  protected doDelete(classifier: string) {
    const correlationsObs = this.groupedCorrelations[classifier].map((c) => {
      const correlation = { ...c, value: -1, timestamp: Date.now() };
      return this.correlationsService.save(correlation.rotation, correlation);
    });
    combineLatest(correlationsObs).subscribe(
      async () => {
        this.snackBarService.addSuccessMessage(`${this.prettify(this.groupedCorrelations[classifier][0])}`);
        await this.fetchCorrelations();
      },
      (err) => {
        this.snackBarService.addErrorMessage(err.error || err.message || JSON.stringify(err));
      }
    );
  }

  protected takeDownAll() {
    const selected = new Set<string>();
    this.agGrid.api.forEachNodeAfterFilter(({ data }) => {
      selected.add(data.key);
    });
    this.takeDownSelected(selected);
  }

  protected takeDownSelected(selected = this.selected) {
    if (!selected.size) {
      this.snackBarService.addErrorMessage('Nothing to do');
      return;
    }
    let selectedList = [...selected];
    selected.clear();
    this.agGrid.api.deselectAll();
    this.correlationsService
      .batchSave(
        selectedList.flatMap((classifier) => this.groupedCorrelations[classifier].map((c) => ({ ...c, value: -1, timestamp: Date.now() })))
      )
      .subscribe(
        async () => {
          this.snackBarService.addSuccessMessage(this.prettify(this.groupedCorrelations[selectedList[0]][0]));
          await this.fetchCorrelations();
        },
        (err) => {
          this.snackBarService.addErrorMessage(err.error || err.message || JSON.stringify(err));
        }
      );
  }

  protected doCreate(correlation: CorrelationInterface) {
    if (!correlation) {
      return this.snackBarService.addErrorMessage('Nothing to do');
    }
    correlation = dup(correlation);
    if (
      String(correlation.minFigure || '')
        .trim()
        .toLowerCase() === 'all'
    ) {
      correlation.minFigure = PASSTHRU_FIGURE;
      correlation.maxFigure = PASSTHRU_FIGURE;
    }
    if (
      String(correlation.correlatedMinFigure || '')
        .trim()
        .toLowerCase() === 'all'
    ) {
      correlation.correlatedMinFigure = PASSTHRU_FIGURE;
      correlation.correlatedMaxFigure = PASSTHRU_FIGURE;
    }
    if (
      String(correlation.minFigure || '')
        .trim()
        .toLowerCase() === 'allx'
    ) {
      correlation.minFigure = PASSTHRU_FIGURE_OVERLAPPING;
      correlation.maxFigure = PASSTHRU_FIGURE_OVERLAPPING;
    }
    if (
      String(correlation.correlatedMinFigure || '')
        .trim()
        .toLowerCase() === 'allx'
    ) {
      correlation.correlatedMinFigure = PASSTHRU_FIGURE_OVERLAPPING;
      correlation.correlatedMaxFigure = PASSTHRU_FIGURE_OVERLAPPING;
    }
    this.correlationsService.create(correlation).subscribe(
      async () => {
        this.snackBarService.addSuccessMessage(this.prettify(correlation));
        await this.fetchCorrelations();
      },
      (err) => {
        this.snackBarService.addErrorMessage(err.error || err.message || JSON.stringify(err));
      }
    );
  }

  private prettify(correlation: CorrelationInterface): string {
    return [
      `${this.idType} ${correlation.rotation} period ${this.getPeriod(correlation.rotation, correlation.period)}`,
      `${this.getWagertypeName(correlation.wagertype)} saved!`,
    ].join(' ');
  }

  private getWagertype(id: number): AdminWagertypeInterface {
    return this.wagertypes.find((wt) => wt.id === +id);
  }

  private getWagertypes(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.wagertypesService.list().subscribe(
        (wagertypes) => {
          this.wagertypes = Object.values(wagertypes)
            .concat(SYSTEM_WAGERTYPES)
            .sort((wt1, wt2) => {
              let prefix1 = wt1.id < 5 ? WT_PREFIX : '';
              let prefix2 = wt2.id < 5 ? WT_PREFIX : '';
              return `${prefix1}${wt1.name}`.localeCompare(`${prefix2}${wt2.name}`);
            })
            .map((wt) => {
              wt.id = +(wt as AdminWagertypeInterface).platformWagertype;
              return wt;
            });
          resolve();
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  private getCorrelations(rotation: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.correlationsService.list({ rotation }).subscribe(
        (correlations) => {
          correlations.forEach((correlation) => {
            let classifier = correlation.classifier;
            this.correlations[classifier] = correlation;
          });
          resolve();
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }

  private async getPeriods(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.periodsService.list().subscribe(
        async (periods) => {
          this.periodsByRotation = this.foldPeriodsByRotation(periods);
          resolve();
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  private foldPeriodsByRotation(periods: Record<string, string[]>): Record<number, { id: number; name: string }[]> {
    return this.rotations.reduce((obj, rot, idx) => {
      obj[rot] = (periods[this.leagueNames[idx]] || periods[this.sportNames[idx]] || periods.default || periods.basketball)
        .map((name, id) => {
          return { id, name };
        })
        .sort((p1, p2) => p1.id - p2.id);
      return obj;
    }, {});
  }

  protected openDialog(item: CorrelationInterface): void {
    const key = this.getGroupedCorrelationsKey(item);
    const dialogRef = this.dialogService.open(CorrelationsFormComponent, {
      data: {
        correlations: item ? this.groupedCorrelations[key] : [],
        rotations: this.rotations,
        periodsByRotation: this.periodsByRotation,
        wagertypes: this.wagertypes,
        sideOptions: this.sideOptions,
        isLeagueCorrelation: this.isLeagueCorrelation,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.toCreate.length) {
        result.toCreate.forEach((c) => {
          this.doCreate(c);
        });
      }
      if (result && result.toUpdateOrDelete.length) {
        this.doSave(result.toUpdateOrDelete);
      }
    });
  }
}

@Component({
  template: `<button
      mat-button
      color="primary"
      (click)="edit()">
      Edit
    </button>
    <button
      mat-button
      color="warn"
      (click)="delete()">
      Delete
    </button>`,
})
export class CorrelationsActionsCellRenderer implements ICellRendererAngularComp {
  private params: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  protected get data(): CorrelationInterface {
    return this.params.data.value[0];
  }

  protected edit(): void {
    this.params.context.controller.openDialog(this.data);
  }

  protected delete(): void {
    this.params.context.controller.doDelete(this.params.data.key);
  }

  public refresh(): boolean {
    return true;
  }
}
