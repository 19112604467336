/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
'use strict';

import {
  Component,
  Input,
  ViewChild,
  AfterViewInit,
  OnChanges,
  Output,
  EventEmitter,
  Injector,
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  AfterViewChecked,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'gs-dialog',
  templateUrl: 'dialog/dialog.component.html',
  styles: [
    require('./dialog.component.scss')  // tslint:disable-line
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent implements AfterViewInit, OnInit {
  @Input() public hasCancel = true;
  @Input() public content;
  @Input() public title;
  @Input() public cancelLabel = 'Cancel';
  @Input() public confirmLabel = 'Confirm';
  @Input() public useTextArea = false;
  @Output() public close = new EventEmitter();
  public disableConfirm = false;
  @ViewChild('body') protected dialogBody;
  private isContentReady = new BehaviorSubject(false);
  private contentRef;

  constructor(
    protected sanitizer: DomSanitizer,
    private componentResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private changeDetector: ChangeDetectorRef
  ) { /**/ }

  public ngOnInit() {
    this.isContentReady.asObservable().subscribe(readyToRender => {
      if (readyToRender && this.content) {
        this.contentRef?.instance?.canConfirm.subscribe(canConfirm => {
          this.disableConfirm = !canConfirm;
          this.changeDetector.detectChanges();
        });
      }
      this.changeDetector.detectChanges();
    });
  }

  public ngAfterViewInit() {
    this.renderBody(this.content);
  }

  public emitCancel() {
    this.close.emit(false);
  }

  public async emitSuccess() {
    if (this.contentRef && this.contentRef.instance.onClose) {
      try {
        let [err, result] = await this.contentRef.instance.onClose();
        if (!err) {
          this.close.emit(result);
        }
      } catch (e) { /**/ }
    } else {
      this.close.emit(true);
    }
  }

  private renderBody(content) {
    if (typeof content === 'string') {
      if (this.useTextArea) {
        const textarea = document.createElement('textarea');
        textarea.classList.add('gs-textarea');
        textarea.innerHTML = content;
        this.dialogBody.nativeElement.appendChild(textarea);
      } else {
        this.dialogBody.nativeElement.innerHTML = content;
      }
      this.isContentReady.next(true);
    } else {
      this.contentRef = this.componentResolver
        .resolveComponentFactory<Component>(content)
        .create(this.injector);
      this.appRef.attachView(this.contentRef.hostView);
      const domElem = (this.contentRef.hostView as EmbeddedViewRef<typeof content>).rootNodes[0] as HTMLElement;
      this.dialogBody.nativeElement.appendChild(domElem);
      this.isContentReady.next(true);
    }
  }

}
