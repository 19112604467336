/* Copyright © 2017-2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { toArray, dup } from 'gs-utils/lib/utilities';

import { WagertypeValidator } from '../../server/shared/validation/wagertypeValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';
import { AdminWagertypeInterface as WagertypeInterface } from '../../server/shared/models/gsModel';

import { FilterListPipe } from '../filters/filterListPipe.filter';

import { WagertypesService } from './wagertypes.service';
import { MarketTypeCategoriesService } from './marketTypeCategories.service';
import { StatisticTypesService } from './statisticTypes.service';
import { LimitGroupsService } from './limitGroups.service';
import { DisplayStylesService } from './displayStyles.service';
import { ColDef } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';

const BEHAVIORS = ['moneyline', 'spread', 'total / contest'];
const wtAttrToOptionList = {
  marketTypeCategoryId: 'marketTypeCategories',
  statisticId: 'statisticTypes',
  gradingType: 'gradingTypes',
  limitGroupId: 'limitGroups'
};
const formatSelectOption = (useId = true) => {
  return (o: any) => ({
    id: o.id ?? o,
    text: `${o.name ?? o} ${useId ? `(${o.id})` : ''}`
  });
};

@Component({
  selector: 'wagertypes',
  templateUrl: 'wagertypes/wagertypes.component.html',
  styles: [
    require('./wagertypes.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe, MarketTypeCategoriesService, StatisticTypesService, DisplayStylesService, LimitGroupsService]
})

export class WagertypesComponent implements OnInit {
  @Input()
  protected wagertypes: WagertypeInterface[] = [];
  protected copiedWagertypes: WagertypeInterface[] = [];

  protected wagertypeFilter = '';

  protected limitGroups: { id?: number; name: string }[] = [];
  protected statisticTypes: { id?: number; name: string }[] = [];
  protected marketTypeCategories: { id?: number; name: string }[] = [];
  protected displayStyles: { id: number; name: string }[] = [];
  protected displayStyleIds: Record<number, { id: number; name: string }> = {};

  protected creating = false;
  protected gradingTypes = [
    'default',
    'threeway',
    'dnb_for_threeway',
    'contest',
    'race_to',
    'point_winner',
    'none'
  ];

  private validator = new WagertypeValidator();
  @ViewChild(AgGridAngular) protected agGrid: AgGridAngular;
  protected columnDefs: ColDef[] = [
    ['id', 'ID'],
    ['name', 'Name'],
    ['long_name', 'Description'],
    ['short_name', 'BAALM Abbreviation'],
    ['baalm_name', 'BAALM Directive Name'],
    ['translateKey', 'Translate Key'],
    ['behavior', 'Baalm Behavior'],
    ['platformWagertype', 'Platform']
  ].map(([field, headerName]) => ({
    field,
    headerName,
    ...(!['id', 'baalm_name', 'behavior'].includes(field) && ({
      editable: true,
      cellEditor: 'agTextCellEditor'
    })),
    ...(field === 'behavior' && ({
      cellRenderer: ({ data }) => {
        const span = document.createElement('span');
        span.textContent = this.displayBehavior(data.behavior);
        return span;
      }
    }))
  }) as ColDef)
  .concat({
    field: 'platformStyle',
    headerName: 'Platform Style',
    cellRenderer: ({ data }) => {
      const span = document.createElement('span');
      if (!this.needsPlatformStyle(data.platformWagertype)) {
        span.textContent = this.effectiveDisplayStyle(data);
      } else {
        const options = [{ id: '', text: ''}].concat(this.displayStyles.filter(d => {
          return String(d.id) !== d.name;
        }).map(formatSelectOption()));
        const select = this.createSelectElement((options), data, 'platformStyleId');
        span.appendChild(select);
      }
      return span;
    }},
    ...[
      ['marketTypeCategoryId', 'Market Category'],
      ['statisticId', 'Statistic'],
      ['gradingType', 'Grading Type'],
      ['limitGroupId', 'Limit Group']
    ].map(([field, headerName]) => ({
      field,
      headerName,
      cellRenderer: ({ data }) => {
        const span = document.createElement('span');
        const listName = wtAttrToOptionList[field];
        const formatedOptions = [{ id: '', text: ''}].concat(this[listName].map(formatSelectOption(false)));
        const select = this.createSelectElement(formatedOptions, data, field);
        span.appendChild(select);
        return span;
      }
    })),
    {
    field: 'actions',
    headerName: 'Actions',
    resizable: false,
    sortable: false,
    filter: false,
    cellRenderer: ({ data }) => {
      const span = document.createElement('span');
      const saveBtn = document.createElement('button');
      saveBtn.textContent = 'Save';
      saveBtn.classList.add('btn', 'btn-primary');
      saveBtn.onclick = () => this.doSave(data);
      span.appendChild(saveBtn);
      return span;
    }
  });
  protected defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    autoHeight: true,
    flex: 1,
  };
  protected getRowNodeId (data): number {
    return data.id;
  }

  constructor(
    private wagertypesService: WagertypesService,
    private marketTypeCategoriesService: MarketTypeCategoriesService,
    private statisticTypesService: StatisticTypesService,
    private displayStylesService: DisplayStylesService,
    private limitGroupsService: LimitGroupsService
  ) { /**/ }

  public async ngOnInit(): Promise<void> {
    try {
      await this.getStatisticTypes();
      await this.getMarketTypeCategories();
      await this.getLimitGroups();
      let displayStyles = await this.getDisplayStyles();
      let wagertypes = await this.getWagertypes();
      Object.keys(wagertypes).forEach(wagertypeId => {
        let platformStyleId = (wagertypes[wagertypeId] as WagertypeInterface).platformStyleId;
        if (platformStyleId && !displayStyles[platformStyleId]) {
          displayStyles[platformStyleId] = { id: platformStyleId, name: `${platformStyleId}` };
        }
        this.wagertypes.push(...toArray(wagertypes[wagertypeId]));
        this.copiedWagertypes.push(...toArray(dup(wagertypes[wagertypeId])));
      });
      this.displayStyleIds = displayStyles;
      this.displayStyles = Object.values(displayStyles).sort((a, b) => a.name.localeCompare(b.name));
      this.sort();
    } catch (e) {
      console.error(e);
    }
  }

  protected validate(wagertypeName: string, wagertype: WagertypeInterface): ValidationResponse {
    return this.validator.validate(wagertypeName, wagertype);
  }

  protected effectiveDisplayStyle(wagertype: WagertypeInterface): string {
    if (!wagertype.platformWagertype) {
      return '';
    }
    let displayStyle = this.displayStyles.find(style => style.id === +wagertype.platformWagertype);
    if (!displayStyle) {
      return '';
    }
    return displayStyle.name === String(wagertype.platformWagertype) ? wagertype.name : displayStyle.name;
  }

  protected needsPlatformStyle(id: number): boolean {
    return id && !this.displayStyleIds[id];
  }

  protected doSave(data: WagertypeInterface) {
    let {
      id, name, long_name, short_name, translateKey, platformWagertype, platformStyleId, marketTypeCategoryId, statisticId, gradingType,
      limitGroupId
    }
     = data;
    let wagertype = this.wagertypes.find(wt => {
      return wt.id === id;
    });
    if (
      wagertype.name === name &&
      wagertype.long_name === long_name && wagertype.short_name === short_name &&
      wagertype.marketTypeCategoryId === +marketTypeCategoryId &&
      wagertype.platformWagertype === +platformWagertype &&
      wagertype.platformStyleId === +platformStyleId &&
      wagertype.statisticId === +statisticId &&
      wagertype.gradingType === gradingType &&
      wagertype.translateKey === translateKey &&
      wagertype.limitGroupId === +limitGroupId
    ) {
      alert('Nothing to do');
    } else {
      this.wagertypesService.save(
        id, {
          ...wagertype, marketTypeCategoryId, long_name: long_name, short_name: short_name,
          platformWagertype, platformStyleId, statisticId, gradingType, name, translateKey,
          limitGroupId
        }
      ).subscribe(newWt => {
        alert(JSON.stringify(newWt));
        this.wagertypes = this.wagertypes.map(wt => {
          if (wt.id === newWt.id) {
            wt.long_name = newWt.long_name;
            wt.short_name = newWt.short_name;
            wt.name = newWt.name;
            wt.marketTypeCategoryId = newWt.marketTypeCategoryId;
            wt.platformWagertype = newWt.platformWagertype;
            wt.platformStyleId = newWt.platformStyleId;
            wt.statisticId = newWt.statisticId;
            wt.gradingType = newWt.gradingType;
            wt.translateKey = newWt.translateKey;
            wt.limitGroupId = newWt.limitGroupId;
          }
          return wt;
        });
        this.sort();
      }, err => {
        let message: string;
        if (err.message) {
          message = err.message;
          if (err.error) {
            message = `${message} -- ${err.error}`;
          }
        }
        alert(message || JSON.stringify(err));
      });
    }
  }

  protected doCancel() {
    this.creating = !this.creating;
  }

  protected displayBehavior(behavior: number): string {
    return BEHAVIORS[behavior];
  }

  protected doCreate(
    copyFrom: number, name: string, shortName: string, statisticId: number, marketTypeCategoryId: number, limitGroupId: number
  ) {
    if (+copyFrom >= 0 && name && shortName) {
      this.wagertypesService.create({
        copy: +copyFrom, name: name.toLowerCase(), short_name: shortName,
        statisticId, marketTypeCategoryId, limitGroupId,
        long_name: name.split(' ').map(a => a.toLowerCase().replace(/^[a-z]/, a[0].toUpperCase())).join(' ')
      }).subscribe(newWt => {
        alert(JSON.stringify(newWt));
        if (!this.wagertypes.find(wt => wt.id === newWt.id)) {
          this.wagertypes = this.wagertypes.concat(newWt);
          this.sort();
        }
      }, err => {
        alert(err.error || err.message || JSON.stringify(err));
      });
    } else {
      alert('Invalid wagertype');
    }
  }

  private sort(): void {
    const wt = this.wagertypes.sort((u1, u2) => u1.id - u2.id);
    this.wagertypes = [...wt];
  }

  private createSelectElement(options: { text: string, id: number | string }[], data: Record<string, any>, field: string) {
    const select = document.createElement('select');
    options.map(({ id, text }) => {
      const opt = document.createElement('option');
      opt.value = `${id}`;
      opt.text = text;
      return opt;
    }).forEach((opt) => select.appendChild(opt));
    select.style.width = '100%';
    select.value = data[field];
    select.onchange = ({ target }: any) => {
      data[field] = target.value;
    };
    return select;
  }

  private getLimitGroups(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.limitGroupsService.list().subscribe(limitGroups => {
        this.limitGroups = Object.values(limitGroups).sort((a, b) => a.name.localeCompare(b.name));
        resolve();
      }, e => {
        reject(e);
      });
    });
  }

  private getStatisticTypes(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.statisticTypesService.list().subscribe(statisticTypes => {
        this.statisticTypes = Object.values(statisticTypes).sort((a, b) => a.name.localeCompare(b.name));
        resolve();
      }, e => {
        reject(e);
      });
    });
  }

  private getDisplayStyles(): Promise<Record<number, { id: number; name: string }>> {
    return new Promise((resolve, reject) => {
      this.displayStylesService.list().subscribe(displayStyles => {
        resolve(displayStyles);
      }, e => {
        reject(e);
      });
    });
  }

  private getMarketTypeCategories(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.marketTypeCategoriesService.list().subscribe(marketTypeCategories => {
        this.marketTypeCategories = Object.values(marketTypeCategories).sort((a, b) => a.name.localeCompare(b.name));
        resolve();
      }, e => {
        reject(e);
      });
    });
  }

  private getWagertypes(): Promise<Record<WagertypeInterface['id'], WagertypeInterface>> {
    return new Promise((resolve, reject) => {
      this.wagertypesService.list().subscribe(wagertypes => {
        resolve(wagertypes);
      }, e => {
        console.error(e);
      });
    });
  }

}
