/* Copyright © 2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { isString } from 'gs-utils/lib/utilities';

import { BaseRequestorService } from '../baseRequestor.service';
import { ValidationResponse } from '../../server/shared/validation/validator';
import { CreatePlayerInterface } from '../../server/shared/models/createPlayer';

@Injectable()
export class CreatePlayerService extends BaseRequestorService<CreatePlayerInterface, any> {

  constructor(protected http: HttpClient) {
    super('createPlayer', http);
  }

  public validate(id: string, model: CreatePlayerInterface): ValidationResponse {
    return { success: model.team_id > 0 && isString(model.name) };
  }
}
