/* Copyright © 2017-2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { ModuleWithProviders, NgModule } from '@angular/core';
import { DialogComponent } from './dialog.component';
import { DialogService } from './dialog.service';
import { AlertDialogComponent } from './kinds/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './kinds/confirm-dialog/confirm-dialog.component';
import { DialogOverlayDirective } from '../dialog/dialog-overlay.directive';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [DialogComponent, AlertDialogComponent, ConfirmDialogComponent, DialogOverlayDirective],
  imports: [BrowserModule],
  exports: [
    AlertDialogComponent,
    ConfirmDialogComponent,
    DialogOverlayDirective
  ],
})
export class DialogModule {
  public static forRoot(): ModuleWithProviders<DialogModule> {
    return {
      ngModule: DialogModule,
      providers: [
        DialogService
      ]
    };
  }
}
