/* Copyright © 2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component } from '@angular/core';
import { LogInResponse, LoginService } from './login.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Observable, tap } from 'rxjs';
import { SnackBarService } from '../services/snackbar.service';

@Component({
  selector: 'login',
  templateUrl: 'login/login.component.html',
  styles: [require('./login.component.scss')], // tslint:disable-line
})
export class LoginComponent {
  protected loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  protected loggedInStatus$: Observable<LogInResponse>;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService
  ) {
    this.activatedRoute.queryParams.subscribe((qp) => this.parseError(qp));
    this.snackBarService.duration = 5 * 1000;
    this.loggedInStatus$ = this.loginService.loggedInStatus.pipe(
      tap(({ fetching, isLoggedIn }) => {
        if (isLoggedIn) {
          this.router.navigate(['events']);
        } else if (fetching) {
          this.loginForm.disable();
        } else {
          this.loginForm.enable();
        }
      })
    );
  }

  protected get password() {
    return this.loginForm.get('password');
  }

  protected get username() {
    return this.loginForm.get('username');
  }

  protected signIn() {
    this.loginService.login(this.username.value, this.password.value);
  }

  private parseError(params: Params) {
    if (params.error === 'not-logged-in') {
      this.snackBarService.addErrorMessage('You are not logged in. Please login before continuing.');
    }

    if (params.error === 'not-authorized' && params.url && params.url.indexOf('/login') === -1) {
      this.snackBarService.addErrorMessage(`Not authorized to visit: ${params.url}. Please log in.`);
    }

    if (params.error === 'invalid-credentials') {
      this.snackBarService.addErrorMessage('Username or Password are incorrect. Please try again.');
    }

    if (params.error === 'logout-success') {
      this.snackBarService.addSuccessMessage('Log out successful.');
    }
  }
}
