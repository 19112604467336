/* Copyright © 2021-2024 Ganchrow Scientific, SA all rights reserved */

'use strict';

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { dup } from 'gs-utils/lib/utilities';
import { LineValidator } from '../../server/shared/validation/lineValidator';
import { ValidationResponse } from '../../server/shared/validation/validator';
import {
  AdminLineInterface as LineInterface,
  AdminWagertypeInterface,
  Wagertypes,
  Periods,
  Lines,
} from '../../server/shared/models/gsModel';
import { WagertypesService } from '../wagertypes/wagertypes.service';
import { PeriodsService } from '../periods/periods.service';
import { LinesService } from './lines.service';
import { Observable, Subscription, combineLatest, switchMap, tap } from 'rxjs';
import { AgGridAngular, ICellRendererAngularComp } from 'ag-grid-angular';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { SnackBarService } from '../services/snackbar.service';

const WT_PREFIX = '_';

@Component({
  selector: 'lines',
  templateUrl: 'lines/lines.component.html',
  styles: [
    require('./lines.component.scss'), // tslint:disable-line
  ],
  providers: [LinesService, PeriodsService],
})
export class LinesComponent implements OnInit, OnDestroy {
  @ViewChild(AgGridAngular) protected agGrid: AgGridAngular;
  protected columnDefs: ColDef[] = [
    {
      field: 'rotation',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerName: 'Rotation',
      valueGetter: ({ data: { value } }: { data: { value: LineInterface } }) => {
        return value.rotation;
      },
    },
    {
      field: 'period',
      filter: false,
      headerName: 'Period',
      valueGetter: ({ data: { value } }: { data: { value: LineInterface } }) => {
        return this.getPeriod(value.rotation, value.period);
      },
    },
    {
      field: 'wagertype',
      filter: false,
      headerName: 'Wagertype',
      valueGetter: ({ data: { value } }: { data: { value: LineInterface } }) => {
        return this.getWagertype(value.wagertype).name;
      },
    },
    {
      field: 'figure',
      headerName: 'Figure',
      cellEditor: 'agTextCellEditor',
      editable: ({ data: { value } }: { data: { value: LineInterface } }) => !this.figureDisabled(value as any),
      valueGetter: ({ data: { value } }: { data: { value: LineInterface } }) => {
        return value.figure;
      },
      valueSetter: (params) => {
        params.data.value.figure = params.newValue;
        return true;
      },
    },
    ...Array(3)
      .fill(null)
      .map(
        (_, i) =>
          ({
            field: `price${i}`,
            headerName: `Price ${i + 1}`,
            cellEditor: 'agTextCellEditor',
            editable: true,
            valueGetter: ({ data: { value } }: { data: { value: LineInterface } }) => {
              return value.prices[i];
            },
            valueSetter: (params) => {
              params.data.value.prices[i] = params.newValue;
              return true;
            },
          } as ColDef)
      ),
    {
      field: 'contestant',
      headerName: 'Contestant',
      cellEditor: 'agTextCellEditor',
      editable: true,
      valueGetter: ({ data: { value } }: { data: { value: LineInterface & { contestant: string } } }) => {
        return value.contestant;
      },
      valueSetter: (params) => {
        params.data.value.contestant = params.newValue;
        return true;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      cellRendererFramework: LinesActionsCellRenderer,
    },
  ];
  protected context = { controller: this };
  protected defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    filterParams: { newRowsAction: 'keep' },
  };
  @Input() protected rotations: number[] = [];
  @Input() protected sportNames: string[] = [];
  @Input() protected leagueNames: string[] = [];
  protected selectedRotation: number;
  protected creating = false;
  protected lines: Record<LineInterface['classifier'], LineInterface> = {};
  protected wagertypes: AdminWagertypeInterface[] = [];
  protected periodsByRotation: Record<number, { id: number; name: string }[]> = {};
  protected selected: Set<string> = new Set();
  protected selectedPeriod = -1;
  protected filteredPeriods: Record<number, string> = { '-1': 'all' };
  protected selectedWagertype = -1;
  protected filteredWagertypes: Record<number, string> = { '-1': 'all' };
  private validator = new LineValidator();
  protected subscriptions = new Subscription();

  constructor(
    private linesService: LinesService,
    private wagertypesService: WagertypesService,
    private periodsService: PeriodsService,
    private snackbarService: SnackBarService
  ) {}

  public ngOnInit(): void {
    const subs = combineLatest([this.getWagertypes(), this.getPeriods()])
      .pipe(switchMap(() => this.getLines()))
      .subscribe();
    this.selectedRotation = this.rotations[0];
    this.subscriptions.add(subs);
  }

  protected getRowNodeId({ value }: { value: LineInterface }): string {
    return value.classifier;
  }

  protected onRowSelected(): void {
    this.selected = new Set(this.agGrid.api.getSelectedRows().map(({ key }) => key));
  }

  protected getWagertypeBehavior(id: number): number {
    return this.getWagertype(id).behavior;
  }

  protected getPeriod(rotation: number, id: number): string {
    return this.periodsByRotation[rotation][id]?.name || `${id}`;
  }

  protected validate(lineName: string, line: LineInterface): ValidationResponse {
    return this.validator.validate(lineName, line);
  }

  protected doSave(classifier: string): void {
    const { rotation, period, wagertype, prices, figure, contestant } = this.lines[classifier] as LineInterface & { contestant: string };
    return this.doCreate(rotation, [period], [wagertype], prices[0], prices[1], prices[2], figure, contestant);
  }

  protected doDelete(classifier: string): void {
    const line = this.lines[classifier];
    line.prices = [];
    delete this.lines[classifier];
    const subs = this.linesService.create({ ...dup(line), gsHide: true }).subscribe({
      next: () => {
        this.snackbarService.addSuccessMessage(`${this.prettify(line)} deleted deleted successfully`);
      },
      error: (err) => this.snackbarService.addErrorMessage(err.error || err.message || JSON.stringify(err)),
    });
    this.subscriptions.add(subs);
  }

  protected takeDownAll(): void {
    const lines = Object.keys(this.lines).filter(
      (k) =>
        (this.lines[k].wagertype === +this.selectedWagertype || +this.selectedWagertype === -1) &&
        (this.lines[k].period === +this.selectedPeriod || +this.selectedPeriod === -1)
    );
    this.takeDownSelected(new Set(lines));
  }

  protected saveSelected(selected = this.selected): void {
    this.batchSaveSelected(selected, true);
  }

  protected takeDownSelected(selected = this.selected): void {
    this.batchSaveSelected(selected);
  }

  private batchSaveSelected(selected = this.selected, save = false): void {
    if (!selected.size) {
      return this.snackbarService.addErrorMessage('Nothing to do');
    }
    if (save) {
      this.selected.forEach((classifier) => {
        this.doSave(classifier);
      });
    } else {
      const subs = this.linesService
        .batchSave(
          Array.from(selected).map((classifier) => {
            const line = this.lines[classifier];
            line.prices = this.ensureValidPrices([0], null);
            line.timestamp = Date.now();
            return line;
          })
        )
        .subscribe({
          next: (lines) => {
            lines.forEach((line) => {
              this.snackbarService.addSuccessMessage(this.prettify(line));
              this.lines[line.classifier] = line;
            });
          },
          error: (err) => this.snackbarService.addErrorMessage(err.error || err.message || JSON.stringify(err)),
        });
      this.subscriptions.add(subs);
    }
    this.agGrid.api.deselectAll();
  }

  protected doCreate(
    rotation: number,
    periods: number[],
    wagertypes: number[],
    price1: number,
    price2: number,
    price3: number,
    figure: number,
    contestant: string
  ): void {
    const prices = this.ensureValidPrices([+price1 || null, +price2 || null], price3);
    if (String(figure) === '' || !Number.isFinite(+figure)) {
      figure = null;
    } else {
      figure = +figure;
    }

    const marketTypeCategoryIds = {};
    wagertypes.forEach((wagertype) => {
      const marketTypeCategoryId = marketTypeCategoryIds[wagertype] || this.wagertypes.find((w) => w.id === wagertype).marketTypeCategoryId;
      if (!marketTypeCategoryIds[wagertype]) {
        marketTypeCategoryIds[wagertype] = marketTypeCategoryId;
      }
      periods.forEach((period) => {
        const subs = this.linesService
          .create({
            rotation,
            period: +period,
            wagertype: +wagertype,
            prices,
            figure,
            contestant,
            timestamp: Date.now(),
            marketTypeCategoryId,
          })
          .subscribe({
            next: (line) => {
              this.snackbarService.addSuccessMessage(this.prettify(line));
              this.resetFiltered(line);
              this.lines[line.classifier] = line;
            },
            error: (err) => this.snackbarService.addErrorMessage(err.error || err.message || JSON.stringify(err)),
          });
        this.subscriptions.add(subs);
      });
    });
  }

  protected figureDisabled(line: LineInterface & { contestant: string; correct_score: number[]; needsDisplayFigure?: boolean }): boolean {
    return !!(this.getWagertypeBehavior(line.wagertype) === 0 || line.contestant || line.correct_score) && !line.needsDisplayFigure;
  }

  private resetFiltered(line: LineInterface): void {
    this.filteredWagertypes[line.wagertype] = this.wagertypes.find((wt) => wt.id === line.wagertype).name;
    this.filteredPeriods[line.period] = this.periodsByRotation[line.rotation].find((pd) => pd.id === line.period).name;
  }

  private prettify(line: LineInterface): string {
    return [
      `Rotation ${line.rotation} period ${this.getPeriod(line.rotation, line.period)}`,
      `${this.getWagertype(line.wagertype).name} saved!`,
    ].join(' ');
  }

  private ensureValidPrices(prices: number[], price3: number): number[] {
    if (!prices[0]) {
      prices = [];
    } else if (prices[0] && !prices[1]) {
      prices[1] = -10000000;
    } else if (price3 && +price3) {
      prices[2] = +price3;
    } else if (prices.filter((p) => !!p).length < 2) {
      prices = [];
    }
    return prices;
  }

  private getWagertype(id: number): AdminWagertypeInterface {
    return this.wagertypes.find((wt) => wt.id === +id);
  }

  private getWagertypes(): Observable<Wagertypes> {
    return this.wagertypesService.list().pipe(
      tap((wagertypes) => {
        this.wagertypes = Object.values(wagertypes).sort((wt1, wt2) => {
          let prefix1 = wt1.id < 5 ? WT_PREFIX : '';
          let prefix2 = wt2.id < 5 ? WT_PREFIX : '';
          return `${prefix1}${wt1.name}`.localeCompare(`${prefix2}${wt2.name}`);
        });
      })
    );
  }

  private getLines(): Observable<Lines[]> {
    const obs = this.rotations.map((rotation) =>
      this.linesService.list({ rotation }).pipe(
        tap((lines) =>
          Object.entries(lines).forEach(([lineId, line]) => {
            this.resetFiltered(line);
            this.lines[lineId] = line;
          })
        )
      )
    );
    return combineLatest(obs);
  }

  private getPeriods(): Observable<Periods> {
    return this.periodsService.list().pipe(
      tap((periods) => {
        this.periodsByRotation = this.foldPeriodsByRotation(periods);
      })
    );
  }

  private foldPeriodsByRotation(periods: Record<string, string[]>): Record<number, { id: number; name: string }[]> {
    return this.rotations.reduce((obj, rot, idx) => {
      obj[rot] = (periods[this.leagueNames[idx]] || periods[this.sportNames[idx]] || periods.default || periods.basketball)
        .map((name, id) => ({ id, name }))
        .sort((p1, p2) => p1.id - p2.id);
      return obj;
    }, {});
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

@Component({
  template: `<button
      mat-button
      color="primary"
      (click)="save()">
      Save
    </button>
    <button
      mat-button
      color="warn"
      (click)="delete()">
      Delete
    </button>`,
})
export class LinesActionsCellRenderer implements ICellRendererAngularComp {
  private params: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  protected get data(): LineInterface {
    return this.params.data.value;
  }

  protected save(): void {
    this.params.context.controller.doSave(this.data.classifier);
  }

  protected delete(): void {
    this.params.context.controller.doDelete(this.data.classifier);
  }

  public refresh(): boolean {
    return false;
  }
}
