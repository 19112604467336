/* Copyright © 2017-2022 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings } from '../multiselect/multiselect.types';

import { FilterListPipe } from '../filters/filterListPipe.filter';

import { PeriodsService } from './periods.service';

@Component({
  selector: 'periods-drop-down',
  templateUrl: 'periods/periodsDropDown.component.html',
  styles: [
    require('./periodsDropDown.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe]
})

export class PeriodsDropDownComponent implements OnInit {
  private periods: string[];
  protected manualChange = false;
  protected selectSettings: IMultiSelectSettings = {
    enableSearch: true,
    dynamicTitleMaxItems: 2,
    containerClasses: 'periods-drop-down--select--container',
    buttonClasses: 'form-control periods-drop-down--select--selector',
    checkedStyle: 'glyphicon',
    showUncheckAll: true
  };
  private singleSelection = false;

  @Input() protected header = 'Select Periods';
  @Input() public simple = false;
  @Input() protected selectedPeriodIds: number[] = [];
  @Input() protected enableSelection = true;
  @Input() protected selectionLimit;
  @Input() protected periodOptions: IMultiSelectOption[] = [];

  @Output() protected periodsChanged = new EventEmitter<{period: number, periodIds: number[], labels: string[]}>();

  constructor(private periodsService: PeriodsService) { /**/ }

  public ngOnInit(): void {
    if (this.selectionLimit) {
      this.singleSelection = this.selectionLimit === 1;
      this.selectSettings.selectionLimit = this.selectionLimit;
      this.selectSettings.closeOnSelect = this.singleSelection;
    }
  }

  protected getPeriodLabel(period: { id: number; name: string }): string {
    return `${period.id}: ${period.name}`;
  }

  protected handlePeriodsChanged() {
    if (this.manualChange) {
      let selectedPeriodIds = this.selectedPeriodIds;
      this.periodsChanged.emit({
        period: this.singleSelection ? this.selectedPeriodIds[0] ?? null : null,
        periodIds: !this.singleSelection ? selectedPeriodIds : null,
        labels: (selectedPeriodIds || [])
          .map(id => (this.periodOptions.find(opt => Number(opt.id) === id) || { label: '', name: '' }).name)
          .filter(name => !!name)
      });
    }
  }

  private sort(): void {
    this.periodOptions.sort((u1, u2) => Number(u1.id) - Number(u2.id));
  }
}
