/* Copyright © 2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component } from '@angular/core';

@Component({
  selector: 'playersInit',
  templateUrl: 'players/playersInit.component.html',
  styles: [
    require('./playersInit.component.scss')  // tslint:disable-line
  ]
})
export class PlayersInitComponent {
  protected searchTeams: { id: number, label: string }[] = [];
  protected teamIds: number[] = [];
  protected leagueIds: number[] = [];
  public isSearchingTeams = true;

  protected teamsChanged({ teamIds, labels }) {
    this.teamIds = teamIds;
    this.searchTeams = teamIds.map((id, idx) => ({ id, label: labels[idx] }));
  }

  public handleSearching($event) {
    this.isSearchingTeams = $event;
  }

  protected leaguesChanged({ leagueIds }) {
    this.leagueIds = leagueIds || [];
    this.teamIds = [];
  }

  protected clear() {
    this.leagueIds = [];
    this.teamIds = [];
    this.searchTeams = [];
  }
}
