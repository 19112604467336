/* Copyright © 2018 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, Input, OnInit } from '@angular/core';

import { LeaguesService } from './leagues.service';

@Component({
  selector: 'single-league',
  templateUrl: 'leagues/singleLeague.component.html'
})
export class SingleLeagueComponent implements OnInit {
  @Input()
  protected league: number;
  protected transformedLeague = '';

  constructor(private leaguesService: LeaguesService) { /**/ }

  public ngOnInit(): void {
    if (this.league || (Number(this.league) === 0)) {
      this.leaguesService.fetch(String(this.league)).subscribe(league => {
        this.transformedLeague = league.name;
      }, err => {
        this.transformedLeague = String(this.league);
      });
    }
  }
}
