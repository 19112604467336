/* Copyright © 2018-2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { BaseRequestorService } from '../baseRequestor.service';

import { ValidationResponse } from '../../server/shared/validation/validator';
import { Wagertypes, AdminWagertypeInterface as WagertypeInterface } from '../../server/shared/models/gsModel';
import { WagertypeValidator } from '../../server/shared/validation/wagertypeValidator';

@Injectable()
export class WagertypesService extends BaseRequestorService<WagertypeInterface, Wagertypes> {
  private wagertypeValidator = new WagertypeValidator();  // TODO: should be injected

  constructor(protected http: HttpClient) {
    super('wagertypes', http, 10000);
  }

  public validate(id: string, model: WagertypeInterface): ValidationResponse {
    return this.wagertypeValidator.validate(id, model);
  }
}
