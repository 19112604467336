/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseRequestorService } from '../baseRequestor.service';

import { ValidationResponse } from '../../server/shared/validation/validator';

type PeriodInterface = string[];
type Periods = Record<string, PeriodInterface>;

@Injectable()
export class PeriodsService extends BaseRequestorService<PeriodInterface, Periods> {

  constructor(protected http: HttpClient) {
    super('periods', http, 10000);
  }

  public validate(id: string, model: PeriodInterface): ValidationResponse {
    return { success: true, reasons: [] };
  }
}
