/* Copyright © 2023-2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, Input } from '@angular/core';

import { PeriodsService } from '../periods/periods.service';
import { WagertypesService } from '../wagertypes/wagertypes.service';

import { LeagueCorrelationsService } from './leagueCorrelations.service';

import { BaseCorrelationsComponent } from './baseCorrelations.component';
import { SnackBarService } from '../services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'league-correlations',
  templateUrl: 'correlations/correlations.component.html',
  styles: [
    require('./correlations.component.scss')  // tslint:disable-line
  ],
  providers: [LeagueCorrelationsService, PeriodsService]
})

export class LeagueCorrelationsComponent extends BaseCorrelationsComponent {
  @Input() protected rotations: number[] = [];
  @Input() protected sportNames: string[] = [];
  @Input() protected leagueNames: string[] = [];
  protected title = 'LeagueCorrelations';
  protected idType = 'League Id';
  protected isLeagueCorrelation = true;
  protected sideOptions = [
    { value: 1, display: 'Side 1' },
    { value: 2, display: 'Side 2' },
    { value: 3, display: 'Draw' },
    { value: 101, display: 'Same Team As Prop Player Top Team Side 1' },
    { value: 102, display: 'Same Team As Prop Player Top Team Side 2' },
    { value: 201, display: 'Same Team As Prop Player Bottom Team Side 1' },
    { value: 202, display: 'Same Team As Prop Player Bottom Team Side 2' },
    { value: 1001, display: 'Same Team As Prop Player Team Total Over' },
    { value: 1002, display: 'Same Team As Prop Player Team Total Under' },
    { value: 2001, display: 'Different Team As Prop Player Team Total Over' },
    { value: 2002, display: 'Different Team As Prop Player Team Total Under' },
    { value: 301, display: 'Different Team As Prop Player Top Team Side 1' },
    { value: 302, display: 'Different Team As Prop Player Top Team Side 2' },
    { value: 401, display: 'Different Team As Prop Player Bottom Team Side 1' },
    { value: 402, display: 'Different Team As Prop Player Bottom Team Side 2' }
  ];

  constructor(
    protected correlationsService: LeagueCorrelationsService,
    protected wagertypesService: WagertypesService,
    protected periodsService: PeriodsService,
    protected snackbarService: SnackBarService,
    protected dialogService: MatDialog
  ) {
    super(snackbarService, dialogService);
  }

  protected getCreatedSides(target: any): [number, number] {
    return [
      target.parentElement.parentElement.querySelector('#league-correlations-side').value,
      target.parentElement.parentElement.querySelector('#league-correlations-side-2').value
    ];
  }

  protected getSide(side: number): string {
    return (this.sideOptions.find(o => o.value === +side) || {}).display || 'NA';
  }

  protected get items(): { key: string; value: number }[] {
    return this.rotations.map((id, i) => {
      return { key: String(this.leagueNames[i] || id), value: id };
    });
  }

  protected rotationLabelGetter(id: number): string | number {
    return (this.items.find(item => +id === +item.value) || { key: id }).key;
  }
}
