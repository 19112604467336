/* Copyright © 2024 Ganchrow Scientific, SA all rights reserved */

const mtPermissions = {
  'line-sets': {},
  events: {
    'last-bets': {},
    'finished-events': {},
    'finished-events-no-result': {},
    unremittable: {},
    'ungraded-exotics': {},
    outcomes: {},
    'live-events': {
      'baalm-link': {},
    },
    'line-history': {},
  },
  'offline-toggle': {},
  users: {
    'users-groups': {},
    'find-user': {},
    'user-list': {},
    'user-groups': {},
    'free-bets': {},
    'featured-bets': {},
    'circle-templates': {},
    'partner-rating-tranches': {},
  },
  groups: {
    shades: {},
  },
  reports: {
    'summary-report': {},
    'open-bet-report': {},
    'users-report': {},
    'events-report': {},
    'summary-ev-report': {},
    'events-ev-report': {},
    'users-ev-report': {},
  },
  tools: {
    ticker: {},
    lines: {},
  },
  'ticket-writer': {},
  translations: {},
  partner: {},
};

function grantPermissions(data: Record<string, any>): Record<string, any> {
  const permissions = ['view', 'edit', 'update', 'create', 'delete', 'apply'];
  for (let category in data) {
    if (data.hasOwnProperty(category) && typeof data[category] === 'object' && !Array.isArray(data[category])) {
      for (let item in data[category]) {
        if (data[category].hasOwnProperty(item)) {
          data[category][item] = Object.fromEntries(permissions.map((permission) => [permission, null]));
        }
      }
    }
  }
  return data;
}

export default grantPermissions(mtPermissions);
