/* Copyright © 2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { AllowedPage } from './users.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  checked: boolean | null;
  parent: string | null;
}

@Component({
  selector: 'mt-permissions',
  templateUrl: 'users/mtPermissions.component.html',
  styles: [
    require('./mtPermissions.component.scss'), // tslint:disable-line
  ],
})
export class MTpermissionsComponent implements OnInit {
  @Input() protected pages: AllowedPage[] = [];
  @Output() protected changeInput = new EventEmitter<any>();
  protected panelOpenState = false;

  protected setNestedValue(obj: Record<string, any>, key: string, value: any) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (prop === key) {
          obj[prop] = value;
        } else if (typeof obj[prop] === 'object') {
          this.setNestedValue(obj[prop], key, value);
        }
      }
    }
  }

  protected setParentToTrue(name: string): void {
    const node = this.treeControl.dataNodes.find((e) => e.name === name);
    if (node) {
      node.checked = true;
      if (node.parent) {
        this.setParentToTrue(node.parent);
      }
    }
  }

  protected onInputChange({ value, parent: prnt }): void {
    if (value && parent) {
      this.setParentToTrue(prnt);
    }

    const parentChildMap = this.treeControl.dataNodes
      .filter((n) => n.checked !== null)
      .reduce((map, { parent, checked, name }) => {
        if (parent === null) {
          map[name] = checked;
        } else {
          this.setNestedValue(map, parent, { [name]: checked });
        }
        return map;
      }, {});
    this.changeInput.emit(parentChildMap);
  }

  public ngOnInit(): void {
    this.dataSource.data = this.pages;
  }

  private transformer(node: AllowedPage, level: number): FlatNode {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      checked: node.checked,
      parent: node.parent,
      level: level,
    };
  }

  public treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  public treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  public dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  public hasChild = (_: number, node: FlatNode) => node.expandable;
}

@Component({
  selector: 'tri-state-checkbox',
  template: `<mat-checkbox
    [ngModel]="value"
    (click)="next()"
    [disabled]="disabled"
    [indeterminate]="value === false"
    [color]="value === false ? 'warn' : 'primary'">
    <ng-content></ng-content>
  </mat-checkbox>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TriStateCheckboxComponent),
      multi: true,
    },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions },
  ],
})
export class TriStateCheckboxComponent implements ControlValueAccessor {
  @Input() protected tape = [null, true, false];
  @Input() protected parent: string;
  @Output() protected changeInput = new EventEmitter<any>();
  protected value: any;
  protected disabled: boolean;

  public onChange: (val: boolean) => void;

  public onTouched: () => void;

  public writeValue(value: any): void {
    this.value = this.tape.includes(value) ? value : this.tape[0];
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  protected next(): void {
    this.onChange((this.value = this.tape[(this.tape.indexOf(this.value) + 1) % this.tape.length]));
    this.onTouched();
    const { parent, value } = this;
    this.changeInput.emit({ value, parent });
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
