/* Copyright © 2022-2024 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, Input } from '@angular/core';

import { PeriodsService } from '../periods/periods.service';
import { WagertypesService } from '../wagertypes/wagertypes.service';

import { CorrelationsService } from './correlations.service';

import { BaseCorrelationsComponent } from './baseCorrelations.component';
import { SnackBarService } from '../services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'correlations',
  templateUrl: 'correlations/correlations.component.html',
  styles: [
    require('./correlations.component.scss'), // tslint:disable-line
  ],
  providers: [CorrelationsService, PeriodsService],
})
export class CorrelationsComponent extends BaseCorrelationsComponent {
  @Input() protected rotations: number[] = [];
  @Input() protected sportNames: string[] = [];
  @Input() protected leagueNames: string[] = [];
  protected title = 'Correlations';
  protected idType = 'Rotation';

  constructor(
    protected correlationsService: CorrelationsService,
    protected wagertypesService: WagertypesService,
    protected periodsService: PeriodsService,
    protected snackbarService: SnackBarService,
    protected dialogService: MatDialog
  ) {
    super(snackbarService, dialogService);
  }
}
