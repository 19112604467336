/* Copyright © 2017 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Validator, ValidationResponse } from './validator';
import { ScoreInterface } from 'gs-templates/lib/score';

export class ScoreValidator extends Validator<ScoreInterface> {
  public validate(rotation: string, instance: ScoreInterface): ValidationResponse {
    let errors = [];
    if (!rotation) {
      errors.push('Must supply a score rotation.');
    }

    if (!instance) {
      errors.push('Must supply score info.');
    }

    if (!instance.rotation) {
      errors.push('Must supply a score rotation.');
    }

    if (Number(rotation) !== Number(instance.rotation)) {
       errors.push(`Key ${rotation} and rotation ${instance.rotation} must match`);
    }

    if (!instance.feed_source) {
      errors.push('Must supply a score feed_source.');
    }

    if (!Number.isInteger(instance.timestamp)) {
      errors.push('Score timestamp must be an integer.');
    }

    if (!Number.isInteger(instance.period)) {
      errors.push('Period must be an integer.');
    }

    errors.push(...this.validatePeriodScores(instance.period_scores));

    if (errors.length) {
      return {
        success: false,
        reasons: errors
      };
    }
    return {
      success: true
    };
  }

  private validatePeriodScores(periodScores): string[] {
    let errors: string[] = [];

    if (!Array.isArray(periodScores)) {
      errors.push('Period scores must be an array');
    } else {
      periodScores.forEach((score, i) => {
        // last period can have empty scores
        errors.push(...this.validateArray(score, `period ${i + 1} score`, i === periodScores.length - 1));
      });
    }

    return errors;
  }

  private validateArray(array: number[], kind: string, onlyCheckArray?: boolean) {
    let isValid = onlyCheckArray ?
      Array.isArray(array) :
      Array.isArray(array) && array.length >= 2 && array.every(num => Number.isInteger(num));

    if (!isValid) {
      return [`Invalid ${kind} ${array}`];
    }
    return [];
  }
}
