/* Copyright © 2020 Ganchrow Scientific, SA all rights reserved */
import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, Component, EmbeddedViewRef, InjectionToken } from '@angular/core';
import { AlertDialogComponent } from './kinds/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './kinds/confirm-dialog/confirm-dialog.component';
import { DialogModel } from './dialog.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DialogService {
  public isShowingModal$;
  private isShowingModal = new BehaviorSubject(false);
  private modalsOpen = new Map();

  constructor(
    private componentResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
    this.isShowingModal$ = this.isShowingModal.asObservable();
  }

  public get alert() {
    return this.dialogWrapper(AlertDialogComponent);
  }

  public get confirm() {
    return this.dialogWrapper(ConfirmDialogComponent);
  }

  public handleOverlayClick() {
    this.modalsOpen.forEach(d => d.cancel());
  }

  private dialogWrapper(componentKind) {
    const dialog: any = {
      kind: componentKind,
      open: () => this.open(dialog),
      title: (modalTitle) => {
        dialog.modalTitle = modalTitle;
        return dialog;
      },
      body: (modalContent: string | typeof Component) => {
        this.isContentValid(modalContent);
        dialog.content = modalContent;
        return dialog;
      },
      asTextArea: () => {
        dialog.useTextArea = true;
        return dialog;
      },
      labels: ({ cancel, confirm }) => {
        dialog.cancelText = cancel;
        dialog.confirmText = confirm;
        return dialog;
      },
      cancel: () => {
        dialog._ref.instance.close();
      },
      close: () => {
        if (dialog._ref) {
          dialog._ref.destroy();
          this.modalsOpen.delete(dialog._ref);
          this.notifyModalState();
          delete dialog._ref;
        }
      },
      get ref() {
        if (!dialog._ref) {
          console.error('Modal has already been destroyed');
          return;
        }
        return dialog._ref;
      },
      set component(dialogRef) {
        dialog.result = dialogRef.instance.result;
        dialog._ref = dialogRef;
        dialogRef.instance.config = dialog;
      }
    };
    return dialog;
  }

  private open(dialog) {
    if (!this.isContentValid(dialog.content)) {
      return;
    }
    const ref = this.appendModalToView(dialog);
    dialog.component = ref;
    this.modalsOpen.set(ref, dialog);
    this.notifyModalState();
    return ref.instance.result;
  }

  private notifyModalState() {
    this.isShowingModal.next(!!this.modalsOpen.size);
  }

  private isContentValid(content) {
    if (!content) {
      console.error('No content');
      return false;
    }
    return true;
  }

  private appendModalToView(dialog) {
    const componentRef = this.componentResolver
      .resolveComponentFactory<DialogModel>(dialog.kind)
      .create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<typeof dialog.component>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    return componentRef;
  }

}
