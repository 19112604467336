/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseRequestorService } from '../baseRequestor.service';

import { ValidationResponse } from '../../server/shared/validation/validator';

@Injectable()
export class DisplayStylesService extends BaseRequestorService<
  { id: number; name: string }, Record<number, { id: number; name: string }>
> {

  constructor(protected http: HttpClient) {
    super('displayStyles', http, 10000);
  }

  public validate(id: string, model: { id: number; name: string }): ValidationResponse {
    return { success: true, reasons: [] };
  }
}
