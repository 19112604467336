/* Copyright © 2019 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { PatternRecord, PatternInterface } from '../models/leaguePattern';

import { Validator, ValidationResponse } from './validator';

export class LeaguePatternValidator extends Validator<PatternInterface> {
  public validate(id: keyof PatternRecord, instance: PatternInterface): ValidationResponse {
    let errors = [];
    if (!id) {
      errors.push('Must supply a sport id');
    }

    if (!(instance && instance.priority && instance.pattern && instance.league)) {
      errors.push('Must supply { priority, pattern , league }');
    }

    if (errors.length) {
      return {
        success: false,
        reasons: errors
      };
    }
    return {
      success: true
    };
  }
}
