/* Copyright © 2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component } from '@angular/core';

@Component({
  selector: 'matchups-header',
  templateUrl: 'matchups/header.component.html',
  styles: [
    require('./header.component.scss') // tslint:disable-line
  ],
  providers: []
})
export class MatchupsHeaderComponent {
  protected sportId: number;
  protected sportSelected({ sportIds }: { sportIds: number[]; labels: string[] }) {
    this.sportId = sportIds[0];
  }
}
