/* Copyright © 2020-2021 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'date-time',
  template: `
    <input type="date" [formControl]="dateControl" style="margin-right: .5rem"/>
    <input type="time" [formControl]="timeControl" />
  `,
  providers: [DatePipe]
})
export class DateTimeComponent implements OnInit {
  @Output() public dateChange = new EventEmitter();
  protected dateControl = new FormControl();
  protected timeControl = new FormControl();
  private dateTimeForm = new FormGroup({});
  private dateValue;

  @Input() public get date() {
    return this.dateValue;
  }
  public set date(newDate) {
    this.dateValue = newDate;
    this.dateChange.emit(this.dateValue);
  }

  constructor(private datePipe: DatePipe) {
    this.dateTimeForm.addControl('date', this.dateControl);
    this.dateTimeForm.addControl('time', this.timeControl);
  }

  public ngOnInit() {
    this.timeControl.setValue(this.datePipe.transform(this.date, 'hh:mm'));
    this.dateControl.setValue(this.datePipe.transform(this.date, 'yyyy-MM-dd'));

    this.dateTimeForm.valueChanges.subscribe((newDateTime) => {
      this.date = this.formatDate(newDateTime);
    });
  }

  private formatDate(dateFormValue) {
    return `${dateFormValue.date}T${dateFormValue.time}`;
  }
}
