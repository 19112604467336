/* Copyright © 2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { toArray } from 'gs-utils/lib/utilities';
import { FilterListPipe } from '../filters/filterListPipe.filter';
import { WagertypesService } from '../wagertypes/wagertypes.service';
import { PeriodsService } from '../periods/periods.service';
import { OffertypesService } from './offertypes.service';
import { OffertypeTemplatesService } from './offertypeTemplates.service';
import { ColDef } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { BehaviorSubject, Observable, combineLatest, switchMap, tap } from 'rxjs';
import { OffertypeInterface } from '../../server/shared/models/offertype';
import { Offertypes, Periods, Wagertypes } from '../../server/shared/models/gsModel';
import { WagertypeInterface } from 'gs-templates/lib/wagertype';
import { LicenseManager } from 'ag-grid-enterprise';
import agGridLicense from '../agGridLicense';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSelectChange } from '@angular/material/select';
import { SnackBarService } from '../services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { AggregatorTemplates } from './aggregatorTemplates.component';

type OffertypeInterfaceWithTemplate = Pick<OffertypeInterface, 'period' | 'wagertype' | 'figure' | 'id' | 'sport_id' | 'sport'> & {
  template: string;
  customFigure: boolean;
  name: string;
};
type OffertypeFormInterface = {
  [K in keyof OffertypeInterfaceWithTemplate]: FormControl<OffertypeInterfaceWithTemplate[K]>;
};

@Component({
  selector: 'offertypes',
  templateUrl: 'offertypes/offertypes.component.html',
  styles: [
    require('./offertypes.component.scss'), // tslint:disable-line
  ],
  providers: [FilterListPipe, OffertypeTemplatesService, PeriodsService],
})
export class OffertypesComponent implements OnInit, OnChanges {
  @Input() protected source: string;
  protected source$: BehaviorSubject<string>;
  protected periodsBySport: Periods = {};
  protected periods: { id: number; name: string }[] = [];
  protected wagertypes: WagertypeInterface[] = [];
  protected offertypes: OffertypeInterface[] = [];
  protected templates: OffertypeInterface[] = [];
  protected fetch$: Observable<any>;
  protected availableSourcesToMap = {
    'betsapi-bwin': true,
    lsports: true,
  };
  protected offertypeForm = new FormGroup<OffertypeFormInterface>({
    name: new FormControl(undefined),
    period: new FormControl(undefined, [Validators.required]),
    wagertype: new FormControl(undefined, [Validators.required]),
    template: new FormControl(''),
    figure: new FormControl(undefined),
    customFigure: new FormControl(false),
    id: new FormControl(undefined),
    sport_id: new FormControl(undefined),
    sport: new FormControl(undefined),
  });
  @ViewChild(AgGridAngular) protected agGrid: AgGridAngular;
  @ViewChild('drawer') protected drawer: MatDrawer;
  @HostListener('document:click', ['$event'])
  protected clickout(event) {
    const offertypesContainer = document.querySelector('.offertype-list');
    if (
      this.drawer &&
      offertypesContainer.contains(event.target) &&
      !event.target.classList.contains('fa-edit') &&
      !this.drawer._content.nativeElement.contains(event.target)
    ) {
      this.closeMenu();
    }
  }
  protected columnDefs: ColDef[] = [
    ...[
      ['sport', 'Sport'],
      ['id', 'Source ID'],
      ['name', 'Name'],
      ['template', 'Template'],
      ['figure', 'Figure'],
    ].map(([field, headerName]) => ({
      field,
      headerName,
    })),
    ...[
      ['period', 'Period'],
      ['wagertype', 'Wagertype'],
    ].map(([field, headerName]) => ({
      field,
      headerName,
      valueGetter: ({ data }: { data: OffertypeInterface }) => data[`${field}_name`] || data[field] || '',
    })),
    {
      field: 'actions',
      headerName: 'Actions',
      cellRenderer: ({ data: selected }: { data: OffertypeInterface }) => {
        const span = document.createElement('span');
        span.innerHTML = '<i class="fas fa-edit"></i>';
        span.onclick = () => {
          this.periods = (
            this.periodsBySport[selected.sport.toLowerCase()] ||
            this.periodsBySport.default ||
            this.periodsBySport.football
          ).map((name, id) => ({ id, name }));
          this.offertypeForm.reset();
          Object.keys(this.offertypeForm.controls).forEach((k) => {
            const value = selected[k];
            if (!!value || Number.isFinite(value)) {
              this.offertypeForm.controls[k].setValue(value);
            }
          });
          this.drawer.open();
        };
        return span;
      },
    },
  ];
  protected context = { controller: this };
  protected defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
  };

  constructor(
    private offertypesService: OffertypesService,
    private offertypeTemplatesService: OffertypeTemplatesService,
    private periodsService: PeriodsService,
    private wagertypesService: WagertypesService,
    private snackbarService: SnackBarService,
    public dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    LicenseManager.setLicenseKey(agGridLicense);
    this.source$ = new BehaviorSubject(this.source);
    this.fetch$ = combineLatest([this.offertypes$, this.data$]);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.source && !changes.source.firstChange) {
      this.source$.next(changes.source.currentValue);
    }
  }

  protected getRowNodeId({ id, sport }: OffertypeInterfaceWithTemplate): string {
    return `${id}_${sport}`;
  }

  protected get data$(): Observable<[Periods, Wagertypes, Offertypes]> {
    return combineLatest([this.periodsService.list(), this.wagertypesService.list(), this.offertypeTemplatesService.list()]).pipe(
      tap(([periods, wagertypes, templates]) => {
        this.wagertypes = Object.values(wagertypes).sort((a, b) => a.name.localeCompare(b.name));
        this.templates = Object.entries(templates).map(([id, temp]) => ({
          id,
          name: id
            .split('_')
            .map((t) => t.replace(/^[a-z]/i, t[0].toUpperCase()))
            .join(' '),
          ...temp,
        }));
        this.periodsBySport = periods;
      })
    );
  }

  protected get offertypes$(): Observable<Offertypes> {
    return this.source$.pipe(
      switchMap((source) =>
        this.offertypesService.listWithSource(source).pipe(
          tap((offtype) => {
            this.offertypes = Object.values(offtype)
              .flatMap((o) => toArray(o))
              .sort((u1, u2) => (u1.sport < u2.sport ? -1 : 1));
          })
        )
      )
    );
  }

  protected closeMenu(): void {
    this.drawer.close();
    this.offertypeForm.reset();
  }

  protected templateChange(event: MatSelectChange): void {
    const hasCustomFigure = `${this.templates.find((t) => t.id === event.value)?.figure}` === 'edit';
    this.offertypeForm.controls.customFigure.setValue(hasCustomFigure);
    if (!hasCustomFigure) {
      this.offertypeForm.controls.figure.setValue(undefined);
      this.offertypeForm.controls.figure.clearValidators();
    } else {
      this.offertypeForm.controls.figure.addValidators([Validators.required]);
    }
    this.offertypeForm.controls.figure.updateValueAndValidity();
  }

  protected doSave(): void {
    const data = Object.fromEntries(Object.entries(this.offertypeForm.controls).map(([key, control]) => [key, control.value]));
    const { figure, period, wagertype, template = '', id, sport_id } = data;

    const subs = this.offertypesService
      .createWithSource(this.source, {
        sport_id,
        id,
        template,
        period,
        wagertype,
        figure,
      })
      .subscribe({
        next: (res) => {
          this.agGrid.api.applyTransaction({ update: [{ ...data, ...res[0] }] });
          this.snackbarService.addSuccessMessage('Offertype updated successfully');
        },
        error: (err) => this.snackbarService.addErrorMessage(JSON.stringify(err)),
        complete: () => {
          subs.unsubscribe();
        },
      });
  }

  protected mapOffertypes() {
    this.dialog.open(AggregatorTemplates, {
      data: {
        source: this.source,
        offertypes: this.offertypes,
      },
    });
  }
}
