/* Copyright © 2019-2023 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { SportInterface } from 'gs-templates/lib/sport';
import { IMultiSelectOption, IMultiSelectSettings } from '../multiselect/multiselect.types';
import { skipWhile } from 'rxjs/operators';

import { FilterListPipe } from '../filters/filterListPipe.filter';

import { SportsService, EMPTY_LEAGUE } from './sports.service';

@Component({
  selector: 'sports-drop-down',
  templateUrl: 'sports/sportsDropDown.component.html',
  styles: [
    require('./sportsDropDown.component.scss')  // tslint:disable-line
  ],
  providers: [FilterListPipe]
})

export class SportsDropDownComponent implements OnInit {
  protected sportOptions: IMultiSelectOption[] = [];

  protected selectSettings: IMultiSelectSettings = {
    enableSearch: true,
    buttonClasses: 'btn btn-default btn-block',
    checkedStyle: 'glyphicon',
    selectionLimit: 0,
    autoUnselect: true,
    showUncheckAll: true
  };

  @Input() protected providedSportOptions: SportInterface[] = [];
  @Input() protected selectedSportIds: number[] = [];
  @Input() protected title = 'Select sports:';
  @Input() protected selectionLimit = 0;
  @Input() protected fromPlatform = false;
  @Output() protected sportsChanged = new EventEmitter<{sportIds: number[], labels: string[]}>();

  constructor(private sportsService: SportsService) { /**/ }

  public ngOnInit(): void {
    if (this.providedSportOptions.length) {
      this.sportOptions = this.providedSportOptions.map(sport => {
        return { id: sport.id, name: this.getSportLabel(sport) };
      });
      this.sort();
    } else {
      this.sportsService
        .list({ fromPlatform: !!this.fromPlatform })
        .subscribe(sports => {
          this.sportOptions = Object.keys(sports || {}).map(id =>
            ({ id: Number(id), name: this.getSportLabel(sports[id]) }));
          this.sort();
        });
    }
    this.selectSettings.selectionLimit = this.selectionLimit;
  }

  protected getSportLabel(sport: SportInterface): string {
    return `${sport.id}: ${sport.name}`;
  }

  protected handleSportsChanged() {
    this.sportsChanged.emit({
      sportIds: this.selectedSportIds,
      labels: this.selectedSportIds
        .map(id => (this.sportOptions.find(opt => Number(opt.id) === id) || EMPTY_LEAGUE).name)
        .filter(name => !!name)
    });
  }

  private sort(): void {
    this.sportOptions.sort((u1, u2) => Number(u1.id) - Number(u2.id));
  }

}
