/* Copyright © 2017-2022 Ganchrow Scientific, SA all rights reserved */
'use strict';

import { AdminLeagueInterface } from '../models/gsModel';
import { Validator, ValidationResponse } from './validator';

export class LeagueValidator extends Validator<AdminLeagueInterface> {
  public validate(id: AdminLeagueInterface['id'], instance: AdminLeagueInterface): ValidationResponse {
    let errors = [];
    if (!id) {
      errors.push('Must supply a league id.');
    }

    if (!instance) {
      errors.push('Must supply league info.');
    }

    if (!instance.id) {
      errors.push('Must supply a league id.');
    }

    if (!instance.name) {
      errors.push('Must supply a league name.');
    }

    if (!instance.sport_id || !Number.isInteger(Number(instance.sport_id))) {
      errors.push('Must supply a sport id.');
    }

    if (instance.platformMarkets && !instance.platformMarkets.split(',').map(Number).every(n => n > 0)) {
      errors.push('Invalid platform markets');
    }

    if (instance.marketNames &&
      !instance.marketNames.split(',').filter((v, i) => i % 2 === 0).map(Number).every(n => n > 0)
    ) {
      errors.push('Invalid market names');
    }

    if (errors.length) {
      return {
        success: false,
        reasons: errors
      };
    }
    return {
      success: true
    };
  }
}
